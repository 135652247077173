import { Component, EventEmitter, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MediaChange } from '@angular/flex-layout';
import { NavigationEnd, Router } from '@angular/router';
import { AsyncIndicatorService } from 'app/shared/async-indicator.service';
import { Subscription } from 'rxjs';
import { AccountService } from '../server/server.module';
import { MediaReplayService } from './../shared/sidenav/mediareplay/media-replay.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MainComponent implements OnInit {
  @ViewChild('sidenav') sidenav;

  private subscriptions: Subscription[] = [];

  sidenavOpen = true;
  sidenavMode = 'side';
  isMobile = false;

  // Wrap the original observable into async EventEmitter to avoid ExpressionChangedAfterItHasBeenCheckedError
  showProgressBar$ = new EventEmitter(true);

  constructor(
    private server: AccountService,
    private router: Router,
    private mediaReplayService: MediaReplayService,
    public asyncIndicatorService: AsyncIndicatorService) { }

  ngOnInit() {
    this.subscriptions.push(
      this.server.whoAmI().subscribe(
        (next) => {
          this.server.iAmBehaviourSubject.next(next)
        },
        (err) => {
          this.router.navigate(['/login'], { queryParams: { redirectTo: this.router.url } });
        }));

    this.subscriptions.push(
      this.mediaReplayService.isMobile$.subscribe((change: boolean) => {
        const isMobile = change;

        this.isMobile = isMobile;
        this.sidenavMode = (isMobile) ? 'over' : 'side';
        this.sidenavOpen = !isMobile;
      })
    );

    this.subscriptions.push(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd && this.isMobile) {
          this.sidenav.close();
        }
      })
    );

    this.subscriptions.push(
      this.asyncIndicatorService.pendingRequestsExist$.subscribe(this.showProgressBar$)
    );
  }

  onActivate(e, scrollContainer) {
    scrollContainer.scrollTop = 0;
  }
}
