import { Component, OnInit } from '@angular/core';
import { ClientSummary, FreightMethod, PtdClientService, PtdDispatchService, DispatchClipboard } from 'coadapi';
import { Observable } from 'rxjs';
import { downloadSummaries } from '../proforma-to-dispatch/abstracts/_download-summaries';

@Component({
  selector: 'app-proforma-to-dispatch',
  templateUrl: './proforma-to-dispatch.component.html',
  styleUrls: ['./proforma-to-dispatch.component.scss'],
})
export class ProformaToDispatchComponent implements OnInit {

  constructor(
    private clientService: PtdClientService,
    private dispatchService: PtdDispatchService,
    private downloadSummaries: downloadSummaries
    ) {}
  public clientSummaries$: Observable<ClientSummary[]>;
  public dispatchClipboards$: Observable<DispatchClipboard[]>;

  ngOnInit() {
    this.clientSummaries$ = this.clientService.getAllClients();
    this.dispatchClipboards$ = this.dispatchService.getClipboardSummary();
  }

  methodShortName(method: FreightMethod) {
    let matches = '';
    if (method.methodId === 1) {
      matches = 'Air';
    } else if (method.methodId === 2) {
      matches = 'Sea';
    } else if (method.methodId === 4) {
      matches = method.name;
    } else if (method.methodId === 70012) {
      matches = 'Virtual';
    } else if (method.methodId === 70014) {
      matches = 'EXW';
    } else {
      matches = method.name.match(/\b(\w)/g).join('');
    }

    return matches;
  }

  getReport() {
    this.downloadSummaries.getSummaries()
  }
}
