import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ADMIN_API_SERVER_URL } from '../tokens/admin-api-server-url.token';
import { ServerBaseService } from '../server-base.service';
import { EmailActionResponse } from '@equipmyschool/emsadminweb-models';

@Injectable()
export class GeneralService extends ServerBaseService {
  constructor(http: HttpClient, @Inject(ADMIN_API_SERVER_URL) serverUrl: string) {
    super(http, serverUrl);
  }

  public EmailAction(params: { [key: string]: any }) {
    return this.request<EmailActionResponse>(
      'POST',
      'General/EmailAction',
      params
    );
  }
}
