import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ADMIN_API_SERVER_URL } from '../tokens/admin-api-server-url.token';
import { ServerBaseService } from '../server-base.service';
import { QuickBooksCompany, QuickBooksCompanyResponse, QuickBooksDataResponse, QuickBooksExportCompany, QuickBooksExportDetails, QuickBooksErrorCompany, QuickBooksExportRow, QuickBooksReconciliationResponse } from '@equipmyschool/emsadminweb-models';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class QuickbooksService extends ServerBaseService {
  refreshToken: string;

  private _refreshToken$ = new BehaviorSubject<string>(null);
  public refreshToken$ = this._refreshToken$.asObservable();

  private _qbExportRows$ = new BehaviorSubject<QuickBooksExportRow[]>(null);
  public qbExportRows$ = this._qbExportRows$.asObservable();

  private _qbExportDetails$ = new BehaviorSubject<QuickBooksExportDetails>(null);
  public qbExportDetails$ = this._qbExportDetails$.asObservable();

  private _qbExportErrors$ = new BehaviorSubject<QuickBooksErrorCompany[]>(null);
  public qbExportErrors$ = this._qbExportErrors$.asObservable();

  private _reconciliationData$ = new BehaviorSubject<QuickBooksReconciliationResponse>(null);
  public reconciliationData$ = this._reconciliationData$.asObservable();

  private _company$ = new BehaviorSubject<QuickBooksCompany>(null);
  public company$ = this._company$.asObservable();

  private _validAccounts$ = new BehaviorSubject<string[]>(null);
  public validAccounts$ = this._validAccounts$.asObservable();

  private _vendorList$ = new BehaviorSubject<QuickBooksExportCompany[]>(null);
  public vendorList$ = this._vendorList$.asObservable();

  constructor(http: HttpClient, @Inject(ADMIN_API_SERVER_URL) serverUrl: string) {
    super(http, serverUrl);
  }

  public getAuthToken (code: string) {
    this.request<string>('GET', 'QuickBooks/AuthToken', {code: code})
      .subscribe((response) => {
        this.refreshToken = response;
        this._refreshToken$.next(response);
      });
  }

  public getCompany (realmId: string) {
    this.request<QuickBooksCompanyResponse>('GET', 'QuickBooks/Company', {realmId: realmId, refreshToken: this.refreshToken})
      .subscribe((response) => {
         this._company$.next(response.Company);
         this.refreshToken = response.Token;
         this._refreshToken$.next(response.Token);
      });
  }

  public getData (type: string, realmId: string) {
    this.request<QuickBooksDataResponse>('GET', 'QuickBooks/' + type, { realmId: realmId, refreshToken: this.refreshToken })
      .subscribe((response) => {
         this._qbExportRows$.next(response.Data);
         this.refreshToken = response.Token;
         this._refreshToken$.next(response.Token);
      });
  }

  public getDetailedData (type: string, realmId: string, company: any) {
    this.request<QuickBooksExportDetails>('GET', 'QuickBooks/' + type, { realmId: realmId, refreshToken: this.refreshToken, companyId: company})
      .subscribe((response) => {
        this._qbExportDetails$.next(response);
      });
  }
  public getDocumentsMissingVAT (type: string, realmId: string, company: number) {
    this.request<QuickBooksExportDetails>('GET', 'QuickBooks/' + type + 'MissingVAT', { realmId: realmId, refreshToken: this.refreshToken, companyId: company})
      .subscribe((response) => {
        this._qbExportDetails$.next(response);
      })
  }  
  public clearDetailedData() {
    this._qbExportDetails$.next(null);
  }

  public exportData (type: string, realmId: string, accounts: QuickBooksExportCompany[]) {
    this.request<QuickBooksDataResponse>('POST', 'QuickBooks/' + type, null, { realmId: realmId, refreshToken: this.refreshToken, accounts: accounts })
      .subscribe((response) => {
         // Clear out any previous errors
         this._qbExportErrors$.next(null);
         this._qbExportRows$.next(response.Data)
         this.refreshToken = response.Token;
         this._refreshToken$.next(response.Token);
      },
      (error: HttpErrorResponse) => {
        // If the error object contains a "NewData" property,
        // we have error details that can be shown to the user
        if ('NewData' in error.error) {
          this._qbExportErrors$.next(error.error.Errors);
          this._qbExportRows$.next(error.error.NewData.Data);
          this.refreshToken = error.error.NewData.Token;
          this._refreshToken$.next(error.error.NewData.Token);
        }
      });
  }

  public reconcileData (type: string, realmId: string) {
    this.request<QuickBooksReconciliationResponse>('GET', 'QuickBooks/Reconcile' + type, {realmId: realmId, refreshToken: this.refreshToken})
      .subscribe((response) => {
        this._reconciliationData$.next(response);
        this.refreshToken = response.Token;
        this._refreshToken$.next(response.Token);
      })
  }

  public getValidAccounts(companyType: number, realmId: string, accounts: string[]) {
    this.request<string[]>('GET', 'QuickBooks/ValidAccounts', {RealmId: realmId, RefreshToken: this.refreshToken, CompanyType: companyType, Accounts: accounts})
      .subscribe((response) => { this._validAccounts$.next(response) });
  }

  public async searchSuppliers (type: String, searchText: string, realmId: string) {
    if (!searchText) {
      this._vendorList$.next(null);
    }
    else {
      this.request<QuickBooksExportCompany[]>('GET', 'QuickBooks/SearchQuickBooksVendors', {realmId: realmId, refreshToken: this.refreshToken, type: type, searchText: searchText})
        .subscribe((response) => {
          this._vendorList$.next(response);
        })
    }
  }

  public async searchClients (type: String, searchText: string, realmId: string) {
    if (!searchText) {
      this._vendorList$.next(null);
    }
    else {
      this.request<QuickBooksExportCompany[]>('GET', 'QuickBooks/SearchQuickBooksClients', {realmId: realmId, refreshToken: this.refreshToken, type: type, searchText: searchText})
        .subscribe((response) => {
          this._vendorList$.next(response);
        })
    }
  }
}
