import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removewhitespace'
})
export class RemoveWhitespacePipe implements PipeTransform {
    transform(value: string) {
      if (value) {
        return value.replace(/\s/g, '');
      }
    }
}
