import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogConfig,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { User } from "@equipmyschool/emsadminweb-models";
import { Staff, GenCommentsService, Comment } from "coadapi";
import { sharedUserData } from "../../../abstracts/_data-sharing";
import { StaffListComponent } from "../staff-list/staff-list.component";
import { CustomDateAdapter } from "app/coad-web/proforma-to-dispatch/abstracts/_date-adapter";
import { CommentActionService } from "app/coad-web/chasing-board/abstracts/_data-sharing";
import { startWith, switchMap } from "rxjs/operators";
import { Subscription } from "rxjs";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-chase-notes",
  templateUrl: "./chase-notes.component.html",
  styleUrls: ["./chase-notes.component.scss"],
})
export class ChaseNotesComponent implements OnInit {
  user: User;
  taMessage = "";
  staffList: Staff[];
  searching = false;
  supplier: number;
  commentsList: Comment[];
  editVisibilityList = new Map();
  completionMessage: number;
  currentUtcDate = CustomDateAdapter.createDateTime(
    new Date().getUTCFullYear(),
    new Date().getUTCMonth(),
    new Date().getUTCDate(),
    new Date().getUTCHours(),
    new Date().getUTCMinutes(),
    new Date().getUTCSeconds(),
    new Date().getUTCMilliseconds()
  );
  subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public sharedUserData: sharedUserData,
    private commentsService: GenCommentsService,
    private dialog: MatDialog,
    private commentActionsService: CommentActionService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    this.iconRegistry.addSvgIcon(
      "tick",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "app/coad-web/proforma-to-dispatch/images/tick.svg"
      )
    );
  }

  ngOnInit(): void {
    this.user = this.sharedUserData.getUser();
    this.supplier = this.data.supplier;
    this.staffList = this.data.staffList;
    this.subscriptions.push(
      this.commentActionsService.commentAction$.pipe(
        startWith(''),
        switchMap(() => {
          return this.commentsService
          .getComments("Companies", this.supplier)
        })
      ).subscribe((res) => {
        this.commentsList = res;
        res.forEach((comment) => {
          this.editVisibilityList.set(comment.commentId, false);
        });
        return res;
      })
    );
  }

  ngAfterViewInit() {
    this.commentActionsService.commentAction$.next();
  }

  isCompletionMessage(message: string): boolean {
    if (message.includes("not complete to complete")) {
      return true;
    }
    return false;
  }

  isUnCompletionMessage(message: string): boolean {
    if (message.includes("complete to not complete")) {
      return true;
    }
    return false;
  }

  onTextChange(event): void {
    const text = this.taMessage;
    const regex = /\B@([^\s@]+)$/;
    
    if (regex.test(text) && !this.searching) {
      console.log("Going to find a user");
      this.searching = true;
    }
    if (this.searching) {
      this.openStaffList(event);
    }
  }

  openStaffList(event): void {
    let targetAttr = event.target.getBoundingClientRect();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.hasBackdrop = false;
    dialogConfig.restoreFocus = true;
    dialogConfig.id = "staff-list";
    dialogConfig.data = {
      list: this.staffList,
      search: this.taMessage.substring(this.taMessage.lastIndexOf(" @") + 2),
    };
    dialogConfig.position = {
      top: targetAttr.y + targetAttr.height + "px",
      left: targetAttr.x + "px",
    };
    if (!this.dialog.openDialogs.find((x) => x.id === "staff-list")) {
      const staffListRef = this.dialog.open(StaffListComponent, dialogConfig);
      this.subscriptions.push(
        staffListRef.afterOpened().subscribe(() => {
          // Set focus back to the text box
          const textBox = document.getElementById("taMessageInput");
          if (textBox) {
            textBox.focus();
          }
        })
      );
    } else {
      this.dialog.getDialogById("staff-list").componentInstance.data =
        dialogConfig.data;
    }
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Tab' || event.key === 'Enter') {
      event.preventDefault(); // Prevent the default behavior
      this.dialog.getDialogById("staff-list").componentInstance.handleKeyDown(event);
    }
  }

  reply(): void {
    let staff: Staff = {
      contact: this.user.ContactID,
    };
    let comment: Comment = {
      tableName: "Companies",
      tableId: this.data.supplier,
      user: staff,
      posted: this.currentUtcDate.toISOString(),
      complete: false,
      comment: this.taMessage,
      deleted: false,
    };
    this.subscriptions.push(
      this.commentsService.postComment(comment).subscribe({
        next: (result: any) => {
          console.log(result);
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => {
          console.log("reply complete");
          this.commentActionsService.commentAction$.next();
          // Set focus back to the text box
          const textBox = document.getElementById("taMessageInput");
          if (textBox) {
            textBox.focus();
            this.taMessage = "";
          }
        },
      })
    );
  }

  edit(
    commentId: number,
    complete: boolean,
    deleted: boolean,
    message: string
  ): void {
    let editBody: Comment = {
      commentId: commentId,
      complete: complete,
      deleted: deleted,
      comment: message,
    };
    this.subscriptions.push(
      this.commentsService.updateComment(editBody).subscribe({
        next: (result: any) => {
          console.log(result);
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => {
          console.log("update complete");
          this.commentActionsService.commentAction$.next();
          if (this.editVisibilityList.get(commentId) == true) {
            this.editVisibilityList.set(commentId, false);
          }
        },
      })
    )    
  }

  makeEdits(commentId: number) {
    this.editVisibilityList.set(commentId, !this.editVisibilityList.get(commentId));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.dialog.closeAll();
  }
}
