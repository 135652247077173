
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Input, OnInit, Output } from '@angular/core';
import { ShopDepartment, ShopDepartmentBase } from '@equipmyschool/emsadminweb-models';
import { CrawlerStatusService } from 'app/server/server.module';
import { of, Subject, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-project-tree',
  templateUrl: './project-tree.component.html',
  styleUrls: ['./project-tree.component.scss']
})
export class ProjectTreeComponent implements OnInit {
  departmentTree$ = this.server.getShopDepartments().pipe(
    tap(root => {
      setTimeout(() => {
        this.treeControl.expand(root);
      });
    }),
    map(root => [root]));

  selectedNode$ = new Subject<ShopDepartmentBase>();

  @Output()
  selectedNodeChange = this.selectedNode$.asObservable();

  @Input()
  selectedNode: ShopDepartmentBase;

  treeControl = new NestedTreeControl<ShopDepartmentBase>((node: ShopDepartment) => {
    return of(node.Children);
  });

  private subscriptions: Subscription[] = [];
  constructor(
    private server: CrawlerStatusService
  ) { }

  ngOnInit() {
  }

  hasChildren(_: number, node: ShopDepartmentBase) {
    return (node as ShopDepartment).Children && (node as ShopDepartment).Children.length > 0;
  }

  select(node: ShopDepartmentBase) {
    this.selectedNode = node;
    this.selectedNode$.next(node);
  }
}
