import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[changeColorInit]',
  exportAs: 'changeColorInit'
})

export class ChangeColorDirective {
  @Input() changeColorInit;
  @Input() color: any;
  @Input() lineID;
}
