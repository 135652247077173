import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ADMIN_API_SERVER_URL } from '../tokens/admin-api-server-url.token';
import { ServerBaseService } from '../server-base.service';
import { ShopDepartment } from '@equipmyschool/emsadminweb-models';

@Injectable()
export class ShopDepartmentService extends ServerBaseService {
  constructor(http: HttpClient, @Inject(ADMIN_API_SERVER_URL) serverUrl: string) {
    super(http, serverUrl);
  }

  /**
   *  Gets all parent, sibling and child departments of the given ShopDepartmentID
  */
  Get = (params: { ShopDepartmentID?: number }) => {
    return this.request<ShopDepartment>(
      'GET',
      'ShopDepartment/Get',
      params
    );
  }

  /**
   *  Gets the viewable parent, sibling and child departments of the given ShopDepartmentID
   */
  GetViewable = (params: { ViewableShopDepartmentID?: number }) => {
    return this.request<ShopDepartment>(
      'GET',
      'ShopDepartment/GetViewable',
      params
    );
  }

  Add = (params: { Name: string, Parent: number }) => {
    return this.request<number>(
      'POST',
      'ShopDepartment/Add',
      params
    );
  }

  Link = (params: { ViewableShopDepartmentID: number, ShopDepartments: number[] }) => {
    return this.request(
      'PUT',
      'ShopDepartment/Link',
      null,
      params
    );
  }
}
