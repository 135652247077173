// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  serverUrl: 'https://adminapitest.equipmyschool.com/',
  emsClientUrl: 'https://test.equipmyschool.com/',
  emsServerUrl: 'https://apitest.equipmyschool.com/',
  basePath: 'https://coadapitest.equipmyschool.com/EquipMySchool/CoadAPI'
  // basePath: 'http://localhost:50352/EquipMySchool/CoadAPI'
}