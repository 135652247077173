import { CompanyType, UserType } from '@equipmyschool/emsadminweb-models';

export const iconMappings = {
  get CompanyType() {
    const res = {};
    res[CompanyType.Customer] = 'credit_card';
    res[CompanyType.Supplier] = 'local_shipping';
    return res;
  },

  get UserType() {
    const res = {};
    res[UserType.User] = 'person';
    res[UserType.Admin] = 'supervisor_account';
    res[UserType.Staff] = 'assignment_ind';
    res[UserType.Contractor] = 'assignment';
    return res;
  }
};

