<h1 mat-dialog-title>Save {{data.documentTypeReadable}}</h1>
<div mat-dialog-content>
  <mat-form-field>
    <input #grandTotal matInput type="number" step="0.01" placeholder="Grand Total" [appValidators]="data.validator" #ctrl="ngModel" (click)="$event.target"
    [ngModel]="value | simpleDecimal:'1.2-2'" [ngModelOptions]="{updateOn: 'blur'}" (ngModelChange)="value=$event">
    <span matSuffix>{{data.currencyCode}}</span>
    <mat-hint>Please enter the Grand Total in the document's native currency.</mat-hint>
  </mat-form-field>
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close tabindex="-1">Cancel</button>
  <button mat-button [mat-dialog-close]="value" tabindex="1" [disabled]="ctrl.invalid">Save</button>
</mat-dialog-actions>
