import { NgModule } from '@angular/core';
import { AgentFilterPipe } from './_agent-search';
import { ContactFilterPipe } from './_contact-search';
import { FilterDispatchPipe } from './_filterdispatch';
import { GroupDispatchPipe } from './_groupdispatch';
import { OrderByPipe } from './_order-by';
import { RemoveWhitespacePipe } from './_remove-whitespace';
import { ReversePipe } from './_reverse';

@NgModule({
  declarations: [
    AgentFilterPipe,
    ContactFilterPipe,
    FilterDispatchPipe,
    GroupDispatchPipe,
    OrderByPipe,
    RemoveWhitespacePipe,
    ReversePipe
  ],
  // exports is required so you can access your component/pipe in other modules
  exports: [
    AgentFilterPipe,
    ContactFilterPipe,
    FilterDispatchPipe,
    GroupDispatchPipe,
    OrderByPipe,
    RemoveWhitespacePipe,
    ReversePipe
  ]
})
export class PipeModule{}