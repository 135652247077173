import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Company, User } from '@equipmyschool/emsadminweb-models';

@Component({
  selector: 'app-confirm-associate-user',
  templateUrl: './confirm-associate-user.component.html',
  styleUrls: ['./confirm-associate-user.component.scss']
})
export class ConfirmAssociateUserComponent implements OnInit {
  public shouldBeAdmin = false;
  constructor(
    public dialogRef: MatDialogRef<ConfirmAssociateUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputData) { }

  ngOnInit() {
  }

}

export interface InputData {
  company: Company;
  user: User;
}
