import {
  PtdDispatchDetails,
  PreDispatchDetails,
  Proforma,
  PtdDispatchService,
} from 'coadapi';

export default class Utils {

  static async createDispatch(details: PreDispatchDetails, dispatchService: PtdDispatchService) {
    return await dispatchService.createDispatch(details).toPromise();
    // .subscribe({
    //   next: (result: any) => {
    //     console.log(result);
    //   },
    //   complete: () => {
    //     console.log('update complete');
    //     return true;
    //   },
    // });
  }

  static updateDispatch(details: PtdDispatchDetails, update: PreDispatchDetails, dispatchService: PtdDispatchService) {
    update.client = details.client;
    update.dispatch = details.dispatchId;
    if (update.client !== 0) {
      dispatchService.updateDispatch(update).subscribe({
        next: (result: any) => {
          console.log(result);
        },
        complete: () => {
          console.log('update complete');
          return true;
        },
      });
    }
  }

  static updateIWD(details: PtdDispatchDetails, update: PreDispatchDetails, dispatchService: PtdDispatchService, originDispatch: PtdDispatchDetails) {
    update.client = details.client;
    update.dispatch = details.dispatchId;
    dispatchService.updateIWDDispatch(update, update.warehouse, originDispatch.dispatchId).subscribe({
      next: (result: any) => {
        console.log(result);
      },
      complete: () => {
        console.log('update complete');
        return true;
      },
    });
  }

  static mapDetailsToUpdate(details: PtdDispatchDetails, update: PreDispatchDetails, clientId: number): PreDispatchDetails {
    update.client = clientId;
    update.closedIwd = details.closedToIwd;
    update.deliveryAddress = details.dispatchDeliveryAddress?.addressId;
    update.consigneeAddress = details.dispatchConsigneeAddress?.addressId;
    update.dispatch = details.dispatchId;
    update.eta = details.dispatchEta;
    update.ets = details.dispatchEts;
    update.incoterm = details.incoterm?.incotermId;
    update.method = details.dispatchFreightMethod?.methodId;
    update.warehouse = details.dispatchWarehouse?.warehouseId;
    update.clientAgent = details.clientAgent?.agentId;
    update.clientAgentContact = details.clientAgentContact?.contactId;
    update.clientContact = details.clientContact?.contactId;
    update.descriptive = details.descriptive?.descriptiveId; 
    update.shipmentCategory = details.dispatchNoLetter?.commodityID
    return update;
  }
}
