import { HostListener, Directive, ElementRef } from '@angular/core';

@Directive({
  selector:
  'input[type=number][step="0.01"][formControlName],input[type=number][step="0.01"][formControl],input[type=number][step="0.01"][ngModel]'
})
export class DecimalInputOnBlurDirective {
  constructor(private el: ElementRef) {
  }

  @HostListener('blur') onLeave() {
    this.el.nativeElement.value = parseFloat(this.el.nativeElement.value).toFixed(2);
  }
}
