import { Component, OnInit } from '@angular/core';
import { CrawlerStatusService } from 'app/server/server.module';
import { CrawlerStatusPage, Supplier } from '@equipmyschool/emsadminweb-models';
import { Subscription } from 'rxjs';
import { UnsubscribeOnDestroy } from 'app/shared/decorators/unsubscribe-on-destroy';
import { saveFile } from 'app/shared/save-file';

@Component({
  selector: 'app-category-pages',
  templateUrl: './category-pages.component.html',
  styleUrls: ['./category-pages.component.scss']
})
export class CategoryPagesComponent implements OnInit {
  public categoryPages: CrawlerStatusPage[];

  @UnsubscribeOnDestroy()
  private subscriptions: Subscription[] = [];

  constructor(private crawlerStatusService: CrawlerStatusService) { }

  ngOnInit() {
    this.subscriptions.push(this.crawlerStatusService.getCategoryPages().subscribe(res => {
      this.categoryPages = res;
    }));
  }

  downloadSpreadsheet(supplier: Supplier) {
    this.subscriptions.push(this.crawlerStatusService.getCategoryPageErrors(supplier.SupplierID).subscribe(res => {
      saveFile(res);
    }));
  }
}
