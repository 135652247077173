<mat-table #table [dataSource]="lines | dataSource">
  <ng-container cdkColumnDef="Select">
    <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
    <mat-cell *cdkCellDef="let row">
      <mat-pseudo-checkbox [state]="isRowSelected(row) ? 'checked': 'unchecked'" (click)="lineClicked.emit(row)"></mat-pseudo-checkbox>
    </mat-cell>
  </ng-container>

  <ng-container cdkColumnDef="Code">
    <mat-header-cell *cdkHeaderCellDef>Code</mat-header-cell>
    <mat-cell *cdkCellDef="let row">{{ row.Product.Code }}</mat-cell>
  </ng-container>

  <ng-container cdkColumnDef="Description">
    <mat-header-cell *cdkHeaderCellDef>Description</mat-header-cell>
    <mat-cell *cdkCellDef="let row">{{ row.Description }}</mat-cell>
  </ng-container>

  <ng-container cdkColumnDef="Quantity">
    <mat-header-cell *cdkHeaderCellDef>Qty</mat-header-cell>
    <mat-cell *cdkCellDef="let row">
      <a *ngIf="split.observers.length > 0 && row.Product.Quantity != 1" matTooltip="Split line" (click)="split.emit(row)" href="javascript:void(0)">
        {{ row.Product.Quantity }}
      </a>
      <ng-container *ngIf="split.observers.length === 0 || row.Product.Quantity === 1">
        {{ row.Product.Quantity }}
      </ng-container>
    </mat-cell>
  </ng-container>

  <ng-container cdkColumnDef="UnitSalePrice">
    <mat-header-cell *cdkHeaderCellDef>Unit Sale Price</mat-header-cell>
    <mat-cell *cdkCellDef="let row">{{ row.Product.UnitValue | number: '1.2-2' }}</mat-cell>
  </ng-container>

  <ng-container cdkColumnDef="ExpectedUnitCost">
    <mat-header-cell *cdkHeaderCellDef>Expected Unit Cost</mat-header-cell>
    <mat-cell *cdkCellDef="let row">{{ row.Product.ExpectedUnitCost | number: '1.2-2' }}</mat-cell>
  </ng-container>

  <ng-container cdkColumnDef="UnitCost">
    <mat-header-cell *cdkHeaderCellDef>Unit Cost</mat-header-cell>
    <mat-cell *cdkCellDef="let row" [ngClass]="{'mat-alert': checkIfUnitCostTooHigh(row)}">{{ row.Cost / row.Product.Quantity | number: '1.2-2' }}</mat-cell>
  </ng-container>

  <ng-container cdkColumnDef="LineCost">
    <mat-header-cell *cdkHeaderCellDef>Line Cost</mat-header-cell>
    <mat-cell *cdkCellDef="let row">
      <mat-form-field *ngIf="editable">
        <input matInput type="number" step="0.01" [ngModel]="row.Cost | number: '1.2-2'" (ngModelChange)="row.Cost=$event;updateLineVat(row)" [ngModelOptions]="{updateOn: 'blur'}" (click)="$event.target">
      </mat-form-field>
      <ng-container *ngIf="!editable">
        {{row.Cost | number: '1.2-2' }}
      </ng-container>
    </mat-cell>
  </ng-container>

  <ng-container cdkColumnDef="Commodity">
    <mat-header-cell *cdkHeaderCellDef>Commodity</mat-header-cell>
    <mat-cell *cdkCellDef="let row">
      <mat-form-field *ngIf="editable">
        <mat-select [(ngModel)]="row.Product.Commodity" (ngModelChange)="updateLineVat(row)" [compareWith]="commodityCompareFn">
          <mat-option *ngFor="let commodity of commodities" [value]="commodity">
            {{commodity.Name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <ng-container *ngIf="!editable">
        {{row.Product.Commodity.Name}}
      </ng-container>
    </mat-cell>
  </ng-container>

  <ng-container cdkColumnDef="LineTaxCost">
    <mat-header-cell *cdkHeaderCellDef>Line Tax Cost</mat-header-cell>
    <mat-cell *cdkCellDef="let row">
      <mat-form-field *ngIf="editable">
        <input matInput type="number" step="0.01" [ngModel]="row.TaxCost | number: '1.2-2'" (ngModelChange)="row.TaxCost=$event" [ngModelOptions]="{updateOn: 'blur'}" (click)="$event.target">
      </mat-form-field>
      <ng-container *ngIf="!editable">
        {{row.TaxCost | number: '1.2-2' }}
      </ng-container>
    </mat-cell>
  </ng-container>

  <ng-container cdkColumnDef="Rec">
    <mat-header-cell *cdkHeaderCellDef matTooltip="Received">Rec</mat-header-cell>
    <mat-cell *cdkCellDef="let row">
      <mat-pseudo-checkbox [matTooltip]="row.Status.Name" [state]="row.Status.Received ? 'checked': 'unchecked'" [disabled]=true></mat-pseudo-checkbox>
    </mat-cell>
  </ng-container>

  <ng-container cdkColumnDef="ETA">
    <mat-header-cell *cdkHeaderCellDef>ETA</mat-header-cell>
    <mat-cell *cdkCellDef="let row">
      <mat-icon class="icon-time"
                matTooltip="Set ETA"
                #changeColorDirective="changeColorInit"
                [changeColorInit]="null"
                [ngStyle]="{'color': changeColorDirective.color}"
                (click)="setETA(row.Description, row.LineID, changeColorDirective)">
        access_time
      </mat-icon>
    </mat-cell>
  </ng-container>

  <ng-container cdkColumnDef="Status">
    <mat-header-cell *cdkHeaderCellDef>Status</mat-header-cell>
    <mat-cell *cdkCellDef="let row" class="mat-caption">{{ row.Status.Name }}</mat-cell>
  </ng-container>

  <ng-container cdkColumnDef="Availability">
    <mat-header-cell *cdkHeaderCellDef>Availability</mat-header-cell>
    <mat-cell *cdkCellDef="let row">
      <mat-form-field *ngIf="editable">
        <mat-select [(ngModel)]="row.Availability">
          <mat-option *ngFor="let availability of (serverEnums.PurchaseAvailabilityType | enumKeys)" [value]="availability">
            {{ serverEnums.PurchaseAvailabilityType[availability] | camelCaseToWords }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <span *ngIf="!editable">
        {{ serverEnums.PurchaseAvailabilityType[row.Availability] || 'Not On Invoice' | camelCaseToWords }}
      </span>
    </mat-cell>
  </ng-container>

  <ng-container cdkColumnDef="Buttons">
    <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
    <mat-cell *cdkCellDef="let row">
    </mat-cell>
  </ng-container>

  <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row mat-ripple [matRippleDisabled]="true" *cdkRowDef="let row; columns: displayedColumns;">
  </mat-row>
</mat-table>
