<mat-table #table [dataSource]="dataSource" matSort>

  <ng-container cdkColumnDef="Supplier">
    <mat-header-cell *cdkHeaderCellDef mat-sort-header>Supplier</mat-header-cell>
    <mat-cell *cdkCellDef="let row">
      {{ row.Supplier.Name }}
    </mat-cell>
  </ng-container>

  <ng-container cdkColumnDef="Count">
    <mat-header-cell *cdkHeaderCellDef mat-sort-header>Count</mat-header-cell>
    <mat-cell *cdkCellDef="let row"> {{ row.PageCount }} </mat-cell>
  </ng-container>

  <ng-container cdkColumnDef="Errors">
    <mat-header-cell *cdkHeaderCellDef>Errors</mat-header-cell>
    <mat-cell *cdkCellDef="let row">
      <ng-container *ngIf="row.PagesWithErrors > 0">
        <a href="javascript:void(0)" matTooltip="Download supplier crawler errors as an excel file" (click)="onErrorsClick(row.Supplier)">{{ row.PagesWithErrors }}</a>
      </ng-container>
      <ng-container *ngIf="row.PagesWithErrors === 0">
        {{ row.PagesWithErrors }}
      </ng-container>
    </mat-cell>
  </ng-container>

  <ng-container cdkColumnDef="ToCheck">
    <mat-header-cell *cdkHeaderCellDef>To Check </mat-header-cell>
    <mat-cell *cdkCellDef="let row">
      {{ row.PagesToCheck }}
    </mat-cell>
  </ng-container>

  <ng-container cdkColumnDef="LastChecked">
    <mat-header-cell *cdkHeaderCellDef>Last Checked</mat-header-cell>
    <mat-cell *cdkCellDef="let row">
      {{ row.PageLastChecked }}
    </mat-cell>
  </ng-container>

  <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
