<mat-card *ngIf="user">
  <pre>
    Name: <strong>{{ user.Contact.FirstName }} {{ user.Contact.LastName }}</strong>
    Email: {{ user.Email }}
    Phone: {{ user.Contact.Phone }}
    Gender: {{ Gender[user.Contact.Gender] }}
  </pre>


  Company:
  <div style="display: inline-flex;">
    <app-country-icon [country]="user.Contact.Company?.Country"></app-country-icon>
    <app-company-type-icon [companyType]="user.Contact?.Company?.Type"></app-company-type-icon>
    {{ user.Contact.Company?.Name }}
  </div>

  <div>
    Account Number: {{ user.Contact.Company?.AccountNo }}<br/>
    Job Title: {{ user.Contact.JobTitle }}
  </div>
</mat-card>
