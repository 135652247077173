import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'app/shared/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-split-quantity-dialog',
  templateUrl: './split-quantity-dialog.component.html',
  styleUrls: ['./split-quantity-dialog.component.scss']
})
export class SplitQuantityDialogComponent implements AfterViewInit {
  public newQuantity: number;
  public min: number;
  public max: number;
  @ViewChild('qtyInput') qtyInput: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputData
  ) {
    this.newQuantity = data.currentQuantity;

    if (data.currentQuantity > 0) {
      this.min = 1;
      this.max = data.currentQuantity - 1;
    } else {
      this.min = data.currentQuantity + 1;
      this.max = -1;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.qtyInput.nativeElement.select();
    }, 100);
  }

}

export interface InputData {
  currentQuantity: number;
}
