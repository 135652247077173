import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NetUser } from '@equipmyschool/emsadminweb-models';
import * as serverEnums from 'app/server/server-enums';
import { iconMappings } from 'app/shared/icon-mappings';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  iconMappings = iconMappings;
  serverEnums = serverEnums;

  constructor(
    public dialogRef: MatDialogRef<UserDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputData
  ) { }

  ngOnInit() {
  }

}

export interface InputData {
  user: NetUser;
}
