<mat-progress-bar mode="indeterminate" *ngIf="showProgressBar$ | async"></mat-progress-bar>

<mat-sidenav-container>

  <mat-sidenav class="sidenav" [opened]="sidenavOpen" align="start" [mode]="'side'" appIconSidenav #sidenav>

    <app-sidenav></app-sidenav>

  </mat-sidenav>

  <div class="content-container" fxLayout="column">
    <app-toolbar [sidenav]="sidenav" fxHide="false" fxHide.gt-sm></app-toolbar>

    <div class="main-container" #scrollContainer>
      <router-outlet (activate)="onActivate($event, scrollContainer)"></router-outlet>
    </div>
  </div>

</mat-sidenav-container>
