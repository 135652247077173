import { Pipe, PipeTransform } from '@angular/core';
import { PtdDispatchDetails } from 'coadapi';

@Pipe({
  name: 'filterDispatch'
})
export class FilterDispatchPipe implements PipeTransform {

  transform(items: PtdDispatchDetails[]): any {
    if (!items) {
        return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter(
      item => items.filter(
        count => count.dispatchId === item.dispatchId
        ).length === 1 || item.dispatchWarehouse.warehouseId === item.shippedToWarehouse.warehouseId
        );
      }
}
