import { Pipe, PipeTransform } from '@angular/core';
import { SplitWarehouse } from 'coadapi';

@Pipe({
  name: 'groupDispatch'
})
export class GroupDispatchPipe implements PipeTransform {
  transform(items: SplitWarehouse[]): any {
    if (items.length > 1) {
        let rIndex = 0;
        const groupArray = items.reduce((r, currentValue, index, array) => {
          if (r.length === 0) {
            r.push(currentValue);
            rIndex = 0;
            return r;
          }
          if (currentValue.warehouse.warehouseId !== r[rIndex].warehouse.warehouseId) {
            r.push(currentValue);
            rIndex++;
            return r;
          } else {
            r[r.findIndex(i => i.warehouse.warehouseId === currentValue.warehouse.warehouseId)].splitCommodity.letter  += currentValue.splitCommodity.letter;
            r[r.findIndex(i => i.warehouse.warehouseId === currentValue.warehouse.warehouseId)].warehouseValue  += currentValue.warehouseValue;
            return r;
          }
        }, []);
        return groupArray;
      } else {
        return items;
      }
  }
}
