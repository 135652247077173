import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ServerBaseService } from './services/server-base.service';
import { AccountService } from './services/account/account.service';
import { AspNetUserService } from './services/asp-net-user/asp-net-user.service';
import { CrawlerStatusService } from './services/crawler-status/crawler-status.service';
import { EmsService } from './services/ems/ems.service';

import { ADMIN_API_SERVER_URL } from './services/tokens/admin-api-server-url.token';
import { EMS_API_SERVER_URL } from './services/tokens/ems-api-server-url.token';
import { PurchaseInvoiceService } from './services/purchase-invoice/purchase-invoice.service';
import { CommodityService } from './services/commodity/commodity.service';
import { ShopDepartmentService } from './services/shop-department/shop-department.service';
import { PurchasingQueryService } from './services/purchasing-query/purchasing-query.service';
import { GeneralService } from './services/general/general.service';
import { OrderLineService } from '../server/services/order-line/order-line.service';
import { QuickbooksService } from '../server/services/quickbooks/quickbooks.service';
import { ClientCountriesService } from '../server/services/client-countries/client-countries.service';
import { ProductImagesService } from '../server/services/product-images/product-images.service';
import { ProjectService } from '../server/services/project/project.service';
import { CountryService } from '../server/services/country/country.service';
import { SupplierService } from '../server/services/supplier/supplier.service';

export {
  AccountService,
  AspNetUserService,
  CrawlerStatusService,
  EmsService,
  PurchaseInvoiceService,
  CommodityService,
  ShopDepartmentService,
  PurchasingQueryService,
  GeneralService,
  QuickbooksService,
  ClientCountriesService,
  ProductImagesService,
  ProjectService,
  CountryService,
  SupplierService,

  ServerBaseService,
  ADMIN_API_SERVER_URL,
  EMS_API_SERVER_URL
};

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    AccountService,
    AspNetUserService,
    CrawlerStatusService,
    EmsService,
    PurchaseInvoiceService,
    CommodityService,
    OrderLineService,
    ShopDepartmentService,
    PurchasingQueryService,
    GeneralService,
    QuickbooksService,
    ClientCountriesService,
    ProductImagesService,
    ProjectService,
    CountryService,
    SupplierService
  ]
})
export class ServerModule {
}
