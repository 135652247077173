import { Component, OnInit, Input } from '@angular/core';
import { NetUser, CompanyType, Gender } from '@equipmyschool/emsadminweb-models';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {
  @Input()
  user: NetUser;

  public CompanyType = CompanyType;
  public Gender = Gender;

  constructor() { }

  ngOnInit() {
  }

}
