import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ADMIN_API_SERVER_URL } from '../tokens/admin-api-server-url.token';
import { ServerBaseService } from '../server-base.service';
import { Country } from '@equipmyschool/emsadminweb-models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountryService extends ServerBaseService {

  private _countries$ = new BehaviorSubject<Country[]>(null);  
  public countries$ = this._countries$.asObservable();

  constructor(http: HttpClient, @Inject(ADMIN_API_SERVER_URL) serverUrl: string) {
    super(http, serverUrl);
  }

  public getCountries () {
    this.request<Country[]>('GET', 'Country/Get')
      .subscribe((response) => {
         this._countries$.next(response);
      });
  }

  compareCountries(a: Country, b: Country) {
    return a && b ? a.CountryID === b.CountryID : a === b;
  }
}
