
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Company, CompanyType, NetUser } from '@equipmyschool/emsadminweb-models';
import { AspNetUserService } from 'app/server/server.module';
import { UnsubscribeOnDestroy } from 'app/shared/decorators/unsubscribe-on-destroy';
import { FilteredDataSource } from 'app/shared/filtered-data-source/filtered-data-source';
import { of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ConfirmAssociateUserComponent } from '../confirm-associate-user/confirm-associate-user.component';

@Component({
  selector: 'app-associate-to-company',
  templateUrl: './associate-to-company.component.html',
  styleUrls: ['./associate-to-company.component.scss']
})
export class AssociateToCompanyComponent implements OnInit {
  public filter = "";
  public user: NetUser;
  public companies: Company[];
  public displayedColumns = ['Id', 'CompanyType', 'Country', 'AccountNo', 'Name'];
  dataSource: FilteredDataSource<Company> = new FilteredDataSource(
    filterValue => {
      if (filterValue === '') {
        return of([] as Company[]);
      }

      return this.server.getCompanies(filterValue);
    }
  );

  public CompanyType = CompanyType;

  @UnsubscribeOnDestroy()
  private subscriptions: Subscription[] = [];

  constructor(
    private server: AspNetUserService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router) { }

  ngOnInit() {
    this.subscriptions.push(this.route.paramMap
      .pipe(switchMap(params => this.server.getUser(params.get('aspNetUserId'))))
        .subscribe(res => {
          this.user = res;
        })
    );

    this.subscriptions.push(this.dataSource._resultingObservable
      .pipe(
        map((result) => {
          this.companies = result;
        })
      )
      .subscribe()
    );
  }

  updateFilter(force:  boolean) {
    if (!this.dataSource) { return; }
    this.dataSource.filter = this.filter;
    if (force) { this.dataSource.forceUpdate(); }
  }

  companySelected(company: Company) {
    this.showConfirmationDialog(company);
  }

  createCompany() {
    this.subscriptions.push(
      this.server.CreateCompany(this.user.AspNetUserID, this.user.Contact.ContactID).subscribe(res => {
        this.snackBar.open('Successfully created a company ' + res);
        this.router.navigate(['./epp-users']);
      }, res => {
        this.snackBar.open('Could not create a company: ' + res.error.Message);
      })
    );
  }

  private showConfirmationDialog(company: Company) {
    const user = this.user;

    // Show modal asking whether the user really wants to associate the user with the company.
    const dialogRef = this.dialog.open(ConfirmAssociateUserComponent, {
      data: {
        company: company,
        user: user
      }
    });
    this.subscriptions.push(
      dialogRef.afterClosed().subscribe(confirmed => {
        if (confirmed) {
          this.subscriptions.push(
            this.server.associateContactToCompany(user.Contact.ContactID, company.CompanyID, confirmed.shouldBeAdmin).subscribe(_ => {
              this.snackBar.open(`${user.Contact.FirstName} ${user.Contact.LastName} is now associated with ${company.Name}`);
              this.router.navigate(['./epp-users']);
            }, err => {
              this.snackBar.open(`Could not associate ${user.Contact.FirstName} ${user.Contact.LastName} with ${company.Name}: ` + err);
            }));
        }
      }));
  }
}
