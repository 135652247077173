import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PurchaseInvoiceSaveDetails } from '@equipmyschool/emsadminweb-models';

@Component({
  selector: 'app-saved-successfully',
  templateUrl: './saved-successfully.component.html',
  styleUrls: ['./saved-successfully.component.scss']
})
export class SavedSuccessfullyComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: InputData
  ) { }

  ngOnInit() {
  }
}

export interface InputData {
  saveDetails: PurchaseInvoiceSaveDetails;
}
