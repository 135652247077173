import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ADMIN_API_SERVER_URL } from '../tokens/admin-api-server-url.token';
import { ServerBaseService } from '../server-base.service';
import { Company, Product } from '@equipmyschool/emsadminweb-models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductImagesService extends ServerBaseService {
  private _clients$ = new BehaviorSubject<Company[]>(null);  
  public clients$ = this._clients$.asObservable();

  private _product$ = new BehaviorSubject<Product>(null);
  public product$ = this._product$.asObservable();

  private _remainingCount$ = new BehaviorSubject<number>(null);
  public remainingCount$ = this._remainingCount$.asObservable();

  private _stillSearching$ = new BehaviorSubject<boolean>(null);
  public stillSearching$ = this._stillSearching$.asObservable();

  private _modelState$ = new BehaviorSubject(null);
  public modelState$ = this._modelState$.asObservable();

  constructor(http: HttpClient, @Inject(ADMIN_API_SERVER_URL) serverUrl: string) {
    super(http, serverUrl);
  }

  public getClients () {
    this.request<Company[]>('GET', 'ProductImage/GetClients', null)
      .subscribe((response: Company[]) => {
         this._clients$.next(response);
      });
  }

  public get (client: number) {
    this._modelState$.next(null);

    this.request<Product>('GET', 'ProductImage/Get', (client ? { client: client } : null))
      .subscribe((response) => {
        this._stillSearching$.next(true);
        this._product$.next(response);
      }, (err) => {
        if (err.status == 404) {
          this._stillSearching$.next(false);
          this._product$.next(null);
        }
      })

    this.request<number>('GET', 'ProductImage/GetRemainingCount', (client ? { client: client } : null))
      .subscribe((response: number) => {
        this._remainingCount$.next(response);
      });
  }

  public post (supplier: number, product: number, url: string, image: File) {
    this._modelState$.next(null);

    const formData: FormData = new FormData();
    formData.append("SupplierID", supplier.toString());
    formData.append("ProductID", product.toString());
    formData.append("ImageUrl", url);
    formData.append("Image", image);

    this.request('POST', 'ProductImage/Post', null, formData)
      .subscribe((response) => {
        this._product$.next(null);
      }, (err) => {
        this._modelState$.next(err.error.ModelState);
      });    
  }
}
