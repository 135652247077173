import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProjectService } from 'app/server/server.module';
import { Project } from '@equipmyschool/emsadminweb-models';

@Component({
  selector: 'app-project-closure',
  templateUrl: './project-closure.component.html',
  styleUrls: ['./project-closure.component.scss']
})
export class ProjectClosureComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription[] = [];
  private projectId: number;
  public project: Project;
  public minDate: Date = new Date();
  public newDate: Date;

  constructor(
    private server: ProjectService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {    
    this._subscriptions.push(
      this.route.params
        .pipe(
          map((params) => {
            this.projectId = params['projectId'];
            this.server.getProject(this.projectId);
          })
        )
        .subscribe()
    );

    this._subscriptions.push(
      this.server.project$
        .pipe(
          map((response) => {
            this.project = response;
            if (response) {
              this.newDate = response.RequiredClosureDate
            }
            else {
              this.newDate = null;
            };
          })
        )
        .subscribe()
    );

    this._subscriptions.push(
      this.server.error$
        .pipe(
          map((error) => {
            this.snackBar.open((error.error.ModelState.ClosureDate ? "Failed to set closure date: " : "Failed to get Project details: " ) + error.error.Message, "OK");
          })
        )
        .subscribe()
    );
  }
  
  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  setClosureDate() {
    this.server.setClosureDate(this.projectId, this.newDate)
  }

}
