import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Staff } from 'coadapi';

@Component({
  selector: 'app-staff-list',
  templateUrl: './staff-list.component.html',
  styleUrls: ['./staff-list.component.scss']
})
export class StaffListComponent implements OnInit {
  selectedIndex: number = -1; // Track the selected index
  stafflist: Staff[]
  taMessage: string = ""

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.stafflist = this.data.list.filter((staff) => staff.email !== "");
  }

  selectedStaff(event): void {
    this.dialog.getDialogById("chase-notes").componentInstance.searching = false;
    this.taMessage = this.dialog.getDialogById("chase-notes").componentInstance.taMessage;
    this.taMessage = this.taMessage.substring(0, this.taMessage.lastIndexOf(" @")+2);
    this.taMessage += event.option.value;
    this.dialog.getDialogById("chase-notes").componentInstance.taMessage = this.taMessage;
    this.dialog.getDialogById("staff-list").close();
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Tab' || event.key === 'Enter') {
      event.preventDefault(); // Prevent the default behavior
      if (event.shiftKey) {
        this.selectedIndex = (this.selectedIndex === 0) ? (this.data.list.length - 1) : (this.selectedIndex - 1);
      } else if (event.key === 'Tab') {
        this.selectedIndex = (this.selectedIndex === this.data.list.length - 1) ? 0 : (this.selectedIndex + 1);
      } else if (event.key === 'Enter') {
        this.selectedStaff({ option: { value: this.data.list.filter(hero => hero.firstName.toLowerCase().indexOf(this.data.search.toLowerCase()) !== -1)[this.selectedIndex].email } });
      }
    }
  }
}
