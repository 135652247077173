<!-- Consider moving this to a sidenav https://material.angular.io/components/sidenav/examples -->
<form #form="ngForm">
  <div fxLayout="column" fxLayoutAlign="stretch"
    class="max-width-on-hover-container">
    <mat-card class="available-lines">
      <h3>Available Lines</h3>

      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-form-field style="width: 400px">
          <mat-select placeholder="Supplier" [(ngModel)]="selectedSupplier"
            [disabled]="invoice.Lines.length > 0"
            [ngModelOptions]="{standalone: true}"
            [compareWith]="supplierCompareFn">
            <mat-option *ngFor="let supplier of suppliers" [value]="supplier">
              {{supplier.Name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <span class="spacer"></span>

        <mat-form-field>
          <mat-select placeholder="PO Reference"
            [(ngModel)]="selectedPurchaseOrder" [disabled]="!selectedSupplier"
            [ngModelOptions]="{standalone: true}"
            [compareWith]="orderCompareFn">
            <mat-option *ngFor="let order of (purchaseOrders$ | async)"
              [value]="order">
              {{order.Reference}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <span class="spacer"></span>

        <button class="no-shrink" mat-raised-button type="button" (click)="searchProduct()">Search for Product</button>
      </div>

      <app-purchase-order-lines-table
        *ngIf="(linesDataSource | async).length > 0" [lines]="linesDataSource"
        (lineClicked)="toggleSelected($event)" [isRowSelected]="isRowSelected"
        [commodities]="commodities" [supplier]="selectedSupplier">
      </app-purchase-order-lines-table>
    </mat-card>

    <mat-card class="purchase-invoice">
      <mat-card-title style="margin: -24px -24px 10px -24px;">
        <mat-toolbar color="primary">
          <h2>{{ documentTypeReadable }}</h2>

          <span class="spacer"></span>

          <button mat-raised-button type="button" color="warn"
            matTooltip="Reset"
            [disabled]="form.control.pristine && (selectedLinesDataSource | async).length === 0"
            (click)="reset()">
            <mat-icon>replay</mat-icon>
          </button>
        </mat-toolbar>
      </mat-card-title>
      <div fxLayout="row" style="display: flex; align-items: baseline">
        <mat-form-field style="width: 400px;">
          <input matInput type="text" placeholder="Invoice Number" [required]=true
            [(ngModel)]="invoice.InvoiceNumber" name="InvoiceNumber"
            [appAsyncValidators]="isInvoiceNumberDuplicate">
          <mat-error
            *ngIf="form.controls['InvoiceNumber']?.invalid && form.controls['InvoiceNumber']?.errors.serverErrors">
            {{form.controls['InvoiceNumber']?.errors.serverErrors[0]}}
          </mat-error>
        </mat-form-field>

        <span class="spacer"></span>

        <mat-form-field>
          <input matInput [(ngModel)]="invoice.Date" [max]="today" [required]=true
            [matDatepicker]="picker" placeholder="Invoice Date" name="Date">
          <mat-datepicker-toggle matSuffix [for]="picker">
          </mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <div *ngIf="selectedSupplier" style="white-space: nowrap;">
          <div>
            Exchange Rate: {{ invoice.ExchangeRate | number: '0.3-3'}}
            <button mat-icon-button type="button" (click)="setExchangeRate()"
              matTooltip="Foreign Currency">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="selectedSupplier">
        <app-purchase-order-lines-table
          *ngIf="(selectedLinesDataSource | async).length > 0"
          [lines]="selectedLinesDataSource"
          (lineClicked)="toggleSelected($event)" [isRowSelected]="isRowSelected"
          (split)="split($event)" [commodities]="commodities" [editable]=true
          [supplier]="selectedSupplier"></app-purchase-order-lines-table>
        <div *ngIf="(selectedLinesDataSource | async).length === 0">
          Currently the {{ documentTypeReadable }} contains no lines
        </div>
      </div>

      <div fxLayout fxLayoutAlign="space-between" style="margin-top: 12px;">
        <button mat-fab type="button" color="primary" fxFlexAlign="end"
          matTooltip="Save" [disabled]="!form.valid || invoice.Lines.length < 1"
          (click)="save()">
          <mat-icon>save</mat-icon>
        </button>

        <div class="totals-grid">
          <mat-form-field id="disc-tax"
            *ngIf="applySubTotalDiscount && selectedSupplier?.TaxType === TaxType.UKVAT">
            <input matInput type="number" step="0.01"
              placeholder="Discounted UK VAT" [disabled]=true
              [ngModel]="calculateDiscountedUkVat() | simpleDecimal:'1.2-2'"
              [ngModelOptions]="{standalone: true}">
            <span matSuffix>&nbsp;{{currencyCode}}</span>
          </mat-form-field>

          <mat-checkbox id="disc-check" [(ngModel)]="applySubTotalDiscount"
            (ngModelChange)="this.invoice.Discount = .0"
            [ngModelOptions]="{standalone: true}"></mat-checkbox>

          <mat-form-field id="total-disc">
            <input matInput type="number" step="0.01" placeholder="Discount"
              [disabled]="!applySubTotalDiscount" name="Discount"
              (click)="$event.target"
              [ngModel]="invoice.Discount | simpleDecimal:'1.2-2'"
              [ngModelOptions]="{updateOn: 'blur'}"
              (ngModelChange)="invoice.Discount=$event">
            <span matSuffix>&nbsp;{{currencyCode}}</span>
          </mat-form-field>

          <mat-form-field id="total-freight">
            <input matInput type="number" step="0.01"
              placeholder="Freight Charge" name="Freight"
              (click)="$event.target"
              [ngModel]="invoice.Freight | simpleDecimal:'1.2-2'"
              [ngModelOptions]="{updateOn: 'blur'}"
              (ngModelChange)="invoice.Freight=$event">
            <span matSuffix>&nbsp;{{currencyCode}}</span>
          </mat-form-field>

          <mat-checkbox id="freight-tax-check" [(ngModel)]="invoice.VATFreight"
            *ngIf="selectedSupplier?.TaxType === TaxType.UKVAT"
            name="VATFreight">Add UK VAT?</mat-checkbox>

          <mat-form-field id="total-surcharge">
            <input matInput type="number" step="0.01" placeholder="Surcharge"
              name="Surcharge" (click)="$event.target"
              [ngModel]="invoice.Surcharge | simpleDecimal:'1.2-2'"
              [ngModelOptions]="{updateOn: 'blur'}"
              (ngModelChange)="invoice.Surcharge=$event">
            <span matSuffix>&nbsp;{{currencyCode}}</span>
          </mat-form-field>

          <mat-checkbox id="surcharge-tax-check"
            [(ngModel)]="invoice.VATSurcharge"
            *ngIf="selectedSupplier?.TaxType === TaxType.UKVAT"
            name="VATSurcharge">Add UK VAT?</mat-checkbox>

          <mat-form-field id="us-sales-tax"
                          *ngIf="selectedSupplier?.TaxType === TaxType.USSalesTax">
            <input matInput type="number" step="0.01"
                   placeholder="US Sales Tax" name="USSalesTax"
                   (click)="$event.target"
                   [ngModel]="invoice.Tax | simpleDecimal:'1.2-2'"
                   [ngModelOptions]="{updateOn: 'blur'}"
                   (ngModelChange)="invoice.Tax=$event">
            <span matSuffix>&nbsp;{{currencyCode}}</span>
          </mat-form-field>

          <mat-form-field id="total-tax"
            *ngIf="selectedSupplier?.TaxType === TaxType.UKVAT">
            <input matInput type="number" step="0.01" placeholder="UK VAT"
              [disabled]=true [ngModel]="calculateVatTotal() | simpleDecimal:'1.2-2'"
              [ngModelOptions]="{standalone: true}">
            <span matSuffix>&nbsp;{{currencyCode}}</span>
          </mat-form-field>

        </div>
      </div>
    </mat-card>
  </div>
</form>
