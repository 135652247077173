import { Component, OnInit, Input, EventEmitter, Output, ViewChild, AfterViewInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CrawlerStatusPage, Supplier } from '@equipmyschool/emsadminweb-models';

@Component({
  selector: 'app-crawler-status-table',
  templateUrl: './crawler-status-table.component.html',
  styleUrls: ['./crawler-status-table.component.scss']
})
export class CrawlerStatusTableComponent implements OnInit, AfterViewInit {
  displayedColumns = ['Supplier', 'Count', 'Errors', 'ToCheck', 'LastChecked'];

  @Input()
  set supplierRows(value: CrawlerStatusPage[]) {
    if(value) {
      this.dataSource.data = this.dataSource.sortData(value, this.sort);
    } else {
      this.dataSource.data = [];
    }
  }

  @ViewChild(MatSort) sort: MatSort;


  @Output()
  public downloadSpreadsheet = new EventEmitter<Supplier>();

  public dataSource = new MatTableDataSource<CrawlerStatusPage>([]);

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.dataSource.sortingDataAccessor = (data: CrawlerStatusPage, sortHeaderId: string) => {
      var value: string | number;

      switch (sortHeaderId) {
        case 'Supplier':
          value = data.Supplier.Name.toLowerCase();
          break;
        case 'Count':
          value = data.PageCount;
          break
      }
      return typeof value === 'string' ? value.toLowerCase() : value;
    };
    this.dataSource.sort = this.sort;
  }

  onErrorsClick(supplier: Supplier) {
    this.downloadSpreadsheet.emit(supplier);
  }
}
