<perfect-scrollbar style="max-width: 100vw; max-height: 100vh;">
  <mat-tree [dataSource]="departmentTree$ | dataSource" [treeControl]="treeControl">
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChildren">
      <div>
        <div class="mat-tree-node">
          <button mat-icon-button matTreeNodeToggle [matTreeNodeToggleRecursive]=false>
            <mat-icon>
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <a href="javascript:void(0)" (click)="select(node)" [class.selected]="selectedNode && (selectedNode.DepartmentID === node.DepartmentID)">{{node.Name}}</a>
        </div>
        <ul [hidden]="!treeControl.isExpanded(node)">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </div>
    </mat-nested-tree-node>

    <mat-tree-node *matTreeNodeDef="let node">
      <div>
        <div>
          <button mat-icon-button [disabled]=true>
            <mat-icon>
              remove
            </mat-icon>
          </button>
          <a href="javascript:void(0)" (click)="select(node)">{{node.Name}}</a>
        </div>
      </div>
    </mat-tree-node>
  </mat-tree>
</perfect-scrollbar>
