<h1 mat-dialog-title>{{data.title}}</h1>
<mat-select [(ngModel)]="selectedOption">
  <mat-option *ngFor="let choice of data.options" [value]="choice">
    {{choice.name}}  
  </mat-option>
</mat-select>
<ng-container *ngIf="selectedOption?.detail">
    {{selectedOption.detail}}
</ng-container>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="selectedOption" tabindex="1">OK</button>
  <button mat-button mat-dialog-close tabindex="-1">Cancel</button>
</mat-dialog-actions>
