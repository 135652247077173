import { Component, HostBinding, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { UnsubscribeOnDestroy } from 'app/shared/decorators/unsubscribe-on-destroy';
import { Subscription } from 'rxjs';
import { fadeInAnimation } from '../route.animation';
import { AccountService } from '../server/server.module';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [fadeInAnimation]
})
export class LoginComponent implements OnInit {
  @HostBinding('@fadeInAnimation') fadeInAnimation = true;
  credential: any;

  @UnsubscribeOnDestroy()
  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private server: AccountService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute
  ) {
    this.credential = {};
  }

  ngOnInit() {
    // If user is already logged in, disallow visiting login page
    this.subscriptions.push(
      this.server.whoAmI().subscribe(
        (loggedIn) => {
          this.router.navigate(['/']);
        }));
  }

  login() {
    this.subscriptions.push(
      this.server.logIn(this.credential).subscribe(
        res => {
          this.route.queryParams.subscribe(queryParams => {
            if (queryParams.redirectTo) {
              this.router.navigateByUrl(queryParams.redirectTo);
            } else {
              this.router.navigate(['/']);
            }
          });
        },
        err => {
          this.snackBar.open('Could not log in: ' + err.error.ModelState['']);
        }
      ));
  }

}
