import { Component, OnInit } from '@angular/core';
import Utils from './abstracts/_utils';
import { Supplier, ChsSuppliersService, DdDispatchDetails, ChasingSnapshot, AssignmentList, GenStaffService, SupplierAssignment } from 'coadapi';
import { DDActionService } from '../proforma-to-dispatch/abstracts/_datasharing';
import { ChsActionService, sharedUserData } from './abstracts/_data-sharing';
import { finalize, startWith, switchMap } from 'rxjs/operators';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CdkDragDrop, CdkDragRelease, CdkDragStart, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChaseNotesComponent } from './pages/dialogs/chase-notes/chase-notes.component'
import { DialogPosition, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ConfirmationComponent } from '../dialogs/confirmation/confirmation.component';

interface SupplierWithProcessing extends Supplier {
  processing: boolean;
}

interface AssignmentListWithProcessing extends AssignmentList {
  assigned: SupplierWithProcessing[];
}

interface GroupedSuppliers {
  warehouseId: string;
  suppliers: Supplier[];
}

@Component({
  selector: "app-chasing-board",
  templateUrl: "./chasing-board.component.html",
  styleUrls: ["./chasing-board.component.scss"],
})
export class ChasingBoardComponent implements OnInit {
  randomPhrase = Utils.randomPhrase;
  dispatchDetails: DdDispatchDetails[] = [];
  suppliers: GroupedSuppliers[] = [];
  assignmentIDList: string[] = [];
  assignmentList: AssignmentListWithProcessing[] = [];
  disassignmentIDList: string[] = [];
  staffList: any;
  purchasingStaff: any;
  orderDir: string;
  sortBy: string;
  lastSort: any;
  orderMod: boolean;
  orderVar: any;
  warehouse = 1;
  firstLoad = true;
  sacrificialArray: any[] = [];
  subscriptions: Subscription[] = [];
  draggingItem: Supplier| null = null;
  draggedItemList: (Supplier | null)[] = [];
  initialContainerId: string;

  constructor(
    private chsSuppliersService: ChsSuppliersService,
    private staffService: GenStaffService,
    public ddActionService: DDActionService,
    public chsActionService: ChsActionService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    public sharedUserData: sharedUserData,
    
  ) {
    this.iconRegistry.addSvgIcon(
      "edit-pen",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "app/coad-web/proforma-to-dispatch/images/editsvg.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "looking-glass",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "app/coad-web/proforma-to-dispatch/images/contentssvg.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "iwdr",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "app/coad-web/proforma-to-dispatch/images/iwdrsvg.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "trash-can",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "app/coad-web/proforma-to-dispatch/images/trashsvg.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "tick",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "app/coad-web/proforma-to-dispatch/images/tick.svg"
      )
    );
  }

  ngOnInit(): void {
    if (this.firstLoad) {
      this.getBoard();
      this.firstLoad = false;
    }
    this.subscriptions.push(
      this.staffService.getAllStaff().subscribe((res) => {
        this.staffList = res;
      })
    );
  }

  setWarehouse(warehouse: number) {
    this.warehouse = warehouse;
    this.assignmentIDList = this.assignmentList.filter((assignment) => {
      // If warehouse is 5, use 2 to show US chasers, otherwise use the warehouse value itself
      const warehouse = 1 * this.warehouse === 5 ? 2 : this.warehouse;
      return assignment.assignee.purchasingUser === 1 * warehouse;
    }).map((assignment) => "assignment" + assignment.assignee.adminId);
    this.disassignmentIDList = this.assignmentIDList
    this.disassignmentIDList.push("drop-zone")
  }

  getBoard() {
    this.subscriptions.push(
      this.chsActionService.chsAction$
        .pipe(
          startWith(""),
          switchMap(() => {
            return this.chsSuppliersService.chsGetBoard();
          })
        )
        .subscribe((chasingSnapshot: ChasingSnapshot) => {
          this.processSnapshot(chasingSnapshot);
          this.draggedItemList = [];
        })
    );
    this.chsActionService.chsAction$.next();
  }

  getPurchasingStaffFirstName(purchasingStaffId: number): string {
    const staff = this.purchasingStaff.find(
      (x) => x.adminId == purchasingStaffId
    );
    return staff ? staff.firstName : "";
  }

  countLines(assigned: Supplier[]): number {
    var count = 0;
    for (let i = 0; i < assigned.length; i++) {
      if (assigned[i] === null) {
        count += 0;
        continue;
      }
      count += assigned[i].lineCount;
    }
    return count;
  }

  countPoLines(assigned: Supplier[]): number {
    var count = 0;
    for (let i = 0; i < assigned.length; i++) {
      if (assigned[i] === null) {
        count += 0;
        continue;
      }
      count += assigned[i].poCount;
    }
    return count;
  }

  isPurchasingAdmin(): boolean {
    const isAdmin = this.staffList.find((staff) => staff.contact === this.sharedUserData.getUser().ContactID);
    return isAdmin ? isAdmin.purchasingAdmin === 1 : false;
  }

  processSnapshot(snapshot: ChasingSnapshot) {
    this.purchasingStaff = this.staffList.filter(
      (staff) =>
        !staff.purchasingAdmin
    );

    // Map adminId values from purchasingStaff
    const purchasingStaffAdminIds = this.purchasingStaff.map(
      (staff) => staff.adminId
    );

    this.assignmentList = snapshot.assignments
    .filter((assignment) =>
      purchasingStaffAdminIds.includes(assignment.assignee.adminId)
    )
    .map((assignment) => ({
      ...assignment, // Copy existing properties
      assigned: assignment.assigned?.map((supplier) => ({
        ...supplier,       // Copy Supplier properties
        processing: false  // Add processing property
      })) || [] // Ensure assigned is an array
    }));

    this.assignmentIDList = this.assignmentList.filter((assignment) => {
      // If warehouse is 5, use 2 to show US chasers, otherwise use the warehouse value itself
      const warehouse = 1 * this.warehouse === 5 ? 2 : this.warehouse;
      return assignment.assignee.purchasingUser === 1 * warehouse;
    }).map((assignment) => "assignment" + assignment.assignee.adminId);
    this.disassignmentIDList = this.assignmentIDList
    this.disassignmentIDList.push("drop-zone")

    this.dispatchDetails = snapshot.dispatch;
    
    // Step 1: Group suppliers by warehouse
    const groupedSuppliers = snapshot.suppliers.reduce((acc, supplier) => {
      const warehouseId = supplier.supplierShipTo; // Use supplierShipTo as the key
      if (!acc[warehouseId]) {
        acc[warehouseId] = [];
      }
      acc[warehouseId].push(supplier);
      return acc;
    }, {} as { [warehouseId: number]: Supplier[] });

    // Step 2: Filter and map within each warehouse group
    const suppliersByWarehouse = Object.keys(groupedSuppliers).map(warehouseId => {
      const filteredSuppliers = groupedSuppliers[warehouseId]
        .filter(
          (supplier) =>
            !this.assignmentList.some((assignment) =>
              assignment.assigned.some(
                (assignedSupplier) => assignedSupplier.supplierId === supplier.supplierId
              )
            )
        )
        .map((supplier) => ({
          ...supplier,          // Copy existing Supplier properties
          processing: false     // Add the processing property, defaulting to false
        }));

      return {
        warehouseId,           // Add the warehouseId as a key
        suppliers: filteredSuppliers  // Add the filtered and mapped suppliers
      };
    });

    // Step 3: Assign the result to this.suppliers
    this.suppliers = suppliersByWarehouse;
    
  }

  getSuppliersForWarehouse(warehouseId: number): Supplier[] {
    const warehouse = this.suppliers.find(wh => +wh.warehouseId === +warehouseId);
    return warehouse ? warehouse.suppliers : [];
  }

  getData() {
    this.chsActionService.chsAction$.next();
  }

  setSnapshot(assigneeId: number, supplier: Supplier) {
    this.subscriptions.push(      
      this.chsSuppliersService.chsAssign(assigneeId, supplier.supplierId, !!supplier.complete).subscribe(() => {
        this.chsActionService.chsAction$.next()
      })
    )
  }

  unassignSnapshot(assigneeId: number, supplier: Supplier) {
    let supplierAssignment: SupplierAssignment = {
      assigneeId: assigneeId,
      newSupplier: supplier,
    };
    this.subscriptions.push(      
      this.chsSuppliersService.chsUnassign(supplierAssignment).subscribe(() => {
        this.chsActionService.chsAction$.next();
        
      })
    )
  }

  reassignSnapshot(assigneeId: number, supplier: Supplier, newAssigneeId: number) {
    let supplierAssignment: SupplierAssignment = {
      assigneeId: assigneeId,
      newSupplier: supplier,
    };
    this.subscriptions.push(      
      this.chsSuppliersService.chsUnassign(supplierAssignment).subscribe(() => {
        supplierAssignment.assigneeId = newAssigneeId
        this.subscriptions.push(
          this.chsSuppliersService.chsAssign(newAssigneeId, supplier.supplierId, !!supplier.complete).subscribe(() => {
            this.chsActionService.chsAction$.next();
          })
        )
      })
    )
  }

  onImgError(event) {
    event.target.src = this.orderDir + ".png";
  }

  sort(sort: string) {
    if (this.dispatchDetails) {
      this.sortBy = sort;
      if (this.sortBy === this.lastSort) {
        this.orderMod = !this.orderMod;
      } else {
        this.orderMod = false;
      }
      this.lastSort = sort;
      // this.orderMod = !this.orderMod;
      if (this.orderMod) {
        this.orderVar = this.sortBy;
        this.orderDir = "up";
      } else {
        this.orderVar = "-" + this.sortBy;
        this.orderDir = "down";
      }
      this.dispatchDetails = [
        ...this.dispatchDetails.sort((a, b) =>
          a[this.orderVar] > b[this.orderVar] ? 1 : -1
        ),
      ];
    }
  }

  drop(event: CdkDragDrop<any>) {
    if (!event.isPointerOverContainer) {
      // console.log('pointer not over container');
      return;
    } else {
      if (event.previousContainer.data.assigned) {
        const eventSupplier = event.previousContainer.data.assigned[event.previousIndex]
        transferArrayItem(
          event.previousContainer.data.assigned,
          event.container.data.assigned,
          event.previousIndex,
          event.currentIndex
        );
        this.assignmentList.find(x => x.assignee.adminId == event.container.data.assignee.adminId).assigned.sort((a, b) => {
          return a.supplierName > b.supplierName ? 1 : -1
        })
        const newIndex = this.assignmentList.find(x => x.assignee.adminId == event.container.data.assignee.adminId).assigned.findIndex(x => x.supplierId == eventSupplier.supplierId)
        this.reassignSnapshot(
          event.previousContainer.data.assignee.adminId,
          event.container.data.assigned[newIndex],
          event.container.data.assignee.adminId
        )
      }
      else {
        
        const eventSupplier = event.previousContainer.data[event.previousIndex]
        transferArrayItem(
          event.previousContainer.data,
          event.container.data.assigned,
          event.previousIndex,
          event.currentIndex
        );
        this.assignmentList.find(x => x.assignee.adminId == event.container.data.assignee.adminId).assigned.sort((a, b) => {
          return a.supplierName > b.supplierName ? 1 : -1
        })
        const newIndex = this.assignmentList.find(x => x.assignee.adminId == event.container.data.assignee.adminId).assigned.findIndex(x => x.supplierId == eventSupplier.supplierId)
        this.setSnapshot(
          event.container.data.assignee.adminId,
          event.container.data.assigned[newIndex]
        );
      }
    }
  }

  onDragStarted(event: CdkDragStart, item: Supplier) {
    // Store the initial container ID when dragging starts
    this.initialContainerId = event.source.dropContainer.id;
    this.draggedItemList.push(item);
  }
  
  isDragging(item: Supplier): boolean {
    return this.draggedItemList.findIndex(di => di.supplierId == item.supplierId) > -1;
  }
  
  onDragReleased(event: CdkDragRelease, item: Supplier) {
    const dropContainerId = event.source.dropContainer.id;
  
    // Check if the item was dropped in the same container it was dragged from
    if (dropContainerId === this.initialContainerId) {
      // Remove the item from the draggedItemList
      const index = this.draggedItemList.findIndex(
        (di) => di.supplierId === item.supplierId
      );
      if (index > -1) {
        this.draggedItemList.splice(index, 1);
      }
    }

    // Reset initialContainerId after release
    this.initialContainerId = '';
  }

  dropDisassociate(event: CdkDragDrop<any>) {
    const targetAttr = document.getElementById(event.previousContainer.id);
    // offsettop = distance from top of the container, offsetleft = distance from left of the container, not including the... thing with the dropdowns. the workspace basically
    // offset height and width is the height and width of the element itself
    const leavingy = targetAttr.offsetHeight + targetAttr.offsetTop;
    const leavingx = targetAttr.offsetWidth + targetAttr.offsetLeft;
    let withinElement = false;

    if (event.dropPoint.x > targetAttr.offsetLeft 
      && event.dropPoint.x < leavingx 
      && event.dropPoint.y > targetAttr.offsetTop 
      && event.dropPoint.y < leavingy) 
    {
      withinElement = true;
    }
    if (withinElement) {
    this.draggedItemList.splice(this.draggedItemList.findIndex(di => di.supplierId == event.previousContainer.data.assigned[event.previousIndex].supplierId));
      return;
    }

    if (!event.isPointerOverContainer || !event.previousContainer.data.assigned) {
      // console.log('pointer not over container');
      this.draggedItemList.splice(this.draggedItemList.findIndex(di => di.supplierId == event.previousContainer.data.assigned[event.previousIndex].supplierId));
      return;
    } else {
      const sacrificialArray: any[] = [];
      const supplierWithProcessing = event.previousContainer.data.assigned[event.previousIndex];
      supplierWithProcessing.processing = true;  // Set processing to true

      this.unassignSnapshot(
        event.previousContainer.data.assignee.adminId,
        event.previousContainer.data.assigned[event.previousIndex]
      ) 
    }
  }

  clearAssignments(id: number) {
    this.subscriptions.push(
      this.chsSuppliersService.chsClearAssignee(id).subscribe(() => {
        this.chsActionService.chsAction$.next();
      })
    )    
  }

  toggleAssignments(assignee: number, item: any) {
    let supplierAssignment: SupplierAssignment = {
      assigneeId: assignee,
      newSupplier: item,
      userId: this.sharedUserData.getUser().ContactID
    };
    this.subscriptions.push(
      this.chsSuppliersService.chsComplete(supplierAssignment).subscribe(() => {
        this.chsActionService.chsAction$.next();
      })
    );
  }

  /**
   * Opens the chase notes dialog
   * @param event - the click event
   * @param assignee - the assigned user's id
   * @param supplier - the supplier's id
   */
  openChaseNotes(event, assignee: number, supplier: number) {
    const targetAttr = event.target.getBoundingClientRect(); // get the bounding rectangle of the clicked element

    const dialogConfig = new MatDialogConfig(); // create a new dialog configuration
    dialogConfig.disableClose = false; // set if the dialog can be closed
    dialogConfig.autoFocus = true; // set if the dialog should focus on the first focusable element
    dialogConfig.id = "chase-notes"; // set the dialog's id
    dialogConfig.data = { supplier: supplier, staffList: this.staffList }; // set the dialog's data (supplier's id)

    const dialogposition: DialogPosition = { // create a new DialogPosition object
      top: "0px", // set the top position of the dialog
      left: "0px" // set the left position of the dialog
    };

    // calculate the position of the dialog based on the position of the clicked element
    if (targetAttr.y + targetAttr.height + 10 + 60 > window.innerHeight) {
      // if the dialog would be cut off at the bottom of the screen
      dialogposition.top = targetAttr.y - (200 - (window.innerHeight - targetAttr.y)) + "px"; // position it above the clicked element
    } else {
      // otherwise
      dialogposition.top = targetAttr.y + targetAttr.height + 10 + "px"; // position it below the clicked element
    };

    if (targetAttr.x + targetAttr.width + 10 + 300 > window.innerWidth) {
      // if the dialog would be cut off at the right side of the screen
      dialogposition.left = targetAttr.x - targetAttr.width - (300 - (window.innerWidth - targetAttr.x)) + "px"; // position it to the left of the clicked element
    } else {
      // otherwise
      dialogposition.left = targetAttr.x - targetAttr.width - 20 + "px"; // position it to the right of the clicked element
    };

    dialogConfig.position = dialogposition; // set the dialog's position

    let chasenotesRef = this.dialog.open(ChaseNotesComponent, dialogConfig); // open the dialog
    chasenotesRef.afterClosed().pipe(
      finalize(() => chasenotesRef = undefined)
    );
  }

  autoAssign(warehouse: number) {
    this.chsSuppliersService.chsGetBoard().subscribe((chasingSnapshot) => {
      this.chsSuppliersService.chsAutoAssign(chasingSnapshot,warehouse).subscribe((chasingSnapshot) => {
        this.processSnapshot(chasingSnapshot);
      })
    })
  }

  confirm(reason: string, Id: number) {
    if (!reason) 
    {
      return; 
    } 

    const confirmDialogRef  = this.dialog.open(ConfirmationComponent, {
      id: 'confirmation-dialog',
      width: '600px',
      disableClose: true,
      panelClass: 'confirmation-pane',
      data: {
        titleText: "Are you sure you want to " + reason +  "? "
      }
    });

    this.subscriptions.push(
      confirmDialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (reason === "clear") {
            this.clearAssignments(Id);
          } else if (reason === "auto assign") {
            this.autoAssign(Id);
          }          
        }
      })
    )

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
