<mat-toolbar class="mat-elevation-z4">
  <span class="row-1"><h1>Proformas to Dispatch</h1></span>
  <div class="download-summary" (click)="getReport()"><div class="text">Download Summary</div></div>
  <span class="row-2"><h2>Select a Client</h2></span>
</mat-toolbar>

 <div class = "workspace">
  <div class = "grid">
    <div *ngFor="let client of clientSummaries$ | async">
      <mat-card class="client-tile mat-elevation-z4" *ngIf="client.clientId" [routerLink]="['../pages/client', client.clientId]" routerLinkActive="active">
        <mat-card-title>
          <div class = "title">
            <div class = "account-no">{{client.accountNo}}</div>
            <div class = "warning" *ngIf="client.outstandingPfiParts > 0"><div class = "outstanding">{{client.outstandingPfiParts}}</div></div>
          </div>
        </mat-card-title>
        
        <div class="vertical-divide"></div>
        <div class="client-dispatches">
          <div class="dispatch" *ngFor="let dispatch of client.dispatches">
            <div class="top-row">
              <div class="accDispatchNo">{{client.accountNo}}-{{dispatch.dispatchNo}}{{dispatch.dispatchNoLetter.commodityID === 2005 ? "V" : dispatch.dispatchNoLetter.letter}}</div>
              <div class="iwdStatus">
                <img *ngIf="!dispatch.closedToIwd && dispatch.hasIwd" src="app/coad-web/proforma-to-dispatch/images/iwdrsvg.svg">
                <img *ngIf="dispatch.closedToIwd" src="app/coad-web/proforma-to-dispatch/images/iwdrclosedsvg.svg">
              </div>
              <div class="dispatchMethod">{{methodShortName(dispatch.freightMethod)}}</div>  
            </div>
            <div class="bottom-row-{{dispatch.warehouse.region | lowercase | removewhitespace}}">
              <div class="progressBar-strong" [style.width.%] = "dispatch.percentComplete"></div>
              <div class="locked" *ngIf="dispatch.isClosed"><img src="app/coad-web/proforma-to-dispatch/images/locksvg.svg"
                onerror="this.src='app/coad-web/proforma-to-dispatch/images/locksvg.png'" height="16px" alt="locked">
              </div>
              <div class="progressPercent">
                {{dispatch.percentComplete}}%
              </div>
            </div>
          </div>
          <img class="no-dispatch-background" *ngIf="client.dispatches.length === 0" src="app/coad-web/proforma-to-dispatch/images/noDispatch.svg" alt="noDispatch">
        </div>
        <p class="divide"></p>
        <div class="client-humans">
          <div class="image">
            <img src={{client.admin.imageUrl}}>
          </div>
          <div class="text">
            <p class="ccs-name">{{client.admin.firstName}}</p>
            <p class="deputy-name">{{client.deputy.firstName}}</p>
          </div>
        </div>
        <p class="divide"></p>
        <div class="freight-agent">
          <p *ngIf="client.freightAgent">{{client.freightAgent.companyName}}</p>
        </div>
      </mat-card>
    </div>
  </div>
</div>