import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort';
import { environment } from 'environments/environment';
import { Subscription, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { QuickbooksService } from 'app/server/server.module';
import { QuickBooksCompany, QuickBooksErrorCompany, QuickBooksErrorDocument, QuickBooksExportCompany, QuickBooksExportRow, QuickBooksReconciliationCollection, QuickBooksReconciliationMismatch, QuickBooksReconciliationObject } from '@equipmyschool/emsadminweb-models';
import { ExportDetailsComponent } from './export-details/export-details.component';

@Component({
  selector: 'app-export-to-quickbooks',
  templateUrl: './export-to-quickbooks.component.html',
  styleUrls: ['./export-to-quickbooks.component.scss']
})
export class ExportToQuickbooksComponent implements OnInit, OnDestroy {
  formArray: FormArray;
  form: FormGroup;
  private _subscriptions: Subscription[] = [];

  public quickBooksRealm: string;
  public quickBooksCompany: QuickBooksCompany;
  public qbAction: string;  
  public qbObject: string;
  private _exporting: boolean = false;

  public qbReconDB: string;
  public qbReconView: string;
  public haveReconData: boolean = false;

  private _qbReconCollection: QuickBooksReconciliationCollection;
  private _emsReconCollection: QuickBooksReconciliationCollection;
  private _reconciliationMismatches: QuickBooksReconciliationMismatch[];
  private _reconData: QuickBooksReconciliationObject[];
  private _qbExportErrors: QuickBooksErrorCompany[];

  displayedColumns = ['Select', 'Exists', 'CompanyName', 'AccountNo', 'Currency', 'ToExport', 'Value', 'Oldest'];
  dataSource = new MatTableDataSource<AbstractControl>([]);
  selection = new SelectionModel<AbstractControl>(true, []);

  reconColumns = [];
  reconSource = new MatTableDataSource<QuickBooksReconciliationObject>([]);
  reconSpans = [];
  
  search$ = new Subject<SupplierSearch>();
  nameOptions$: Observable<QuickBooksExportCompany[]> = this.server.vendorList$;

  constructor(
    private server: QuickbooksService,
    private _fb: FormBuilder,
    public dialog: MatDialog
  ) {
  }

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.formArray = this._fb.array([]);
    this.form = this._fb.group({ formArray: this.formArray });
    
    this._subscriptions.push(
      this.server.qbExportRows$
        .pipe(
          map((response) => {
            var index = this.displayedColumns.indexOf('MissingVAT');
            if (this.qbObject === "PurchaseInvoices" && index === -1) {
              this.displayedColumns.push('MissingVAT');
            }
            else if (this.qbObject !== "PurchaseInvoices" && index !== -1) {
              this.displayedColumns.splice(index, 1);
            }
            
            this.selection.clear();

            if (response) {
              while (this.formArray.length !== 0) { this.formArray.removeAt(0) }
              response.forEach(
                (eR: QuickBooksExportRow) => {
                  // Create the FormGroup for the row
                  // We need to do this so it can be sorted
                  var r =  this._fb.group({
                    Company: [eR.Company, [Validators.required]],
                    CompanyName: [eR.CompanyName, [Validators.required, Validators.minLength(1), Validators.maxLength(300)]],
                    AccountNo: [eR.AccountNo, [Validators.required, Validators.minLength(1), Validators.maxLength(20)]],
                    Currency: [eR.Currency, [Validators.required]],
                    Exists: [eR.Exists],
                    ToExport: [eR.ToExport],
                    Value: [eR.Value],
                    MissingVAT: [eR.MissingVAT],
                    Oldest: [eR.Oldest],
                    Errors: null
                  });

                  // If we have any errors returned from the server
                  if (this._qbExportErrors) {
                    // Check them and add them to the relevant rows
                    this._qbExportErrors.forEach((qbError) => {
                      if (qbError.CompanyID === r.value['Company']) {
                        r.controls['Errors'].setValue(qbError);
                      }
                    });
                  }

                  // Add the row's FormGroup to the array
                  this.formArray.push(r);
                  // Mark all controls as touched.
                  // This allows the initial validation to be done
                  r.markAllAsTouched();
                }
              );
              
              if (this.sort) {
                this.dataSource.data = this.dataSource.sortData(this.formArray.controls, this.sort);
                this.dataSource.sortingDataAccessor = (data: AbstractControl, sortHeaderId: string) => {
                  const value: any = data.value[sortHeaderId];
                  return typeof value === 'string' ? value.toLowerCase() : value;
                };
                this.dataSource.sort = this.sort;
              }
              
              var companyType = this.qbObject === "PurchaseInvoices" ? 2: 1;
              var accounts = Array.from(response, row => row.AccountNo);
              this.server.getValidAccounts(companyType, this.quickBooksRealm, accounts);
            }
          })
        )
        .subscribe()
    );

    this._subscriptions.push(
      this.server.qbExportErrors$
        .pipe(
          map((response) => {
            this._qbExportErrors = response;
          })
        )
        .subscribe()
    );

    this._subscriptions.push(
      this.server.qbExportDetails$
        .pipe(
          map((response) => {
            if (response) {
              const errors: QuickBooksErrorDocument[] = this._qbExportErrors?.find((f:QuickBooksErrorCompany) => f.CompanyID === response.Company.CompanyID)?.Documents
              const diag: MatDialogRef<ExportDetailsComponent> = this.dialog.open(ExportDetailsComponent, {data: {data: response, errors: errors}, width: "100%"});
              diag.afterClosed().subscribe(() => {
                this.server.clearDetailedData();
              })
            }
          })
        )
        .subscribe()
    );

    this._subscriptions.push(
      this.server.reconciliationData$
        .pipe(
          map((response) => {
            if (response) {
              this._qbReconCollection = response.QBReconciliation;
              this._emsReconCollection = response.EMSReconciliation;
              this._reconciliationMismatches = response.Mismatches;
              this.haveReconData = true;
            } else {
              this.haveReconData = false;
              this._qbReconCollection = null;
              this._emsReconCollection = null;
              this._reconciliationMismatches = [];
            }
          })
        )
        .subscribe()
    );

    this._subscriptions.push(
      this.server.refreshToken$
        .pipe(
          map((response) => {
            if (!this.quickBooksCompany && this.quickBooksRealm && response) {
              this.server.getCompany(this.quickBooksRealm);
            }
          })
        )
        .subscribe()
    );

    this._subscriptions.push(
      this.server.company$
        .pipe(
          map((response) => {
            this.quickBooksCompany = response;
          })
        )
        .subscribe()
    );

    this._subscriptions.push(
      this.search$
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          switchMap((search) => {
            if (this.qbObject === 'PurchaseInvoices') {
              return this.server.searchSuppliers(search.SearchField, search.SearchText, this.quickBooksRealm);
            }
            else {
              return this.server.searchClients(search.SearchField, search.SearchText, this.quickBooksRealm);
            }
          })
        )
        .subscribe()
    )

    this._subscriptions.push(
      this.server.validAccounts$
        .pipe(
          map((response) => {
            if (this._exporting && this.selection.selected && response) {
              this.selection.selected.forEach((row: FormGroup) => row.controls["Exists"].setValue(response.includes(row.controls["AccountNo"].value)));
              this.checkSelection();
              this._exporting = false;
            }
            else if (this.dataSource.data && response) {
              this.dataSource.data.forEach((row: FormGroup) => row.controls["Exists"].setValue(response.includes(row.controls["AccountNo"].value)));
            }
          })
        )
        .subscribe()
    );
  }
  
  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
  
  connectToQB() {
    var authUri = environment.serverUrl + 'QuickBooks/Auth';
    
    console.log('The Auth Uris is :'+ authUri);
  
    // Launch Popup using the JS window Object
    var parameters = "location=1,width=800,height=650";
    parameters += ",left=" + (screen.width - 800) / 2 + ",top=" + (screen.height - 650) / 2;
    var win = window.open(authUri, 'connectPopup', parameters);
    var mainWindow = this;
    var pollOAuth = window.setInterval(function () {
      try {
        if (win.document.URL.indexOf("code") != -1) {
          window.clearInterval(pollOAuth);
          var realmId = win.location.href.match(/realmId=([^&]*)/)[1];
          mainWindow.quickBooksRealm = realmId;
          mainWindow.quickBooksCompany = null;
          mainWindow.qbAction = null;
          mainWindow.qbObject = null;
          mainWindow.server.getAuthToken(win.location.href.match(/code=([^&]*)/)[1]);
          win.close();
        }
      } catch (e) {
        console.log(e)
      }
    }, 100);
  }

  clearOptions() {
    this.qbObject = null;
    this.qbReconView = null;
    this.qbReconDB = null;
    this._reconData = [];
    this.haveReconData = false;
    this._qbReconCollection = null;
    this._emsReconCollection = null;
    this._reconciliationMismatches = [];
  }

  getData() {
    if (this.qbAction == null || this.qbObject == null) return;

    var realmId = this.quickBooksRealm;

    if (this.qbAction == "Export") {
      this.server.getData(this.qbObject, realmId);
    }
    else if (this.qbAction == "Reconcile") {
      this.reconSource.data = [];
      this._reconData = [];
      this.reconSpans = [];
      this.qbReconView = null;
      this.qbReconDB = null;
      this.server.reconcileData(this.qbObject, realmId);
    }
  }

  updateAutocompleteNames(row: FormGroup, type: string) {
    this.search$.next({ SearchField: type, SearchText: row.controls[type].value});
  }

  supplierSelected(row: FormGroup, supplier: QuickBooksExportCompany) {
    row.controls['CompanyName'].setValue(supplier.CompanyName);
    row.controls['AccountNo'].setValue(supplier.AccountNo);
  }

  showToExport(row: FormGroup) {
    this.server.getDetailedData(this.qbObject, this.quickBooksRealm, row.controls[this.qbObject === 'PurchaseInvoices' ? "Company" : "AccountNo"].value);
  }

  showMissingVAT(row: FormGroup) {
    this.server.getDocumentsMissingVAT(this.qbObject, this.quickBooksRealm, row.controls["Company"].value);
  }

  doPreExportChecks() {
    this._exporting = true;
    var companyType = this.qbObject === "PurchaseInvoices" ? 2: 1;
    var ids = Array.from(this.selection.selected, (row: FormGroup) => row.controls["AccountNo"].value);
    this.server.getValidAccounts(companyType, this.quickBooksRealm, ids);
  }

  checkSelection() {
    var missing = this.selection.selected.filter(a => a.invalid);

    if (missing.length > 0) {
      alert("Please resolve errors with the selected lines");
    } else {
      this.exportToQuickBooks();
    }
  }

  exportToQuickBooks() {
    var accounts = Array.from(this.selection.selected, (row: FormGroup) => ({
      Company: row.controls["Company"].value,
      CompanyName: row.controls["CompanyName"].value,
      AccountNo: row.controls["AccountNo"].value,
      Currency: row.controls["Currency"].value
    } as QuickBooksExportCompany));
    this.server.exportData(this.qbObject, this.quickBooksRealm, accounts);
  }

  /**
   * Evaluated and store an evaluation of the rowspan for each row.
   * The key determines the column it affects, and the accessor determines the
   * value that should be checked for spanning.
   */
  cacheReconSpan(key, accessor) {
    for (let i = 0; i < this._reconData.length;) {
      let currentValue = accessor(this._reconData[i]);
      let count = 1;

      // Iterate through the remaining rows to see how many match
      // the current value as retrieved through the accessor.
      for (let j = i + 1; j < this._reconData.length; j++) {
        if (currentValue != accessor(this._reconData[j])) {
          break;
        }

        count++;
      }

      if (!this.reconSpans[i]) {
        this.reconSpans[i] = {};
      }

      // Store the number of similar values that were found (the span)
      // and skip i to the next unique row.
      this.reconSpans[i][key] = count;
      i += count;
    }
  }

  getReconRowSpan(col, index) {    
    return this.reconSpans[index] && this.reconSpans[index][col];
  }

  changeReconView() {
    var updateSpans = false;

    if (this.qbReconView == "Mismatches") {
      this._reconData = [];
      this._reconciliationMismatches.forEach(m => {
        if (m.QBObject) {
          this._reconData.push(m.QBObject);
        } else {
          this._reconData.push({ DocumentNumber:m.EMSObject.DocumentNumber, CompanyName: m.EMSObject.CompanyName, AccountNumber: m.EMSObject.AccountNumber, ID: 'QB', Date: (this.qbObject == "Payments" ? m.EMSObject.Date : null), Goods: null, Freight: null, Tax: null, Handling: null, Servicing: null, Total: null, Added: null, AddedBy: null, Currency: null, Exported: null });
        }

        if (m.EMSObject) {
          this._reconData.push(m.EMSObject);
        } else {
          this._reconData.push({ DocumentNumber: m.QBObject.DocumentNumber, CompanyName: m.QBObject.CompanyName, AccountNumber: m.QBObject.AccountNumber, ID: 'EMS', Date: (this.qbObject == "Payments" ? m.QBObject.Date : null), Goods: null, Freight: null, Tax: null, Handling: null, Servicing: null, Total: null, Added: null, AddedBy: null, Currency: null, Exported: null });
        }
      });
      updateSpans = true;
    }

    else {
      var reconObj: QuickBooksReconciliationCollection;

      if (this.qbReconDB == "QB") {
        reconObj = this._qbReconCollection;
      } else if (this.qbReconDB == "COAD") {
        reconObj = this._emsReconCollection;
      } else {
        return;
      }

      if (this.qbReconView == "Duplicates") {
        this._reconData = [];
        reconObj.Duplicates.forEach(d => {
          this._reconData = this._reconData.concat(d.Duplicates);
        });
        updateSpans = true;
      }
      else if (this.qbReconView == "Missing") {
        this._reconData = reconObj.Missing;
        updateSpans = true;
      }
    }

    if (updateSpans) {
      this.reconSpans = [];

      if (this.qbObject == "Payments" || this.qbObject == "Clients") {
        this.reconColumns = ['AccountNumber', 'CompanyName', 'Date', 'Currency', 'ID', 'Total'];
        this.cacheReconSpan('AccountNumber', d => d.AccountNumber);
        this.cacheReconSpan('CompanyName', d => d.AccountNumber + d.CompanyName);
        this.cacheReconSpan('Date', d => d.AccountNumber + d.Date);
        this.cacheReconSpan('Currency', d => d.AccountNumber + d.Date + d.Currency);
        this.cacheReconSpan('Total', d => d.AccountNumber + d.Date + d.Total);
      } else {
        if (this.qbObject == "SalesInvoices") {
          this.reconColumns = ['DocumentNumber', 'Date', 'AccountNumber', 'CompanyName', 'ID', 'Currency', 'Goods', 'Freight', 'Tax', 'Servicing', 'Handling', 'Total', 'Added', 'AddedBy'];
        }
        else {
          this.reconColumns = ['DocumentNumber', 'Date', 'AccountNumber', 'CompanyName', 'ID', 'Currency', 'Goods', 'Freight', 'Tax', 'Total', 'Added', 'AddedBy'];
        }
        
        this.cacheReconSpan('DocumentNumber', d => d.DocumentNumber);
        this.cacheReconSpan('Date', d => d.DocumentNumber + d.Date);
        this.cacheReconSpan('AccountNumber', d => d.DocumentNumber + d.AccountNumber);
        this.cacheReconSpan('CompanyName', d => d.DocumentNumber + d.CompanyName);
        this.cacheReconSpan('Goods', d => d.DocumentNumber + d.Goods);
        this.cacheReconSpan('Freight', d => d.DocumentNumber + d.Freight);
        this.cacheReconSpan('Tax', d => d.DocumentNumber + d.Tax);
        this.cacheReconSpan('Handling', d => d.DocumentNumber + d.Handling);
        this.cacheReconSpan('Servicing', d => d.DocumentNumber + d.Servicing);
        this.cacheReconSpan('Total', d => d.DocumentNumber + d.Total);
        this.cacheReconSpan('Currency', d => d.DocumentNumber + d.Currency);
      }

        this.reconSource.data = this._reconData;
    }
  }
}

export interface SupplierSearch {
  SearchField: string,
  SearchText: string
}
