<h1 mat-dialog-title>Are you sure?</h1>
<div mat-dialog-content>
  <p>
    {{data.text}}
  </p>
</div>
<mat-dialog-actions>
  <button mat-button mat-dialog-close tabindex="-1">No</button>
  <button mat-button [mat-dialog-close]="true" tabindex="1">Yes</button>
</mat-dialog-actions>
