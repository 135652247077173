<h1 mat-dialog-title>Are you sure?</h1>
<div>
  <p>
    Are you sure you want to associate the user {{data.user.FirstName}} {{data.user.LastName}} with the company "{{data.company.Name}}"?
  </p>
  <mat-checkbox [(ngModel)]="shouldBeAdmin">
    Contact is an admin at the company
  </mat-checkbox>
</div>
<mat-dialog-actions>
  <button mat-button matDialogClose tabindex="-1">No</button>
  <button mat-button [matDialogClose]="{'shouldBeAdmin': shouldBeAdmin}" tabindex="1">Yes</button>
</mat-dialog-actions>
