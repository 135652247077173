import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { ShopDepartment, ShopDepartmentBase } from '@equipmyschool/emsadminweb-models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-project-tree-dialog',
  templateUrl: './project-tree-dialog.component.html',
  styleUrls: ['./project-tree-dialog.component.scss']
})
export class ProjectTreeDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ProjectTreeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputData
  ) { }

  ngOnInit() {
  }

  selectedNodeChangeProxy(event) {
    this.dialogRef.close(event);
  }
}

export interface InputData {
  selectedNode: ShopDepartmentBase;
}
