<div *ngIf="!quickBooksRealm" fxLayoutAlign="center center">
  <button mat-raised-button color="primary" (click)="connectToQB()">
    Connect to QuickBooks
  </button>
</div>
<div *ngIf="quickBooksRealm">
  <div *ngIf="!quickBooksCompany">
    Checking Purchase Invoices, please wait
  </div>
  <div *ngIf="quickBooksCompany">
    Viewing data to export for {{quickBooksCompany.CompanyName}}
    <button mat-raised-button color="primary" (click)="connectToQB()">
      Switch Company
    </button>
  </div>
  <div *ngIf="quickBooksCompany">
    <mat-radio-group class="export-radio-group" [(ngModel)]="qbAction" (change)="clearOptions()">
      <mat-radio-button class="export-radio-button" value="Export">
        Export
      </mat-radio-button>
      <mat-radio-button class="export-radio-button" value="Reconcile">
        Reconcile
      </mat-radio-button>
    </mat-radio-group>
    <mat-radio-group class="export-radio-group" [(ngModel)]="qbObject" (change)="getData()">
      <mat-radio-button class="export-radio-button" value="Payments">
        Payments
      </mat-radio-button>
      <mat-radio-button class="export-radio-button" value="PurchaseInvoices">
        Purchase Invoices
      </mat-radio-button>
      <mat-radio-button class="export-radio-button" value="SalesInvoices">
        Sales Invoices
      </mat-radio-button>
      <mat-radio-button class="export-radio-button" value="Clients" *ngIf="qbAction == 'Reconcile'">
        Clients
      </mat-radio-button>
    </mat-radio-group>
      
    <mat-radio-group *ngIf="qbAction == 'Reconcile' && haveReconData" class="export-radio-group" [(ngModel)]="qbReconView" (change)="changeReconView()">
      <mat-radio-button class="export-radio-button" value="Duplicates">
        Duplicates
      </mat-radio-button>
      <mat-radio-button class="export-radio-button" value="Missing">
        Missing
      </mat-radio-button>
      <mat-radio-button class="export-radio-button" value="Mismatches">
        Mismatches
      </mat-radio-button>
    </mat-radio-group>
    <mat-radio-group *ngIf="qbAction == 'Reconcile' && haveReconData && qbReconView && qbReconView !== 'Mismatches'" class="export-radio-group" [(ngModel)]="qbReconDB" (change)="changeReconView()">
      <mat-radio-button class="export-radio-button" value="QB">
        QuickBooks
      </mat-radio-button>
      <mat-radio-button class="export-radio-button" value="COAD">
        COAD
      </mat-radio-button>
    </mat-radio-group>

    <button *ngIf="qbAction == 'Export' && qbObject" mat-raised-button color="primary" (click)="getData()">
      Refresh
    </button>
  </div>

  <div *ngIf="qbAction == 'Export'">
    <form [formGroup]="form">
      <mat-table [dataSource]="dataSource" matSort formArrayName="formArray">
        <ng-container cdkColumnDef="Select">
          <mat-header-cell *cdkHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"></mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Exists">
          <mat-header-cell *cdkHeaderCellDef>On Quickbooks?</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <mat-checkbox (click)="$event.preventDefault()" [checked]="row.controls['Exists'].value"></mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="CompanyName">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Company Name</mat-header-cell>
          <mat-cell *cdkCellDef="let row;" [formGroupName]="dataSource.data.indexOf(row)">
            <ng-container *ngIf="row.controls['Exists'].value == null || row.controls['Exists'].value">
              <div>
                {{row.controls['CompanyName'].value}}
              </div>
              <div *ngIf="row.controls['Errors']?.value">
                <mat-error>
                  <div *ngFor="let error of row.controls['Errors']?.value?.Errors">{{error}}</div>
                  <div *ngIf="row.controls['Errors']?.value?.Documents?.length">Some documents failed to export</div>
                </mat-error>
              </div>
            </ng-container>
            <ng-container *ngIf="row.controls['Exists'].value != null && !row.controls['Exists'].value">
              <mat-form-field>
                <input matInput formControlName="CompanyName" [matAutocomplete]="autoName" (input)="updateAutocompleteNames(row, 'CompanyName')" (focus)="updateAutocompleteNames(row, 'CompanyName')" />
                <mat-autocomplete #autoName="matAutocomplete" (optionSelected)="supplierSelected(row, $event.option.value)">
                  <mat-option *ngFor="let option of nameOptions$ | async" [value]="option">
                    {{option.CompanyName}} ({{option.AccountNo}}) - {{option.Currency}}
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="row.controls['Errors']?.value || row.controls['CompanyName']?.invalid">
                  <div *ngIf="row.controls['CompanyName'].errors.required">Enter a company name</div>
                  <div *ngIf="row.controls['CompanyName'].errors.maxlength">Name cannot be more than 300 characters</div>
                  <div *ngFor="let error of row.controls['Errors']?.value?.Errors">{{error}}</div>
                  <div *ngIf="row.controls['Errors']?.value?.Documents?.length">Some documents failed to export</div>
                </mat-error>
              </mat-form-field>
            </ng-container>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="AccountNo">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Account No</mat-header-cell>
          <mat-cell *cdkCellDef="let row;" [formGroupName]="dataSource.data.indexOf(row)">
            <ng-container *ngIf="qbObject !== 'PurchaseInvoices' || row.controls['Exists'].value == null || row.controls['Exists'].value">
              {{row.controls['AccountNo'].value}}
            </ng-container>
            <ng-container *ngIf="qbObject === 'PurchaseInvoices' && !(row.controls['Exists'].value == null || row.controls['Exists'].value)">
              <mat-form-field>
                <input matInput formControlName="AccountNo" [matAutocomplete]="autoAccNo" (input)="updateAutocompleteNames(row, 'AccountNo')" (focus)="updateAutocompleteNames(row, 'AccountNo')" />
                <mat-autocomplete #autoAccNo="matAutocomplete" panelWidth="auto" (optionSelected)="supplierSelected(row, $event.option.value)">
                  <mat-option *ngFor="let option of nameOptions$ | async" [value]="option">
                    {{option.CompanyName}} ({{option.AccountNo}}) - {{option.Currency}}
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="row.controls['AccountNo']?.invalid">
                  <div *ngIf="row.controls['AccountNo'].errors.required">Enter an account reference</div>
                  <div *ngIf="row.controls['AccountNo'].errors.maxlength">Reference too long</div>
                </mat-error>
              </mat-form-field>
            </ng-container>
          </mat-cell>
        </ng-container>
        
        <ng-container cdkColumnDef="Currency">
          <mat-header-cell *cdkHeaderCellDef>Currency</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            {{row.value['Currency']}}
          </mat-cell>
        </ng-container>
        
        <ng-container cdkColumnDef="ToExport">
          <mat-header-cell *cdkHeaderCellDef>To Export</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <a mat-button [color]="row.value['Errors']?.Documents && row.value['Errors'].Documents.length > 0 ? 'warn' : ''" (click)="showToExport(row)">{{row.value['ToExport']}}</a>
          </mat-cell>
        </ng-container>
        
        <ng-container cdkColumnDef="MissingVAT">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Missing VAT</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <a mat-button *ngIf="row.value['MissingVAT'] > 0" color='warn' (click)="showMissingVAT(row)">{{row.value['MissingVAT']}}</a>
            <span *ngIf="row.value['MissingVAT'] === 0">0</span>
          </mat-cell>
        </ng-container>
        
        <ng-container cdkColumnDef="Value">
          <mat-header-cell *cdkHeaderCellDef>Value</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            {{row.value['Value'] | currency: row.value['Currency']}}
          </mat-cell>
        </ng-container>
        
        <ng-container cdkColumnDef="Oldest">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Date of Oldest Item</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            {{row.value['Oldest'] | date: 'd MMM yyyy'}}
          </mat-cell>
        </ng-container>

        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns;"          
          [ngClass]="{
            'selected-ok': selection.isSelected(row) && (row.controls['Exists'].value == null || row.controls['Exists'].value) && (qbObject !== 'PurchaseInvoices' || row.value['MissingVAT'] == 0),
            'selected-bad': selection.isSelected(row) && (!row.controls['CompanyName']?.value?.length || row.controls['CompanyName']?.invalid || !row.controls['AccountNo']?.value?.length || row.controls['AccountNo']?.invalid || qbObject === 'PurchaseInvoices' && row.value['MissingVAT'] > 0),
            'selected-new': selection.isSelected(row) && row.controls['Exists'].value != null && !row.controls['Exists'].value && row.controls['CompanyName']?.value?.length && !row.controls['CompanyName']?.invalid && row.controls['AccountNo']?.value?.length && !row.controls['AccountNo']?.invalid
          }"
        ></mat-row>
      </mat-table>
    </form>

    <button *ngIf="selection.selected.length > 0" mat-raised-button color="primary" (click)="doPreExportChecks()">
      Export to QuickBooks
    </button>
  </div>

  <div *ngIf="qbAction == 'Reconcile' && haveReconData">
    <table mat-table [dataSource]="reconSource">
      <ng-container cdkColumnDef="DocumentNumber">
        <th mat-header-cell *cdkHeaderCellDef>Document Number</th>
        <td mat-cell *cdkCellDef="let row; let i = index" [attr.rowspan]="getReconRowSpan('DocumentNumber', i)" [style.display]="getReconRowSpan('DocumentNumber', i) ? '' : 'none'">
          {{row.DocumentNumber}}
        </td>
      </ng-container>

      <ng-container cdkColumnDef="Date">
        <th mat-header-cell *cdkHeaderCellDef>Date</th>
        <td mat-cell *cdkCellDef="let row; let i = index" [attr.rowspan]="getReconRowSpan('Date', i)" [style.display]="getReconRowSpan('Date', i) ? '' : 'none'">
          {{row.Date | date: 'd MMM yyyy'}}
        </td>
      </ng-container>
      
      <ng-container cdkColumnDef="CompanyName">
        <th mat-header-cell *cdkHeaderCellDef>Company Name</th>
        <td mat-cell *cdkCellDef="let row; let i = index" [attr.rowspan]="getReconRowSpan('CompanyName', i)" [style.display]="getReconRowSpan('CompanyName', i) ? '' : 'none'">
          {{row.CompanyName}}
        </td>
      </ng-container>
      
      <ng-container cdkColumnDef="AccountNumber">
        <th mat-header-cell *cdkHeaderCellDef>Account Number</th>
        <td mat-cell *cdkCellDef="let row; let i = index" [attr.rowspan]="getReconRowSpan('AccountNumber', i)" [style.display]="getReconRowSpan('AccountNumber', i) ? '' : 'none'">
          {{row.AccountNumber}}
        </td>
      </ng-container>
      
      <ng-container cdkColumnDef="ID">
        <th mat-header-cell *cdkHeaderCellDef>ID</th>
        <td mat-cell *cdkCellDef="let row">
          {{row.ID}}
        </td>
      </ng-container>
      
      <ng-container cdkColumnDef="Goods">
        <th mat-header-cell *cdkHeaderCellDef>Goods</th>
        <td mat-cell *cdkCellDef="let row; let i = index" [attr.rowspan]="getReconRowSpan('Goods', i)" [style.display]="getReconRowSpan('Goods', i) ? '' : 'none'">
          {{row.Goods | currency: row.Currency?.Code}}
        </td>
      </ng-container>
      
      <ng-container cdkColumnDef="Freight">
        <th mat-header-cell *cdkHeaderCellDef>Freight</th>
        <td mat-cell *cdkCellDef="let row; let i = index" [attr.rowspan]="getReconRowSpan('Freight', i)" [style.display]="getReconRowSpan('Freight', i) ? '' : 'none'">
          {{row.Freight | currency: row.Currency?.Code}}
        </td>
      </ng-container>
      
      <ng-container cdkColumnDef="Tax">
        <th mat-header-cell *cdkHeaderCellDef>Tax</th>
        <td mat-cell *cdkCellDef="let row; let i = index" [attr.rowspan]="getReconRowSpan('Tax', i)" [style.display]="getReconRowSpan('Tax', i) ? '' : 'none'">
          {{row.Tax | currency: row.Currency?.Code}}
        </td>
      </ng-container>
      
      <ng-container cdkColumnDef="Handling">
        <th mat-header-cell *cdkHeaderCellDef>Handling</th>
        <td mat-cell *cdkCellDef="let row; let i = index" [attr.rowspan]="getReconRowSpan('Handling', i)" [style.display]="getReconRowSpan('Handling', i) ? '' : 'none'">
          {{row.Handling | currency: row.Currency?.Code}}
        </td>
      </ng-container>
      
      <ng-container cdkColumnDef="Servicing">
        <th mat-header-cell *cdkHeaderCellDef>Servicing</th>
        <td mat-cell *cdkCellDef="let row; let i = index" [attr.rowspan]="getReconRowSpan('Servicing', i)" [style.display]="getReconRowSpan('Servicing', i) ? '' : 'none'">
          {{row.Servicing | currency: row.Currency?.Code}}
        </td>
      </ng-container>
      
      <ng-container cdkColumnDef="Total">
        <th mat-header-cell *cdkHeaderCellDef>Total</th>
        <td mat-cell *cdkCellDef="let row; let i = index" [attr.rowspan]="getReconRowSpan('Total', i)" [style.display]="getReconRowSpan('Total', i) ? '' : 'none'">
          {{row.Total | currency: row.Currency?.Code}}
        </td>
      </ng-container>
      
      <ng-container cdkColumnDef="Added">
        <th mat-header-cell *cdkHeaderCellDef>Added</th>
        <td mat-cell *cdkCellDef="let row">
          {{row.Added | date: 'd MMM yyyy'}}
        </td>
      </ng-container>
      
      <ng-container cdkColumnDef="AddedBy">
        <th mat-header-cell *cdkHeaderCellDef>Added By</th>
        <td mat-cell *cdkCellDef="let row">
          {{row.AddedBy}}
        </td>
      </ng-container>
      
      <ng-container cdkColumnDef="Currency">
        <th mat-header-cell *cdkHeaderCellDef>Currency</th>
        <td mat-cell *cdkCellDef="let row; let i = index" [attr.rowspan]="getReconRowSpan('Currency', i)" [style.display]="getReconRowSpan('Currency', i) ? '' : 'none'">
          {{row.Currency?.Code}}
        </td>
      </ng-container>

      <ng-container cdkColumnDef="Exported">
        <th mat-header-cell *cdkHeaderCellDef>Exported</th>
        <td mat-cell *cdkCellDef="let row">
          {{row.Exported | date: 'd MMM yyyy'}}
        </td>
      </ng-container>

      <tr mat-header-row *cdkHeaderRowDef="reconColumns"></tr>
      <tr mat-row *cdkRowDef="let row; let i = index; columns: reconColumns;"></tr>
    </table>
  </div>
</div>
