import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ServerBaseService } from '../server-base.service';
import { Company, NetUser, ExtendedContact, AdminForDetails, UserType } from '@equipmyschool/emsadminweb-models';
import { ADMIN_API_SERVER_URL } from '../tokens/admin-api-server-url.token';

@Injectable()
export class AspNetUserService extends ServerBaseService {
  constructor(http: HttpClient, @Inject(ADMIN_API_SERVER_URL) serverUrl: string) {
    super(http, serverUrl);
  }

  public getUser(aspNetUserId: string) {
    return this.request<NetUser>(
      'GET',
      'AspNetUser/Get',
      { id: aspNetUserId }
    );
  }

  public getUsers(query: string) {
    return this.request<NetUser[]>(
      'GET',
      'AspNetUser/GetUsers',
      { query }
    );
  }

  public getContacts(query: string) {
    return this.request<ExtendedContact[]>(
      'GET',
      'AspNetUser/GetContacts',
      { query }
    );
  }

  public toggleApproved(aspNetUserId: string) {
    return this.request(
      'POST',
      'AspNetUser/ToggleApproved',
      { aspNetUserId }
    );
  }

  public unlock(aspNetUserId: string) {
    return this.request(
      'POST',
      'AspNetUser/Unlock',
      { aspNetUserId }
    );
  }

  /**
   * Emulates the clicking of the link on the confirmation email for the user with the given ID
   * @param AspNetUserID The ASP.NET UserID of the user
   */
  public SetVerified(AspNetUserID: string) {
    return this.request(
      'POST',
      'AspNetUser/SetVerified',
      { AspNetUserID }
    );
  }

  /**
   * Toggles whether the user with the given ID is prompted to reset their password after a successful login.
   * @param AspNetUserID The ASP.NET UserID of the user
   */
  public TogglePasswordReset(AspNetUserID: string) {
    return this.request(
      'POST',
      'AspNetUser/TogglePasswordReset',
      { AspNetUserID }
    );
  }

  public setType(aspNetUserId: string, type: UserType) {
    return this.request(
      'POST',
      'AspNetUser/SetType',
      { aspNetUserId, type }
    );
  }

  public delete(aspNetUserId: string) {
    return this.request(
      'POST',
      'AspNetUser/Delete',
      { aspNetUserId }
    );
  }

  /**
   * Resets the password of the user with the given ID
   * @param aspNetUserId The ASP.NET UserID of the user
   * @returns An observable containing the user's new password
   */
  public resetPassword(aspNetUserId: string) {
    return this.request<string>(
      'POST',
      'AspNetUser/ResetPassword',
      { aspNetUserId }
    );
  }

  public getCompanies(query: string) {
    return this.request<Company[]>(
      'GET',
      'AspNetUser/GetCompanies',
      { query }
    );
  }

  public createCompany(aspNetUserId: string, contactId: number) {
    return this.request<number>(
      'POST',
      'AspNetUser/CreateCompany',
      { AspNetUserID: aspNetUserId, UserID: contactId }
    );
  }

  /**
  * Creates a contact based on the details in the ASP.NET User's profile.
  * @param AspNetUserID The ASP.NET UserID of the user that needs a contact creating.
  * @returns The ContactID of the newly created contact.
  */
  public CreateContact(AspNetUserID: string) {
    return this.request<number>(
      'POST',
      'AspNetUser/CreateContact',
      { AspNetUserID }
    );
  }

  /**
   * Creates a company based on the details in the ASP.NET User's profile
   * @param AspNetUserID The ASP.NET UserID of the contact that needs a company creating
   * @param ContactID The ContactID of the contact that needs a company creating
   * @returns The CompanyID of the newly created company
   */
  public CreateCompany(AspNetUserID: string, ContactID: number) {
    return this.request<number>(
      'POST',
      'AspNetUser/CreateCompany',
      { AspNetUserID, ContactID }
    );
  }

  /**
   * Associates an asp.net user to a contact.
   * @param aspNetUserId The asp.net user id, which corresponds to NetUser.aspNetUserID field.
   * @param contactId The contact Id, which corresponds to NetUser.UserID field.
   */
  public associateUserToContact(aspNetUserId: string, contactId: number) {
    return this.request(
      'POST',
      'AspNetUser/AssociateUserToContact',
      { AspNetUserId: aspNetUserId, UserID: contactId }
    );
  }

  public associateContactToCompany(contactId: number, companyId: number, isAdmin: boolean) {
    return this.request(
      'POST',
      'AspNetUser/AssociateContactToCompany',
      { UserID: contactId, CompanyID: companyId, IsAdmin: isAdmin }
    );
  }

  public generateAdminForDetails(userId: number) {
    return this.request<AdminForDetails>(
      'POST',
      'AspNetUser/GenerateAdminForDetails',
      { userId }
    );
  }
}
