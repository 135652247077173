import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ServerBaseService } from '../server-base.service';
import {
  CrawlerStatusPage,
  CrawlerStatusProduct,
  CrawlerStatusSupplier,
  CrawlerStatusEventLogItem,
  ShopDepartment
} from '@equipmyschool/emsadminweb-models';
import { ADMIN_API_SERVER_URL } from '../tokens/admin-api-server-url.token';

@Injectable()
export class CrawlerStatusService extends ServerBaseService {
  constructor(http: HttpClient, @Inject(ADMIN_API_SERVER_URL) serverUrl: string) {
    super(http, serverUrl);
  }

  public getSuppliers() {
    return this.request<CrawlerStatusSupplier[]>('GET', 'CrawlerStatus/GetSuppliers');
  }

  public getCategoryPages() {
    return this.request<CrawlerStatusPage[]>('GET', 'CrawlerStatus/GetCategoryPages');
  }

  public getProductPages() {
    return this.request<CrawlerStatusPage[]>('GET', 'CrawlerStatus/GetProductPages');
  }

  public getProducts() {
    return this.request<CrawlerStatusProduct[]>('GET', 'CrawlerStatus/GetProducts');
  }

  public getShopDepartments() {
    return this.request<ShopDepartment>('GET', 'CrawlerStatus/GetShopDepartments');
  }

  /**
   * Sets the root shop department for the supplier
   * @param data
   * SupplierID: The SupplierID for the supplier whose root shop department will be set
   * DepartmentID: The DepartmentID for the shop department to be set as the root
   */
  public setRootDepartment(data: { SupplierID: number, DepartmentID: number }) {
    return this.request('POST', 'CrawlerStatus/SetRootDepartment', data);
  }

  public getEventLog = () => {
    return this.request<CrawlerStatusEventLogItem[]>('GET', 'CrawlerStatus/GetEventLog');
  }

  public togglePluginEnabled(supplierId: number) {
    return this.request('POST', 'CrawlerStatus/TogglePluginEnabled', { supplierId });
  }

  public toggleShowProducts(supplierId: number) {
    return this.request('POST', 'CrawlerStatus/ToggleShowProducts', { supplierId });
  }

  public toggleUseCrawledPrices(supplierId: number) {
    return this.request('POST', 'CrawlerStatus/ToggleUseCrawledPrices', { supplierId });
  }

  // #region Files
  public getCategoryPageErrors(supplierId: number) {
    return this.requestFile('GET', 'CrawlerStatus/GetCategoryPageErrors', { supplierId });
  }

  public getProductPageErrors(supplierId: number) {
    return this.requestFile('GET', 'CrawlerStatus/GetProductPageErrors', { supplierId });
  }

  public getProductsMissingPricing(supplierId: number) {
    return this.requestFile('GET', 'CrawlerStatus/GetProductsMissingPricing', { supplierId });
  }

  public getProductsNotMatched(supplierId: number) {
    return this.requestFile('GET', 'CrawlerStatus/GetProductsNotMatched', { supplierId });
  }

  public getProductsMissingFromWebsite(supplierId: number) {
    return this.requestFile('GET', 'CrawlerStatus/GetProductsMissingFromWebsite', { supplierId });
  }
  // #endregion
}
