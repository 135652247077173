import {Pipe, PipeTransform} from '@angular/core';
import { FreightAgent } from 'coadapi';

@Pipe({ name: 'agentSearch' })
export class AgentFilterPipe implements PipeTransform {
  public transform(agents: FreightAgent[], searchText: any): any {
    if (searchText == null || agents == null) {
      return agents;
    }
    return agents.filter(agent => agent.companyName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
  }
}