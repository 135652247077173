import { Component, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-save-dialog',
  templateUrl: './save-dialog.component.html',
  styleUrls: ['./save-dialog.component.scss']
})
export class SaveDialogComponent implements AfterViewInit {
  @ViewChild('grandTotal') grandTotal: ElementRef;
  value = 0;

  constructor(
    public dialogRef: MatDialogRef<SaveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputData
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.grandTotal.nativeElement.select();
    }, 100);
  }

}

export interface InputData {
  currencyCode: string;
  validator: ValidatorFn;
  documentTypeReadable: string;
}
