<div *ngIf="project" style="font-size: 0.75em;">
    <div>
      <h3 class="EMSPageSubHeader">Client Details</h3>
      <table>
        <tr>
          <td>Account No:</td>
          <td>{{project.Customer.AccountNo}}</td>
        </tr>
        <tr>
          <td>Name:</td>
          <td>{{project.Customer.Name}}</td>
        </tr>
        <tr>
          <td>Country:</td>
          <td><app-country-icon [country]="project.Customer.Country"></app-country-icon></td>
        </tr>
      </table>
    </div>
    <hr />
    <div>
      <h3 class="EMSPageSubHeader">Budget Details</h3>
      <table>
        <tr>
          <td>Name:</td>
          <td>{{project.BudgetName}}</td>
        </tr>
        <tr>
          <td>Currency:</td>
          <td>{{project.CurrencyCode}}</td>
        </tr>
        <tr>
          <td>Value:</td>
          <td>{{project.BudgetAmount}}</td>
        </tr>
      </table>
    </div>
    <hr />
    <div>
      <h3 class="EMSPageSubHeader">Creator Details</h3>
      <table>
        <tr>
          <td>Name:</td>
          <td>{{project.Owner.FirstName}} {{project.Owner.LastName}}</td>
        </tr>
        <tr>
          <td>Email:</td>
          <td>{{project.Owner.Email}}</td>
        </tr>
      </table>
    </div>
    <hr />
    <div>
      <table>
        <tr>
          <th style="vertical-align: top;">Deliver To:</th>
          <td [innerText]="project.DeliveryAddress"></td>
        </tr>
        <tr>
          <th>Required Arrival Date:</th>
          <td>{{project.RequiredArrivalDate | date: "d MMM yyyy"}}</td>
        </tr>
        <tr>
          <th>Required Closure Date:</th>
          <td>{{project.RequiredClosureDate | date: "d MMM yyyy"}}</td>
        </tr>
        <tr>
          <th><label>New Required Closure Date:</label></th>
          <td>
            <mat-form-field class="closure-form-field" appearance="fill">
              <mat-label>Choose a date</mat-label>
              <input matInput [min]="minDate" [(ngModel)]="newDate" [matDatepicker]="picker">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </td>
        </tr>
      </table>
      <input type="button" value="Set Closure Date" (click)="setClosureDate()" />
    </div>
  </div>
