import { Directive, Provider, OnChanges, Input, SimpleChanges, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl, ValidationErrors, Validators } from '@angular/forms';

// Source: https://github.com/angular/angular/commit/81925fa66d2c930f68d3b6dd084c954ea1bab6b6#diff-5b182f7023604dff820b5c3bd44bfcdb

export const MAX_VALIDATOR: Provider = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => MaxValidatorDirective),
  multi: true
};

/**
 * A directive which installs the {@link MaxValidator} for any `formControlName`,
 * `formControl`, or control with `ngModel` that also has a `max` attribute.
 *
 * @experimental
 */
@Directive({
    // tslint:disable-next-line:directive-selector
  selector: 'input[type=number][max][formControlName],input[type=number][max][formControl],input[type=number][max][ngModel]',
  providers: [MAX_VALIDATOR],
  host: { '[attr.max]': 'max ? max : null' }
})
export class MaxValidatorDirective implements Validator, OnChanges {
  private _validator: ValidatorFn;
  private _onChange: () => void;

  @Input('max') max: number;

  ngOnChanges(changes: SimpleChanges): void {
    if ('max' in changes) {
      this._createValidator();
      if (this._onChange) {
        this._onChange();
      }
    }
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this._validator(c);
  }

  registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }

  private _createValidator(): void {
    this._validator = Validators.max(this.max);
  }
}
