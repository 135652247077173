import { Component, Input } from '@angular/core';
import * as Models from '@equipmyschool/emsadminweb-models';
import * as Enums from 'app/server/server-enums';
import { iconMappings } from 'app/shared/icon-mappings';

@Component({
  selector: 'app-company-type-icon',
  templateUrl: './company-type-icon.component.html',
  styleUrls: ['./company-type-icon.component.scss']
})
export class CompanyTypeIconComponent {
  iconMappings = iconMappings;
  @Input() companyType: Models.CompanyType;
  Enums = Enums;
}
