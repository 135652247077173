<h1 mat-dialog-title>Line split</h1>
<div mat-dialog-content>
  <p>Please enter the new quantity for the line.</p>

  <p class="mat-caption">Current quantity: {{data.currentQuantity}}</p>

  <form novalidate>
    <mat-form-field>
      <input matInput type="number" placeholder="New quantity" #qtyInput
        [(ngModel)]="newQuantity" true [min]=min [max]=max
        #newQuantityCtrl="ngModel" name="newQuantity" class="right" (click)="$event.target">
      <mat-error *ngIf="newQuantityCtrl.invalid">
        Please enter a value within a valid range ({{min}} - {{max}})
      </mat-error>
      <mat-hint>Enter a value between {{min}} and {{max}}</mat-hint>
    </mat-form-field>
  </form>

  <p class="mat-caption">The rest
    <span *ngIf="newQuantityCtrl.valid">({{data.currentQuantity - newQuantity}})</span>
    will be extracted to a new order line.</p>

</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close tabindex="-1">Cancel</button>
  <button mat-button [mat-dialog-close]="newQuantity" tabindex="1"
    [disabled]="newQuantityCtrl.invalid">OK</button>
</mat-dialog-actions>
