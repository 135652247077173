import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Status } from '@equipmyschool/emsadminweb-models';
import { Subscription } from 'rxjs';
// Services
import { OrderLineService } from '../../server/services/order-line/order-line.service';

@Component({
  selector: 'app-set-eta',
  templateUrl: './set-eta.component.html',
  styleUrls: ['./set-eta.component.scss']
})
export class SetEtaComponent implements OnInit, OnDestroy {
  selectedReason: Status;
  Date: any;
  arrayReason: Status[];
  subscriptReasons: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public data: InputData,
              private orderLineService: OrderLineService,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.subscriptReasons = this.orderLineService.Reasons$.subscribe(
      reasons => {
      this.arrayReason = reasons;
    }, err => console.log(err));
  }

  public setETA(lineID) {
    const data = {
      lineID: lineID,
      ETA : this.Date._d.toISOString(),
      reason: this.selectedReason
    };
    this.data.changeColorDirective.color = 'yellow';
    this.orderLineService.setETA(data)
      .subscribe(
        resp => {
          console.log(resp);
          this.data.changeColorDirective.color = 'green';
        },
        err => {
          console.log(err);
          const errMessageETA = err.error.ModelState.ETA ? err.error.ModelState.ETA[0] : '';
          const errMessageReason = err.error.ModelState.Reason ? err.error.ModelState.Reason[0] : '';
          const errMessage = errMessageETA + errMessageReason;
          this.data.changeColorDirective.color = 'red';
          this.snackBar.open(err.error.Message, errMessage, {
            duration: 5000,
          });
        }
      );
  }

  ngOnDestroy(): void {
    this.subscriptReasons.unsubscribe();
  }
}

export interface InputData {
  lineID: number;
  description: string;
  changeColorDirective: any;
}
