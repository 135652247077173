import { finalize } from 'rxjs/operators';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpResponse,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as moment from 'moment';

import { AsyncIndicatorService } from 'app/shared/async-indicator.service';

@Injectable()
export class ProgressInterceptor implements HttpInterceptor {
  constructor(private asyncIndicatorService: AsyncIndicatorService) {

  }

  public intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    this.asyncIndicatorService.requestStarted(req.urlWithParams);

    return next.handle(req).pipe(finalize(() => {
      this.asyncIndicatorService.requestFinished(req.urlWithParams);
    }));
  }
}
