<h1 mat-dialog-title>Details saved successfully</h1>
<div mat-dialog-content>
  <p>Copy the following text and use it to re-name the digital copy of the document.</p>

  <mat-form-field>
    <input matInput [ngModel]="data.saveDetails.SaveName" [readonly]=true>
    <button mat-icon-button matTooltip="Copy to Clipboard" matSuffix [ngxClipboard] [cbContent]="data.saveDetails.SaveName">
      <mat-icon>content_copy</mat-icon>
    </button>
  </mat-form-field>

  <p>Then save the re-named version in the following location in the company file repository.</p>

  {{data.saveDetails.SavePath}}
</div>
<mat-dialog-actions>
  <button mat-raised-button color="primary" mat-dialog-close tabindex="1">I have renamed and saved the file</button>
</mat-dialog-actions>
