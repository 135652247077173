<h2>Associate the contact with a company</h2>

<app-user-card *ngIf="user" [user]="user"></app-user-card>

<div class="search-header">
  <mat-form-field floatPlaceholder="never">
    <input matInput placeholder="Filter companies" [(ngModel)]="filter" (ngModelChange)="updateFilter(false)" (keyup.enter)="updateFilter(true)">
  </mat-form-field>
</div>

<button mat-fab type="button" color="primary" matTooltip="Create new company for the user" (click)="createCompany()">
  <mat-icon>add</mat-icon>
</button>

<mat-nav-list>
  <a mat-list-item *ngFor="let company of companies" (click)="companySelected(company)">
    <div fxLayout fxLayoutAlign="space-between center" style="width: 100%;">
      <div fxLayout fxLayoutAlign="center center">
        <ng-container [ngSwitch]="company.Type">
          <mat-icon matListIcon *ngSwitchCase="CompanyType.Customer" matTooltip="Customer"> credit_card </mat-icon>
          <mat-icon matListIcon *ngSwitchCase="CompanyType.Supplier" matTooltip="Supplier"> local_shipping </mat-icon>
        </ng-container>
        <h3>{{ company.Name }}</h3>
      </div>
      <div style="position: absolute; right: 200px; text-align: right; display: flex;">
        <p>{{ company.Country?.Name }}</p>
        <app-country-icon [country]="company.Country" style="margin-left: 5px"></app-country-icon>
      </div>
      <div fxFlexAlign="flex-end" style="text-align: right;">
        <p>ID: {{ company.CompanyID }}</p>
        <ng-container *ngIf="company.AccountNo">Account No: {{ company.AccountNo }}</ng-container>
      </div>
    </div>
  </a>
</mat-nav-list>
