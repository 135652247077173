import { Injectable } from '@angular/core';
import { EndpointDataProvider } from 'app/endpoint-data-provider/endpoint-data-provider';
import { PurchaseInvoiceService } from 'app/server/server.module';

@Injectable()
export class PurchaseInvoiceProvider {
  constructor(private server: PurchaseInvoiceService) { }

  suppliers = new EndpointDataProvider(this.server.getSuppliers);
  purchaseOrders = new EndpointDataProvider(this.server.getPurchaseOrders);
  lines = new EndpointDataProvider(this.server.getLines);
  get purchaseOrdersByProductCode() {
    return this.server.getPurchaseOrdersByProductCode; // Not sure if cache needed at all
  }
  get post() {
    return this.server.post;
  }
  get checkInvoiceNumber() {
    return this.server.checkInvoiceNumber;
  }

  get splitLine() {
    return this.server.splitLine; // Should invalidate lines
  }
}
