import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ApiModule, BASE_PATH } from 'coadapi';

import { AppComponent } from './app.component';
import { RoutingModule } from './app-routing.module';
import { LoginComponent } from './login/login.component';
import { WelcomeComponent } from './welcome/welcome.component';

import { MainComponent } from './main/main.component';
import { ServerModule } from 'app/server/server.module';
import { SharedModule } from 'app/shared/shared.module';
import { EppUsersModule } from 'app/epp-users/epp-users.module';
import { CrawlerStatusModule } from 'app/crawler-status/crawler-status.module';
import { ADMIN_API_SERVER_URL } from 'app/server/server.module';
import { EMS_API_SERVER_URL } from 'app/server/server.module';
import { PurchaseInvoiceModule } from 'app/purchase-invoice/purchase-invoice.module';
import { ExportToQuickbooksModule } from 'app/export-to-quickbooks/export-to-quickbooks.module';

import { environment } from 'environments/environment';
import { MaterialModule } from 'app/material.module';
import { EmailActionComponent } from 'app/email-action/email-action.component';
import { ProformaToDispatchComponent  } from './coad-web/proforma-to-dispatch/proforma-to-dispatch.component';
import { DispatchDetailsComponent } from './coad-web/dispatch-details/dispatch-details.component'
import { ChasingBoardComponent } from './coad-web/chasing-board/chasing-board.component'
import { ChaseNotesComponent } from './coad-web/chasing-board/pages/dialogs/chase-notes/chase-notes.component'
import { StaffListComponent } from './coad-web/chasing-board/pages/dialogs/staff-list/staff-list.component'
import { StaffFilterPipe } from './coad-web/chasing-board/abstracts/_staff-filter.pipe'
import { AssignmentsWarehouseFilterPipe, SuppliersWarehouseFilterPipe, DispatchesWarehouseFilterPipe } from './coad-web/chasing-board/abstracts/_snapshot-warehouse-filters'
import { CommentActionService } from './coad-web/chasing-board/abstracts/_data-sharing'
import { NotesDialogComponent } from './coad-web/dispatch-details/pages/dialogs/notes-dialog/notes-dialog.component'
import { ConfirmationComponent } from './coad-web/dialogs/confirmation/confirmation.component'
import { ClientComponent,  } from './coad-web/proforma-to-dispatch/pages/client/client.component';
import { IwdDeadlineComponent} from './coad-web/dialogs/iwd-deadline/iwd-deadline.component';
import { DatepickerInlineCalendarComponent } from './coad-web/proforma-to-dispatch/abstracts/_inline-calender';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop'; 
import { MatDialogModule } from '@angular/material/dialog';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PipeModule } from './coad-web/proforma-to-dispatch/abstracts/_pipes'
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ClientCountriesComponent } from './client-countries/client-countries.component';
import { ProductImagesComponent } from './product-images/product-images.component';
import { ProjectClosureComponent } from './project-closure/project-closure.component';

export const EMS_DATE_FORMATS: MatDateFormats = {
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD MMMM YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
  parse: {
    dateInput: 'DD MMM YYYY'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    WelcomeComponent,
    MainComponent,
    EmailActionComponent,
    ClientCountriesComponent,
    ProductImagesComponent,
    ClientComponent,
    ProformaToDispatchComponent,
    DispatchDetailsComponent,
    ChasingBoardComponent,
    ChaseNotesComponent,
    StaffListComponent,
    StaffFilterPipe,
    AssignmentsWarehouseFilterPipe,
    SuppliersWarehouseFilterPipe,
    DispatchesWarehouseFilterPipe,
    NotesDialogComponent,
    ConfirmationComponent,
    IwdDeadlineComponent,
    ProjectClosureComponent,
    DatepickerInlineCalendarComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    RoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PerfectScrollbarModule,
    MaterialModule,
    ServerModule,
    SharedModule,
    EppUsersModule,
    CrawlerStatusModule,
    PurchaseInvoiceModule,
    PipeModule,
    ApiModule,
    RouterModule,
    DragDropModule,
    MatDialogModule,
    ScrollingModule,
    MatTabsModule,
    MatMenuModule,
    MatButtonToggleModule,
    ExportToQuickbooksModule,
  ],
  providers: [
    { provide: EMS_API_SERVER_URL, useValue: environment.emsServerUrl },
    { provide: ADMIN_API_SERVER_URL, useValue: environment.serverUrl },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: EMS_DATE_FORMATS },
    { provide: BASE_PATH, useValue: environment.basePath },
    StaffFilterPipe, ChaseNotesComponent, CommentActionService,
    // SharedDispatchData, 
    // DispatchDetailsTranslations
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
