import { Component, OnInit, Input } from '@angular/core';
import * as Models from '@equipmyschool/emsadminweb-models';

@Component({
  selector: 'app-country-icon',
  templateUrl: './country-icon.component.html',
  styleUrls: ['./country-icon.component.scss']
})
export class CountryIconComponent {
  @Input() country: Models.Country;

  constructor() { }

}
