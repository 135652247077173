import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ServerBaseService } from '../server-base.service';
import { Commodity } from '@equipmyschool/emsadminweb-models';
import { ADMIN_API_SERVER_URL } from '../tokens/admin-api-server-url.token';

@Injectable()
export class CommodityService extends ServerBaseService {
  constructor(http: HttpClient, @Inject(ADMIN_API_SERVER_URL) serverUrl: string) {
    super(http, serverUrl);
  }

  public get = () => {
    return this.request<Commodity[]>(
      'GET',
      'Commodity/Get'
    );
  }
}
