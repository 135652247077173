import { Directive, Provider, OnChanges, Input, SimpleChanges, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl, ValidationErrors, Validators } from '@angular/forms';

// Source: https://github.com/angular/angular/commit/81925fa66d2c930f68d3b6dd084c954ea1bab6b6#diff-5b182f7023604dff820b5c3bd44bfcdb

export const MIN_VALIDATOR: Provider = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => MinValidatorDirective),
  multi: true
};

/**
 * A directive which installs the {@link MinValidator} for an input that has `formControlName`,
 * `formControl`, or `ngModel` and a `min` attribute.
 *
 * @experimental
 */
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'input[type=number][min][formControlName],input[type=number][min][formControl],input[type=number][min][ngModel]',
  providers: [MIN_VALIDATOR],
  host: { '[attr.min]': 'min ? min : null' }
})
export class MinValidatorDirective implements Validator, OnChanges {
  private _validator: ValidatorFn;
  private _onChange: () => void;

  @Input('min') min: number;

  ngOnChanges(changes: SimpleChanges): void {
    if ('min' in changes) {
      this._createValidator();
      if (this._onChange) {
        this._onChange();
      }
    }
  }

  validate(c: AbstractControl): ValidationErrors | null { return this._validator(c); }

  registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }

  private _createValidator(): void { this._validator = Validators.min(this.min); }
}
