import { Injectable } from "@angular/core";
import { DispatchClipboard, PtdDispatchDetails, PtdDispatchService } from "coadapi";
import { Observable } from "rxjs";
import { CustomDateAdapter } from "./_date-adapter";

@Injectable({
  providedIn: "root",
})
export class downloadSummaries {
  currentUtcDate = CustomDateAdapter.createDate(
    new Date().getUTCFullYear(),
    new Date().getUTCMonth(),
    new Date().getUTCDate()
  );
  constructor(private dispatchService: PtdDispatchService) {}

  public dispatchClipboards$: Observable<DispatchClipboard[]>;

  private setting = {
    element: {
      dynamicDownload: null as HTMLElement,
    },
  };

  getSummary(dispatch: PtdDispatchDetails) {
    this.dispatchClipboards$ = this.dispatchService.getSingleClipboardSummary(
      dispatch.dispatchId
    );
    this.dispatchClipboards$.subscribe((res) => {
      this.dynamicDownloadByHtmlTag({
        fileName:
          dispatch.accountNo + "-" + dispatch.dispatchNo + " Clipboard Report - " + this.currentUtcDate.toLocaleDateString(),
        text: this.formatSummaries(res),
      });
    });
  }

  getSummaries() {
    this.dispatchClipboards$ = this.dispatchService.getClipboardSummary();
    this.dispatchClipboards$.subscribe((res) => {
      this.dynamicDownloadByHtmlTag({
        fileName:
          "Clipboard Report - " + this.currentUtcDate.toLocaleDateString(),
        text: this.formatSummaries(res),
      });
    });
  }

  formatSummaries(list: DispatchClipboard[]): string {
    const listOfDispatchClipboards = list;
    let summaryString = "";
    listOfDispatchClipboards.forEach((dC) => {
      summaryString +=
        dC.schoolAcc + " | Dispatch: " + dC.etd.substring(0, 10) + " | " + dC.percRec + " Complete \n";
      summaryString +=
        "Shipped To: " + dC.shippedTo + " | Leaving From: " + dC.leavingFrom + "\n";
      summaryString +=
        dC.ltc + " LTC | " + dC["intake?"] + " Intake? | " + dC.noETA + " with no ETA | " + dC.badETA + " with Bad ETA \n";
      summaryString +=
        "POC: " + dC.poc + " | PQ: " + dC.pq + " | CQ: " + dC.cq + " | OWNR: " + dC.ownr;
      summaryString += "\n\n";
    });

    return summaryString;
  }

  private dynamicDownloadByHtmlTag(arg: { fileName: string; text: string }) {
    if (!this.setting.element.dynamicDownload) {
      this.setting.element.dynamicDownload = document.createElement("a");
    }
    const element = this.setting.element.dynamicDownload;
    const fileType =
      arg.fileName.indexOf(".json") > -1 ? "text/json" : "text/plain";
    element.setAttribute(
      "href",
      `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`
    );
    element.setAttribute("download", arg.fileName);

    const event = new MouseEvent("click");
    element.dispatchEvent(event);
  }
}
