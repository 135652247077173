<button mat-fab type="button" color="primary" matTooltip="Refresh" (click)="refreshData()" class="refreshButton">
  <mat-icon>refresh</mat-icon>
</button>

<div fxLayout="column" style="height: 100%;">

  <div fxFlex class="mat-elevation-z2">
    <mat-table [dataSource]="eventLog$ | dataSource" matSort>
      <ng-container cdkColumnDef="DateTime">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Date/Time </mat-header-cell>
        <mat-cell *cdkCellDef="let row">
          {{ row.Date | date: 'dd/MM/yyyy HH:mm:ss' }}
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="Type">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Type </mat-header-cell>
        <mat-cell *cdkCellDef="let row">
          {{ row.Type }}
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="Message">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header> Message </mat-header-cell>
        <mat-cell *cdkCellDef="let row" style="white-space: pre;">
          {{ row.Message }}
        </mat-cell>
      </ng-container>

      <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *cdkRowDef="let row; columns: displayedColumns;" [ngClass]="row.Type"></mat-row>
    </mat-table>


    <!-- <table class="table table-striped">
      <thead>
        <tr>
          <th>Date/Time</th>
          <th>Time</th>
          <th>Message</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of eventLog">
          <td>{{ row.Date }}</td>
          <td>{{ row.Type }}</td>
          <td style="white-space: pre;">{{ row.Message }}</td>
        </tr>
      </tbody>
    </table> -->

  </div>
</div>
