import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';

import { ServerBaseService } from '../server-base.service';
import { Credential, User } from '@equipmyschool/emsadminweb-models';
import { ADMIN_API_SERVER_URL } from '../tokens/admin-api-server-url.token';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class AccountService extends ServerBaseService {
  public iAmBehaviourSubject:BehaviorSubject<User> = new BehaviorSubject(null);

  constructor(http: HttpClient, @Inject(ADMIN_API_SERVER_URL) serverUrl: string) {
    super(http, serverUrl);
  }

  public logIn(credential: Credential) {
    return this.request<User>('POST', 'Account/SignIn', null, credential);
  }

  public logOut() {
    return this.request('POST', 'Account/SignOut');
  }

  public whoAmI() {
    return this.request<User>('GET', 'Account/WhoAmI');
  }
}
