<h1 mat-dialog-title>Set ETA for {{data.description}}</h1>
<div mat-dialog-content>
  <form #form="ngForm">
    <mat-form-field>
      <input matInput #ctrlDate="ngModel"
             [(ngModel)]="Date"
             [required]=true
             [matDatepicker]="picker"
             placeholder="Estimated Delivery Date" name="Date">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <br>
    <mat-form-field>
      <mat-select placeholder="Select a Reason"
                  #ctrl="ngModel"
                  [(ngModel)]="selectedReason"
                  [ngModelOptions]="{standalone: true}">
        <mat-option *ngFor="let eta of arrayReason" [value]="eta">
          {{eta.Name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close tabindex="-1">Cancel</button>
  <button mat-button
          mat-dialog-close
          tabindex="1"
          [disabled]="ctrl.invalid && ctrlDate.invalid"
          (click)="setETA(data.lineID)">OK</button>
</mat-dialog-actions>
