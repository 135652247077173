import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ADMIN_API_SERVER_URL } from '../tokens/admin-api-server-url.token';
import { ServerBaseService } from '../server-base.service';
import {
  PurchaseInvoiceSupplier,
  PurchaseOrder,
  PurchaseInvoice,
  InvoiceType,
  PurchaseInvoiceLine,
  PurchaseOrderDetail,
  PurchaseInvoiceSaveDetails,
  PurchaseInvoiceProductDetailed
} from '@equipmyschool/emsadminweb-models';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PurchaseInvoiceService extends ServerBaseService {
  private _piProducts$ = new BehaviorSubject<PurchaseInvoiceProductDetailed[]>(null);
  public piProducts$ = this._piProducts$.asObservable();

  constructor(http: HttpClient, @Inject(ADMIN_API_SERVER_URL) serverUrl: string) {
    super(http, serverUrl);
  }

  public getSuppliers = (params: { invoiceType: InvoiceType }) => {
    return this.request<PurchaseInvoiceSupplier[]>(
      'GET',
      'PurchaseInvoice/GetSuppliers',
      params
    );
  }

  public getPurchaseOrders = (params: { SupplierID: number, invoiceType: InvoiceType }) => {
    return this.request<PurchaseOrder[]>(
      'GET',
      'PurchaseInvoice/GetPurchaseOrders',
      params
    );
  }

  /**
   * Gets a list of lines still needing an invoice posted against them, in the supplier's native currency.
   * @param SupplierID The ID of the Supplier
   * @param PurchaseOrderID The ID of the Purchase Order
   * @param invoiceType The type of invoice
   */
  public getLines = (params: { SupplierID: number, PurchaseOrderID: number, invoiceType: InvoiceType }) => {
    return this.request<PurchaseInvoiceLine[]>(
      'GET',
      'PurchaseInvoice/GetLines',
      params
    );
  }

  /**
   * Posts the details sent to the database in the supplier's native currency.
   * For foreign currency invoices, the values will be converted to GBP using PurchaseInvoice.ExchangeRate.
   * @param purchaseInvoice The details of the Purchase Invoice
   */
  public post = (purchaseInvoice: PurchaseInvoice) => {
    return this.request<PurchaseInvoiceSaveDetails>(
      'POST',
      'PurchaseInvoice/Post',
      null,
      purchaseInvoice
    );
  }

  /**
   * Sets the quantity on the line with given LineID,
   * and returns a new line that contains the rest of the quantity.
   * @param LineID
   * @param Quantity
   */
  public splitLine = (LineID: number, Quantity: number) => {
    return this.request<PurchaseInvoiceLine>(
      'PUT',
      'PurchaseInvoice/SplitLine',
      { LineID, Quantity }
    );
  }

  public getPurchaseOrdersByProductCode = (params: { ProductCode: string }) => {
    return this.request<PurchaseOrderDetail[]>(
      'GET',
      'PurchaseInvoice/GetPurchaseOrdersByProductCode',
      params
    );
  }

  public checkInvoiceNumber = (params: { CompanyID: number, InvoiceNumber: string, InvoiceType: InvoiceType }) => {
    return this.request(
      'GET',
      'PurchaseInvoice/CheckInvoiceNumber',
      params
    );
  }

  public getProducts = (PIID: number) => {
    this.request<PurchaseInvoiceProductDetailed[]>('GET', 'PurchaseInvoice/GetProducts', { PIID })
      .subscribe((result) => {
        this._piProducts$.next(result);
      });
  }

  public updateProducts = (Products: PurchaseInvoiceProductDetailed[]) => {
    return this.request(
      'POST',
      'PurchaseInvoice/UpdateProducts',
      null,
      Products
    )
  }

  public delete = (PIID: number, Reason: string) => {
    return this.request(
      'POST',
      'PurchaseInvoice/Delete',
      { PIID: PIID, Reason: Reason }
    )
  }
}
