import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { CrawlerStatusSupplier, ShopDepartment } from '@equipmyschool/emsadminweb-models';
import { ProjectTreeDialogComponent } from 'app/crawler-status/plugins/project-tree-dialog/project-tree-dialog.component';
import { CrawlerStatusService } from 'app/server/server.module';
import { UnsubscribeOnDestroy } from 'app/shared/decorators/unsubscribe-on-destroy';
import { FilteredDataSource, getSortFn } from 'app/shared/filtered-data-source/filtered-data-source';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-crawler-status-plugins',
  templateUrl: './crawler-status-plugins.component.html',
  styleUrls: ['./crawler-status-plugins.component.scss']
})
export class CrawlerStatusPluginsComponent implements OnInit, AfterViewInit {
  public emsClientUrl = environment.emsClientUrl;
  public dataSource = new FilteredDataSource(
    () => {
      return this.server.getSuppliers();
    }
  );

  public displayedColumns = ['Name', 'Logo', 'RootDepartment', 'Checkboxes', 'OutOfDateCategories', 'OutOfDateProducts', 'HomepageChecked'];

  @ViewChild(MatSort) sort: MatSort;

  @UnsubscribeOnDestroy()
  private subscriptions: Subscription[] = [];

  constructor(
    private server: CrawlerStatusService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.dataSource.forceUpdate();
  }

  ngAfterViewInit() {
    this.subscriptions.push(
      this.sort.sortChange.subscribe((val: Sort) => {
        this.dataSource.sortFn = getSortFn(val);
      })
    );
  }

  togglePluginEnabled(supplier: CrawlerStatusSupplier) {
    this.server.togglePluginEnabled(supplier.SupplierID).subscribe(_ => {
      supplier.PluginEnabled = !supplier.PluginEnabled;
    });
  }

  toggleShowProducts(supplier: CrawlerStatusSupplier) {
    this.server.toggleShowProducts(supplier.SupplierID).subscribe(_ => {
      supplier.ShowProductsOnline = !supplier.ShowProductsOnline;
    });
  }

  toggleUseCrawledPrices(supplier: CrawlerStatusSupplier) {
    this.server.toggleUseCrawledPrices(supplier.SupplierID).subscribe(_ => {
      supplier.UseCrawledPrices = !supplier.UseCrawledPrices;
    });
  }

  setRootDepartment(row: CrawlerStatusSupplier) {
    const dialogRef = this.dialog.open(ProjectTreeDialogComponent, {
      data: {
        selectedNode: row.RootDepartment
      }
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((node: ShopDepartment) => {
        if (node) {
          this.server.setRootDepartment({ SupplierID: row.SupplierID, DepartmentID: node.DepartmentID }).subscribe(() => {
            this.snackBar.open('Root department has been successfully set');
            row.RootDepartment = node;
          });
        }
      }));
  }
}
