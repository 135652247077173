import { BehaviorSubject, Observable, ReplaySubject, Subscription, merge } from 'rxjs';
import { skip, switchMap, share, filter, distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/collections';
import { Sort } from '@angular/material/sort';
import { reEmitWhen } from 'app/shared/rx-operators/re-emit-when';

export class FilteredDataSource<T> extends DataSource<T> {
  readonly initialFilterValue = '';
  readonly debounceTime = 300;
  readonly minFilterLength = 5;

  readonly subscriptions: Subscription[] = [];

  /**
   * Observable of events that are forced by the user (e.g., when a user pushes 'enter' button)
   */
  _forcedUpdates = new ReplaySubject<string>(1);
  /**
   * Observable of filter values
   */
  _filterChange = new BehaviorSubject(this.initialFilterValue);
  get filter(): string { return this._filterChange.value; }
  set filter(filterString: string) { this._filterChange.next(filterString); }

  _sortFnChange: BehaviorSubject<SortFn<T>> = new BehaviorSubject(null);
  get sortFn() {
    return this._sortFnChange.value;
  }
  set sortFn(sortFn: SortFn<T>) {
    this._sortFnChange.next(sortFn);
  }

  /**
   * Resulting observable conists of two merged observables:
   * - the observable that gets fired upon filter value changes (filter values less that than minFilterLength are ignored)
   * - the observable that gets fired by the user manually (usually an 'Enter' press)
   *
   * Whenever any of those events gets fired, the new value gets pushed to the resulting observable.
   */
  _resultingObservable: Observable<T[]>;

  /**
   * We wrap the _resultingObservable with a ReplaySubject
   * so that, on each subscribe() call, the subscriber receives the last cached result value.
   * Also, thanks to the wrapper, multiple subsrcibers share the same observable (and multiple https calls are avoided)
   */
  _resultWrapped = new ReplaySubject<T[]>(1);

  constructor(private filterSwitchMapFn: (value: string) => Observable<T[]>) {
    super();
    const self = this;
    const serverResults = new ReplaySubject<T[]>(1);

    this.subscriptions.push(
      merge(
        this._filterChange.pipe(
          debounceTime(this.debounceTime),
          distinctUntilChanged(),
          filter(filterValue => filterValue.length >= this.minFilterLength), ),
        this._forcedUpdates,
      ).pipe(
        switchMap(this.filterSwitchMapFn), share(), ).subscribe(serverResults)
    );

    this._resultingObservable =
      serverResults.pipe(
        reEmitWhen(this._sortFnChange.pipe(skip(1))),
        map(res => {
          if (self.sortFn) {
            return res.slice().sort(self.sortFn);
          } else {
            return res;
          }
        })
      );

    this.subscriptions.push(this._resultingObservable.subscribe(this._resultWrapped));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<T[]> {
    return this._resultWrapped;
  }

  disconnect() {
  }

  forceUpdate() {
    this._forcedUpdates.next(this._filterChange.getValue());
  }
}

type SortFn<T> = (a: T, b: T) => number;

export function compare<T>(a: T, b: T, isAsc: boolean) {
  if (typeof a === 'string') {
    a = a.toLowerCase() as any;
  }

  if (typeof b === 'string') {
    b = b.toLowerCase() as any;
  }

  return (a === b ? 0 : a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

export function compareByFields<T>(a: T, b: T, compareFields: { fn: (T) => any, isAsc: boolean }[]) {
  for (const tuple of compareFields) {
    const compareResult = compare(tuple.fn(a), tuple.fn(b), tuple.isAsc);
    if (compareResult !== 0) {
      return compareResult;
    }
  }

  return 0;
}

export function getSortFn<T>(val: Sort, columnSortMapping?: { [key: string]: (a: T) => any }) {
  if (val.direction) {
    const mapping = columnSortMapping && columnSortMapping[val.active] ? columnSortMapping[val.active] : (a: T) => a[val.active];

    return (a: T, b: T) => {
      return compare(mapping(a), mapping(b), val.direction === 'asc');
    };
  } else {
    return null;
  }
}
