import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';


@Component({
    selector: 'inline-range-calendar',
    template: '',

})
export class InlineRangeCalendarComponent {
    index = 0;
    @Input() selectedRangeValue: DateRange<Date> | undefined;
    @Input() originalRangeValue: DateRange<Date> | undefined;
    @Output() selectedRangeValueChange = new EventEmitter<DateRange<Date>>();
    @Output() originalRangeValueChange = new EventEmitter<DateRange<Date>>();

    /**
     * This method is called when the selected date range changes.
     * It updates the selectedRangeValue and originalRangeValue properties and emits the changes.
     *
     * @param {any} m - The newly selected date value.
     * @param {number} i - The index of the date range.
     * @param {boolean} disabled - Indicates if the date range is disabled.
     */
    selectedChange(m: any, i: number, disabled: boolean) {
        // Update the index
        this.index = i;

        // If the date range is disabled, return without making any changes
        if (disabled) {
            return;
        } else {
            // If start or end date is not set in selectedRangeValue, set it to new DateRange with m and null
            if (!this.selectedRangeValue?.start || this.selectedRangeValue?.end) {
                this.selectedRangeValue = new DateRange<Date>(m, null);
            } else {
                // If start or end date is set in selectedRangeValue, update originalRangeValue and create new DateRange
                this.originalRangeValue = this.selectedRangeValue;
                const start = this.selectedRangeValue.start;
                const end = m;
                if (end < start) {
                    this.selectedRangeValue = new DateRange<Date>(end, start);
                } else {
                    this.selectedRangeValue = new DateRange<Date>(start, end);
                }
            }
            // Emit the changes in selectedRangeValue and originalRangeValue
            this.selectedRangeValueChange.emit(this.selectedRangeValue);
            this.originalRangeValueChange.emit(this.originalRangeValue);
        }
    }
}
