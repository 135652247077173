  <div *ngIf="clients" fxFlexFill>
    <div fxLayoutAlign="center center" fxLayoutGap="6px">
      <mat-form-field fxFlex="20">
        <mat-label>Client</mat-label>
        <mat-select [(ngModel)]="selectedClient" name="client" (selectionChange)="clientChanged()">
          <mat-option [value]="null">[All Clients]</mat-option>
          <mat-option *ngFor="let client of clients" [value]="client.CompanyID">
            {{client.AccountNo}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-flat-button color="primary" (click)="findProduct()">
        Search
      </button>
      <mat-label *ngIf="remaining">{{remaining}} now remaining</mat-label>
    </div>

    <form *ngIf="product" [formGroup]="form" (ngSubmit)="addImage()">
      <div fxLayoutAlign="center center">
          <mat-card fxFlex="80">
            <mat-card-header>
              <div fxLayout="column" fxLayoutAlign="center start">
                <p>Supplier: {{product.Supplier}}</p>
                <p>Code: {{product.Code}}</p>
                <p>Description: {{product.Description}}</p>
              </div>
            </mat-card-header>

            <mat-card-content>
              <mat-tab-group dynamicHeight (selectedTabChange)="resetValues()">
                <mat-tab label="Paste image URL" selected fxLayoutAlign="center start">
                  <mat-form-field fxFlexFill>
                    <input matInput formControlName="url" name="url" fxFlexFill />
                    <mat-error *ngIf="form.get('url').hasError('pattern')">Invalid URL</mat-error>
                    <mat-error *ngIf="form.get('url').errors?.serverError">
                      {{form.get('url').errors?.serverError}}
                    </mat-error>
                  </mat-form-field>
                </mat-tab>
                <mat-tab label="Upload an image" fxLayoutAlign="center start">
                  <div fxLayoutAlign="center start">
                    <input type="file" #image formControlName="image" name="image" fxFlexFill />
                  </div>
                  <mat-error *ngIf="form.get('image').errors?.serverError">
                    {{form.get('image').errors?.serverError}}
                  </mat-error>
                </mat-tab>
              </mat-tab-group>
            </mat-card-content>

            <mat-card-actions>
              <button mat-flat-button color="primary" type="submit">
                Add image to product
              </button>
            </mat-card-actions>
          </mat-card>
      </div>
    </form>

    <div *ngIf="stillSearching == false" fxLayoutAlign="center center">
        <mat-card fxFlex="80">
          <mat-card-content>
            No more products requiring images <mat-label *ngIf="selectedClient">for the selected client</mat-label>
          </mat-card-content>
        </mat-card>
    </div>
  </div>
