<div class="list" *ngFor="let comment of commentsList">
  <div class="note completion-{{this.isCompletionMessage(comment.comment) ? 'true' : this.isUnCompletionMessage(comment.comment) ? 'true': 'false'}}">
    <section class="user" >
        <div class="profilepicture"><img src="{{comment.user.imageUrl}}"></div>
        <div class="user-posted">
          <div class="username">{{comment.user.firstName}} {{comment.user.lastName}}</div>
          <div class="posted">{{comment.posted | date:"MMM dd, yyyy hh:mm a" : "Europe/London"}}</div>
        </div>
        <div class="edit {{comment.user.contact == user.ContactID}}" ><button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="makeEdits(comment.commentId)">
              <mat-icon>edit</mat-icon>
              <span>Edit</span>
            </button>
            <button mat-menu-item (click)="edit(comment.commentId,null,true,null)">
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu></div>
    </section>
    <section class="complete completion-{{this.isCompletionMessage(comment.comment) ? 'true' : this.isUnCompletionMessage(comment.comment) ? 'true': 'false'}}">
      <div class="tick {{this.isCompletionMessage(comment.comment)}}">
        <mat-icon svgIcon="tick"></mat-icon>
      </div>
      <div class="completionmessage">{{comment.user.firstName}} {{comment.user.lastName}} {{this.isUnCompletionMessage(comment.comment)? "un":""}}completed this supplier on {{comment.posted | date: 'MMM dd, yyyy hh:mm a' : 'Europe/London'}}</div>
    </section>
    <section class="message">
        <textarea cdkTextareaAutosize matInput [(ngModel)]="comment.comment" [readonly]="!this.editVisibilityList.get(comment.commentId)" (input)="onTextChange($event)" class="textbox" placeholder="Type a comment or use @ to tag a new person"></textarea>
    </section>
    <section class="actions vis-{{this.editVisibilityList.get(comment.commentId)}}">
      <button mat-button class="close accept" (click)="edit(comment.commentId,null,null,comment.comment)">Comment</button>
      <button mat-button class="close cancel" (click)="makeEdits(comment.commentId)">Cancel</button>
    </section>
  </div>
</div>
<div class="list" *ngIf="true">
  <div class="note">
    <section class="user">
        <div class="profilepicture"><img src="{{this.user.ProfilePicture}}"></div>
        <div class="username">{{this.user.FirstName}} {{this.user.LastName}}</div>
    </section>
    <section class="message">
        <textarea id="taMessageInput" cdkTextareaAutosize matInput [(ngModel)]="taMessage" (input)="onTextChange($event)" class="textbox" placeholder="Type a comment or use @ to tag a new person" cdkFocusInitial
        (keydown)="handleKeyDown($event)"></textarea>
    </section>
    <section class="actions">
      <button mat-button class="close accept" (click)="reply()">Comment</button>
      <button mat-button mat-dialog-close class="close cancel">Cancel</button>
    </section>
  </div>
</div>

