import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';

// Components
import { PurchaseInvoiceComponent } from './purchase-invoice.component';
import { MaterialModule } from 'app/material.module';
import { PurchaseOrderLinesTableComponent } from './purchase-order-lines-table/purchase-order-lines-table.component';
import { SplitQuantityDialogComponent } from './split-quantity-dialog/split-quantity-dialog.component';
import { MaxValidatorDirective } from 'app/shared/validators/max.validator';
import { MinValidatorDirective } from 'app/shared/validators/min.validator';
import { SharedModule } from 'app/shared/shared.module';
import { SetExchangeRateDialogComponent } from './set-exchange-rate-dialog/set-exchange-rate-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SaveDialogComponent } from './save-dialog/save-dialog.component';
import { FindOrderComponent } from './find-order/find-order.component';
import { ServerDataProvidersModule } from 'app/server-data-providers/server-data-providers.module';
import { SavedSuccessfullyComponent } from './saved-successfully/saved-successfully.component';
import { SetEtaComponent } from './set-eta/set-eta.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    SharedModule,
    FlexLayoutModule,
    ServerDataProvidersModule,
    ClipboardModule
  ],
  declarations: [
    PurchaseInvoiceComponent,
    PurchaseOrderLinesTableComponent,
    SplitQuantityDialogComponent,
    SetExchangeRateDialogComponent,
    SaveDialogComponent,
    FindOrderComponent,
    SetEtaComponent,
    SavedSuccessfullyComponent
  ],
  providers: [
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true}},
  ],
  entryComponents: [
    SplitQuantityDialogComponent,
    SetExchangeRateDialogComponent,
    SaveDialogComponent,
    FindOrderComponent,
    SetEtaComponent,
    SavedSuccessfullyComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class PurchaseInvoiceModule { }
