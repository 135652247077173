import {Pipe, PipeTransform} from '@angular/core';
import { ContactDetails } from 'coadapi';

@Pipe({ name: 'contactSearch' })
export class ContactFilterPipe implements PipeTransform {
  public transform(contacts: ContactDetails[], searchText: any): any {
    if (searchText == null || contacts == null) {
      return contacts;
    }
    return contacts.filter(contact => contact.fullName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
  }
}