import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, NgForm, ValidatorFn } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Commodity, InvoiceType, PurchaseAvailabilityType, PurchaseInvoice, PurchaseInvoiceLine, PurchaseInvoiceSaveDetails, PurchaseInvoiceSupplier, PurchaseOrder, PurchaseOrderDetail } from '@equipmyschool/emsadminweb-models';
import { FindOrderComponent } from 'app/purchase-invoice/find-order/find-order.component';
import { SaveDialogComponent } from 'app/purchase-invoice/save-dialog/save-dialog.component';
import { SavedSuccessfullyComponent } from 'app/purchase-invoice/saved-successfully/saved-successfully.component';
import { SetExchangeRateDialogComponent } from 'app/purchase-invoice/set-exchange-rate-dialog/set-exchange-rate-dialog.component';
import { SplitQuantityDialogComponent } from 'app/purchase-invoice/split-quantity-dialog/split-quantity-dialog.component';
import { PurchaseInvoiceProvider } from 'app/server-data-providers/purchase-invoice.provider';
import { TaxType } from 'app/server/server-enums';
import { CommodityService } from 'app/server/server.module';
import { ConfirmDialogComponent } from 'app/shared/confirm-dialog/confirm-dialog.component';
import { UnsubscribeOnDestroy } from 'app/shared/decorators/unsubscribe-on-destroy';
import { compareByFields } from 'app/shared/filtered-data-source/filtered-data-source';
import { CamelCaseToWordsPipe } from 'app/shared/pipes/camel-case-to-words.pipe';
import { round } from 'app/shared/round';
import { createBehaviorSubject } from 'app/shared/rx-operators/create-behavior-subject';
import { reEmitWhen } from 'app/shared/rx-operators/re-emit-when';
import { BehaviorSubject, Observable, of, Subscription, throwError as observableThrowError } from 'rxjs';
import { catchError, map, share, switchMap, tap } from 'rxjs/operators';
import { OrderLineService } from '../server/services/order-line/order-line.service';


@Component({
  selector: 'app-purchase-invoice',
  templateUrl: './purchase-invoice.component.html',
  styleUrls: ['./purchase-invoice.component.scss']
})
export class PurchaseInvoiceComponent implements OnInit {
  public invoice: Partial<PurchaseInvoice> = {};

  public applySubTotalDiscount = false;
  public commodities: Commodity[];
  TaxType = TaxType;

  @ViewChild('form') form: NgForm;

  public get linesDataSource() {
    return this.orderLines$.pipe(
      reEmitWhen(this.selectedLines$),
      map(orderLines => orderLines.filter(line => this.invoice.Lines.filter(orderLine => orderLine.LineID === line.LineID).length === 0)),
      map(lines => lines.sort((a, b) => {
        const compareFields = [
          {
            fn: (x: PurchaseInvoiceLine) => x.POLine,
            isAsc: true
          }
        ];

        return compareByFields<PurchaseInvoiceLine>(a, b, compareFields);
      }))
    );
  }

  public get selectedLinesDataSource() {
    return this.selectedLines$.asObservable();
  }

  @UnsubscribeOnDestroy()
  private subscriptions: Subscription[] = [];

  private invoiceType$ = createBehaviorSubject<InvoiceType>(
    undefined,
    this.activatedRoute.paramMap.pipe(
      map(params => {
        const documentType = parseInt(params.get('documentType'), 10);
        if (InvoiceType[documentType] === undefined) {
          throw new Error(`${params['documentType']} is not a supported document type`);
        }
        return documentType;
      })));
  private selectedSupplier$ = new BehaviorSubject<PurchaseInvoiceSupplier>(undefined);
  private selectedPurchaseOrder$ = new BehaviorSubject<PurchaseOrder>(undefined);
  private selectedLines$ = new BehaviorSubject<PurchaseInvoiceLine[]>([]);
  private foreignCurrency = false;

  suppliers$ = this.invoiceType$.pipe(
    switchMap(invoiceType => {
      return this.dataProvider.suppliers.forParams({ invoiceType });
    }),
    share()
  );

  public suppliers: PurchaseInvoiceSupplier[];

  public purchaseOrders$ = this.selectedSupplier$.pipe(
    switchMap(selectedSupplier => {
      return selectedSupplier ?
        this.dataProvider.purchaseOrders.forParams({ SupplierID: selectedSupplier.CompanyID, invoiceType: this.invoiceType$.getValue() }) :
        of(undefined);
    }),
    share()
  );

  public get selectedSupplier() {
    return this.selectedSupplier$.getValue();
  }
  public set selectedSupplier(value: PurchaseInvoiceSupplier) {
    this.selectedSupplier$.next(value);
  }

  public get selectedPurchaseOrder() {
    return this.selectedPurchaseOrder$.getValue();
  }
  public set selectedPurchaseOrder(value: PurchaseOrder) {
    this.selectedPurchaseOrder$.next(value);
  }

  public orderLines$ = createBehaviorSubject(
    [],
    this.selectedPurchaseOrder$.pipe(switchMap(selectedPurchaseOrder => {
      return selectedPurchaseOrder ?
        this.dataProvider.lines.forParams({
          SupplierID: this.selectedSupplier.CompanyID,
          PurchaseOrderID: selectedPurchaseOrder.PurchaseOrderID,
          invoiceType: this.invoiceType$.getValue()
        }).pipe(
          tap(lines => {
            for (const line of lines) {
              if (line.TaxCost === 0) {
                const VATRate = (this.selectedSupplier.TaxType === TaxType.UKVAT ? line.Product.Commodity.VATRate : 0);
                line.TaxCost = round(line.Cost * VATRate);
              }
            }
          })) :
        of<PurchaseInvoiceLine[]>([]);
    }),
      share()
    ));

  public get orderLines() {
    return this.orderLines$.getValue();
  }

  public set orderLines(value: PurchaseInvoiceLine[]) {
    this.orderLines$.next(value);
  }

  public get documentTypeReadable() {
    const invoiceType = this.invoiceType$.getValue();
    return invoiceType === undefined ? undefined :
      new CamelCaseToWordsPipe().transform(InvoiceType[invoiceType]);
  }

  constructor(
    private dataProvider: PurchaseInvoiceProvider,
    private commodityServer: CommodityService,
    private orderLineService: OrderLineService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.orderLineService.getReasons();
    this.subscriptions.push(
      this.commodityServer.get().subscribe(res => {
        this.commodities = res;
      }),

      // Reset selection if parent selection gets reset
      this.invoiceType$.subscribe(invoiceType => {
        this.invoice.Type = invoiceType;
        this.reset();
      }),

      this.selectedSupplier$.subscribe(selectedSupplier => {
        this.selectedPurchaseOrder = undefined;
        this.applySubTotalDiscount = false;
        this.selectedLines$.next([]);
        this.invoice.Discount = .0;
        this.invoice.Surcharge = .0;
        this.invoice.VATSurcharge = false;
        this.invoice.Freight = .0;
        this.invoice.Tax = .0;
        this.invoice.VATFreight = false;
        this.invoice.ExchangeRate = 1.0;
        this.invoice.InvoiceNumber = undefined;
        this.invoice.Date = undefined;
        if (this.form) {
          this.form.control.markAsPristine();
          this.form.control.markAsUntouched();
        }
        this.foreignCurrency = false;
      }),

      this.selectedPurchaseOrder$.subscribe(selectedPurchaseOrder => {
        this.orderLines = [];
      }),

      this.selectedLines$.subscribe(selectedLines => {
        this.invoice.Lines = selectedLines;
      }),

      this.suppliers$.subscribe(suppliers => {
        this.suppliers = suppliers;
      })
    );
  }

  public toggleSelected(row: PurchaseInvoiceLine) {
    const newArray = this.invoice.Lines.slice();
    const found = newArray.filter(arrayEl => arrayEl.LineID === row.LineID);
    if (found.length === 1) {
      newArray.splice(this.invoice.Lines.indexOf(found[0]), 1);
    } else if (found.length === 0) {
      newArray.push({ ...row, Availability: PurchaseAvailabilityType.Available });
    } else {
      throw new Error('invoice.Lines contains duplicate items');
    }

    this.selectedLines$.next(newArray);
  }

  public isRowSelected = (row) => {
    return this.invoice.Lines.indexOf(row) > -1;
  }

  public reset() {
    this.selectedSupplier = undefined;
  }

  public calculateVatTotal() {
    switch (this.selectedSupplier.TaxType) {
      case TaxType.NoTax:
        return 0;
      case TaxType.UKVAT: {
        let vat = this.invoice.Lines.reduce<number>((total: number, line) => total + line.TaxCost, 0);
        const ukFreightVatRate = this.commodities.find(com => com.Name === 'Freight').VATRate;
        vat += this.invoice.VATFreight ? this.invoice.Freight * ukFreightVatRate : 0;
        vat += this.invoice.VATSurcharge ? this.invoice.Surcharge * ukFreightVatRate : 0;
        return round(vat);
      }
      default:
        throw new Error('Feature not implemented yet');
    }
  }

  public save() {
    this.dialog.open(SaveDialogComponent, {
      data: {
        validator: this.grandTotalCorrect,
        currencyCode: this.currencyCode,
        documentTypeReadable: this.documentTypeReadable
      }
    }).afterClosed().subscribe(totalValue => {
      if (totalValue !== '') {
        this.invoice.Value = totalValue;

        this.dataProvider.post(this.invoice as PurchaseInvoice).subscribe(res => {
          // Saved successfully!
          this.saveSuccessfulDialog(res).subscribe(_ => {
            this.dataProvider.suppliers.forParams({ invoiceType: this.invoice.Type }).update();
            this.dataProvider.purchaseOrders.forParams(
              { invoiceType: this.invoice.Type, SupplierID: this.selectedSupplier.CompanyID }
            ).update();
            this.reset();
          });
        }, err => {
          if (err.status === 400) {
            if (err.error.ModelState['']) {
              this.snackBar.open(`Could not save the ${this.documentTypeReadable}! ${err.error.ModelState['']}`);
            } else if (err.error.ModelState.Value) {
              this.snackBar.open(`Could not save the ${this.documentTypeReadable}! ${err.error.ModelState.Value}`);
            } else {
              for (const entry of Object.entries(err.error.ModelState)) {
                this.form.controls[entry[0]].setErrors({ 'serverErrors': entry[1] });
              }
            }
          } else {
            this.snackBar.open(`Could not save the ${this.documentTypeReadable}! ${err.error.Message}`);
          }
        });
      }
    });
  }

  public split(row: PurchaseInvoiceLine) {
    this.dialog.open(SplitQuantityDialogComponent, {
      data: {
        currentQuantity: row.Product.Quantity
      }
    }).afterClosed().subscribe(newQty => {
      if (newQty) {
        this.dataProvider.splitLine(row.LineID, newQty).subscribe(newLine => {
          row.Product.Quantity = newQty;
          this.orderLines.push(newLine);
          this.snackBar.open('Order line has been successfully split.');
        });
      }
    });
  }

  public setExchangeRate() {
    this.dialog.open(SetExchangeRateDialogComponent, { data: { supplierCurrency: this.selectedSupplier.InvoiceCurrency } })
      .afterClosed().subscribe(newExchangeRate => {
        if (newExchangeRate !== '') {
          // TODO: should check for undefined, not for empty string,
          // after material bug is fixed https://github.com/angular/material2/issues/9020
          this.invoice.ExchangeRate = newExchangeRate;
          this.foreignCurrency = true;
        }
      });
  }

  public get today() {
    return new Date();
  }

  public isInvoiceNumberDuplicate: AsyncValidatorFn = (control: AbstractControl) => {
    let obs: Observable<null | any>;
    const invoiceNumber = control.value;

    if (!invoiceNumber) {
      obs = of(null);
    } else if (!this.selectedSupplier) {
      obs = of(null);
    } else {
      obs = this.dataProvider.checkInvoiceNumber({
        CompanyID: this.selectedSupplier.CompanyID,
        InvoiceNumber: invoiceNumber,
        InvoiceType: this.invoiceType$.getValue()
      }).pipe(
        map(_ => {
          return null;
        }),
        catchError(err => {
          if (err.status === 400) {
            return of({ 'serverErrors': err.error.ModelState.InvoiceNumber });
          } else {
            return observableThrowError(err);
          }
        })
      );
    }

    return obs.toPromise();
  }

  public grandTotalCorrect: ValidatorFn = (control: AbstractControl) => {
    const expectedTotal = this.invoice.Lines.map(line => line.Cost).reduce((total, line) => total + line);
    const valid = control.value === expectedTotal;
    // TODO: Uncomment after server errors are handled correctly
    // return !valid ? { 'invoiceNumberDuplicate': { value: control.value } } : null;
    return null;
  }

  public searchProduct() {
    this.dialog.open(FindOrderComponent, {
      data: {
        // currentQuantity: row.Product.Quantity
      }
    }).afterClosed().subscribe((res: PurchaseOrderDetail) => {
      if (res) {
        if (res.PurchaseOrder.SupplierID !== this.selectedSupplier.CompanyID) {
          // If the supplier is different and at least one line is selected, can we throw a warning.
          if (this.invoice.Lines.length > 0) {
            this.dialog.open(ConfirmDialogComponent, {
              data: {
                text: 'You are trying to change supplier.  This will reset the form and discard any work done. Do you wish to proceed?'
              }
            }).afterClosed().subscribe(confirmed => {
              // If yes
              if (confirmed === true) {
                this.selectedSupplier = this.suppliers.find(supplier => supplier.CompanyID === res.Supplier.SupplierID);
                this.selectedPurchaseOrder = res.PurchaseOrder; // This might need to be moved into setTimeout();
              }
            });
          } else {
            this.selectedSupplier = this.suppliers.find(supplier => supplier.CompanyID === res.Supplier.SupplierID);
            this.selectedPurchaseOrder = res.PurchaseOrder; // This might need to be moved into setTimeout();
          }
        } else {
          this.selectedPurchaseOrder = res.PurchaseOrder;
        }
      }
    });
  }

  public supplierCompareFn(a: PurchaseInvoiceSupplier, b: PurchaseInvoiceSupplier) {
    return a && b ? a.CompanyID === b.CompanyID : a === b;
  }

  public orderCompareFn(a: PurchaseOrder, b: PurchaseOrder) {
    return a && b ? a.PurchaseOrderID === b.PurchaseOrderID : a === b;
  }

  get currencyCode() {
    if (this.foreignCurrency || !this.selectedSupplier) {
      return undefined;
    }

    // If "All suppliers selected", take the supplier of the currently selected order
    if (this.selectedSupplier.CompanyID === 0) {
      if (this.selectedPurchaseOrder === undefined) {
        return undefined;
      }
      const supplier = this.suppliers.find(sup => sup.CompanyID === this.selectedPurchaseOrder.SupplierID);
      if (supplier === undefined) {
        return undefined;
      }

      return supplier.InvoiceCurrency.Code;
    }

    return this.selectedSupplier.InvoiceCurrency.Code;
  }

  calculateDiscountedUkVat() {
    const lineCostSum = this.invoice.Lines.map(l => l.Cost).reduce((acc, current) => acc + current, 0);
    if (lineCostSum === 0) {
      return 0;
    }
    return round(this.calculateVatTotal() * (1 - (this.invoice.Discount / lineCostSum)));
  }

  private saveSuccessfulDialog(saveDetails: PurchaseInvoiceSaveDetails) {
    return this.dialog.open(SavedSuccessfullyComponent, {
      data: {
        saveDetails: saveDetails
      },
      disableClose: true
    }).afterClosed();
  }
}
