<cdk-virtual-scroll-viewport autosize class="scrollviewport" itemSize="50">
  <div class="row" *cdkVirtualFor="let note of notesContents; let i = index">
    <div class="displayedOn">
      <mat-button-toggle-group name="displayedOn" aria-label="Displayed On" [value]="selectedValue[i]" multiple disabled>
        <mat-button-toggle value="goodsIn-{{i}}">GI</mat-button-toggle>
        <mat-button-toggle value="goodsOut-{{i}}">GO</mat-button-toggle>
        <mat-button-toggle value="public-{{i}}">W</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="title">
      [{{note.date | date: "dd/MM/yyyy HH:mm"}}] - {{note.user.fullName}}
    </div>
    <div class="message">
      {{note.message}}
    </div>
  </div>  
</cdk-virtual-scroll-viewport>
