import { Component, OnInit } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { UnsubscribeOnDestroy } from 'app/shared/decorators/unsubscribe-on-destroy';
import { CrawlerStatusProvider } from 'app/server-data-providers/crawler-status.provider';
import { CrawlerStatusEventLogItem } from '@equipmyschool/emsadminweb-models';

@Component({
  selector: 'app-event-log',
  templateUrl: './event-log.component.html',
  styleUrls: ['./event-log.component.scss']
})
export class EventLogComponent implements OnInit {
  public eventLog$: Observable<CrawlerStatusEventLogItem[]> = this.crawlerStatusProvider.eventLog.forParams({});
  displayedColumns = ['DateTime', 'Type', 'Message'];

  @UnsubscribeOnDestroy()
  private subscriptions: Subscription[] = [];

  constructor(private crawlerStatusProvider: CrawlerStatusProvider) { }

  ngOnInit() {
  }

  refreshData() {
    this.crawlerStatusProvider.eventLog.forParams({}).update();
  }
}
