<ng-container *ngIf="!(isClosing$ | async)">
<h1 mat-dialog-title>{{data.data.Company.Name}} ({{data.data.Company.AccountNo}})</h1>
<div mat-dialog-content>
  <div>
    Country:
    <ng-container *ngIf="qbObject === 'PurchaseInvoices'">
      <mat-form-field>
        <mat-select [(ngModel)]="country" [compareWith]="countryServer.compareCountries">
          <mat-option *ngFor="let c of countries" [value]="c">
            {{c.Name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-raised-button color="primary" *ngIf="country.CountryID !== data.data.Company.Country.CountryID" (click)="saveCountry()">Save</button>
    </ng-container> 
    <ng-container *ngIf="qbObject !== 'PurchaseInvoices'">
      {{data.data.Company.Country.Name}}
    </ng-container>
  </div>
  <div *ngIf="qbObject === 'PurchaseInvoices' && country.ChargeVAT">
    VAT Number:
    <ng-container *ngIf="!data.data.Company.VATNumber">
      <div class="vat-panel">
        <mat-form-field>
          <input matInput [(ngModel)]="vatNumber">
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="saveVATNumber()">Save</button>
        <button mat-raised-button color="primary" (click)="notVATRegistered()">Not VAT Registered</button>
      </div>
    </ng-container>
    <ng-container *ngIf="data.data.Company.Country.ChargeVAT === true && data.data.Company.VATNumber">
      {{data.data.Company.VATNumber}}
    </ng-container>
  </div>

  <h1 mat-dialog-title class="subtitle">Invoices</h1>
  <table mat-table [dataSource]="popupSource" matSort multiTemplateDataRows>
    <ng-container cdkColumnDef="DocumentNumber">
      <th mat-header-cell *cdkHeaderCellDef mat-sort-header>{{qbObject === 'Payments' ? 'Description' : 'Invoice Number'}}</th>
      <td mat-cell *cdkCellDef="let row">
        {{row.DocumentNumber}}
      </td>
    </ng-container>
    
    <ng-container cdkColumnDef="Date">
      <th mat-header-cell *cdkHeaderCellDef mat-sort-header>Date</th>
      <td mat-cell *cdkCellDef="let row">
        {{row.Date | date: 'd MMM yyyy'}}
      </td>
    </ng-container>
    
    <ng-container cdkColumnDef="Goods">
      <th mat-header-cell *cdkHeaderCellDef>{{qbObject === 'Payments' ? 'Amount' : 'Goods'}}</th>
      <td mat-cell *cdkCellDef="let row">
        {{row.Goods | currency: row.Currency.Code}}
      </td>
    </ng-container>
    
    <ng-container cdkColumnDef="Freight">
      <th mat-header-cell *cdkHeaderCellDef>Freight</th>
      <td mat-cell *cdkCellDef="let row">
        {{row.Freight | currency: row.Currency.Code}}
      </td>
    </ng-container>
    
    <ng-container cdkColumnDef="Servicing">
      <th mat-header-cell *cdkHeaderCellDef>Servicing</th>
      <td mat-cell *cdkCellDef="let row">
        {{row.Servicing | currency: row.Currency.Code}}
      </td>
    </ng-container>
    
    <ng-container cdkColumnDef="Handling">
      <th mat-header-cell *cdkHeaderCellDef>Handling</th>
      <td mat-cell *cdkCellDef="let row">
        {{row.Handling | currency: row.Currency.Code}}
      </td>
    </ng-container>
    
    <ng-container cdkColumnDef="Tax">
      <th mat-header-cell *cdkHeaderCellDef>Tax</th>
      <td mat-cell *cdkCellDef="let row">
        {{row.Tax | currency: row.Currency.Code}}
      </td>
    </ng-container>
    
    <ng-container cdkColumnDef="Total">
      <th mat-header-cell *cdkHeaderCellDef>Total</th>
      <td mat-cell *cdkCellDef="let row">
        {{row.Total | currency: row.Currency.Code}}
      </td>
    </ng-container>
    
    <ng-container cdkColumnDef="Added">
      <th mat-header-cell *cdkHeaderCellDef>Added</th>
      <td mat-cell *cdkCellDef="let row">
        {{row.Added | date: 'd MMM yyyy'}}
      </td>
    </ng-container>
    
    <ng-container cdkColumnDef="AddedBy">
      <th mat-header-cell *cdkHeaderCellDef>Added By</th>
      <td mat-cell *cdkCellDef="let row">
        {{row.AddedBy}}
      </td>
    </ng-container>

    <ng-container cdkColumnDef="Actions">
      <th mat-header-cell *cdkHeaderCellDef>Actions</th>
      <td mat-cell *cdkCellDef="let row" [attr.rowspan]="hasErrors(row) ? 2 : 1">
        <button mat-icon-button [attr.invoice-number]="row.DocumentNumber" [attr.is-credit]="row.Total < 0" onclick="showInvoice(this, event)">
          <mat-icon>visibility</mat-icon>
        </button>
        <button mat-icon-button (click)="tryEdit(row)">
          <mat-icon>edit</mat-icon>
        </button>
        <button *ngIf="row.ID > 0" mat-icon-button (click)="tryDelete(row)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>
    
    <ng-container cdkColumnDef="Errors">
      <td mat-cell *cdkCellDef="let row" [attr.colspan]="popupColumns.length - 1">
        <mat-error *ngIf="hasErrors(row)">
          <div *ngFor="let error of getErrors(row)">{{error}}</div>
        </mat-error>
      </td>
    </ng-container>

    <tr mat-header-row *cdkHeaderRowDef="popupColumns"></tr>
    <tr mat-row *cdkRowDef="let row; columns: popupColumns;" [ngClass]="hasErrors(row) ? 'no-border' : ''"></tr>
    <tr mat-row *cdkRowDef="let row; columns: ['Errors'];" [ngClass]="hasErrors(row) ? 'error-row' : 'hidden'"></tr>
  </table>
</div>
</ng-container>