import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ADMIN_API_SERVER_URL } from '../tokens/admin-api-server-url.token';
import { ServerBaseService } from '../server-base.service';
import { PurchasingQuery } from '@equipmyschool/emsadminweb-models';

@Injectable()
export class PurchasingQueryService extends ServerBaseService {
  constructor(http: HttpClient, @Inject(ADMIN_API_SERVER_URL) serverUrl: string) {
    super(http, serverUrl);
  }

  Get = () => {
    return this.request<PurchasingQuery[]>(
      'GET',
      'PurchasingQuery/Get'
    );
  }
}
