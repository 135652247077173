<h2>Products</h2>

<div class="table-container mat-elevation-z8">

  <mat-table #table [dataSource]="dataSource" matSort>

    <ng-container cdkColumnDef="Name">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Supplier </mat-header-cell>
      <mat-cell *cdkCellDef="let row"> {{row.Supplier.Name}} </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="Crawled">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Crawled </mat-header-cell>
      <mat-cell *cdkCellDef="let row"> {{row.Crawled}} </mat-cell>
    </ng-container>


    <ng-container cdkColumnDef="Expired">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Expired </mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <a href="javascript:void(0)" (click)="getFile(row.Supplier, 'MissingPricing')" matTooltip="{{row.Expired}} of {{row.Crawled}}">
          {{row.Expired / row.Crawled | percent}}
        </a>
      </mat-cell>
    </ng-container>
    <ng-container cdkColumnDef="NotMatched">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Not Matched </mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <a href="javascript:void(0)" (click)="getFile(row.Supplier, 'NotMatched')" matTooltip="{{row.NotMatched}} of {{row.Crawled}}">
          {{row.NotMatched / row.Crawled | percent}}
        </a>
      </mat-cell>
    </ng-container>
    <ng-container cdkColumnDef="NotCrawled">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Not Crawled </mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <a href="javascript:void(0)" (click)="getFile(row.Supplier, 'MissingFromWebsite')">
          {{row.NotCrawled}}
        </a>
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="LastImported">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>Last Imported</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{ row.LastImported }}
      </mat-cell>
    </ng-container>

    <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
