import { HttpClient } from '@angular/common/http';
import { Injectable, Inject, InjectionToken } from '@angular/core';
import { AdminForDetails } from '@equipmyschool/emsadminweb-models';
import { ServerBaseService } from '../server-base.service';
import { EMS_API_SERVER_URL } from '../tokens/ems-api-server-url.token';

@Injectable()
export class EmsService extends ServerBaseService {
  constructor(http: HttpClient, @Inject(EMS_API_SERVER_URL) serverUrl: string) {
    super(http, serverUrl);
  }
  public administerFor(adminForDetails: AdminForDetails) {
    return this.request(
      'POST',
      'Account/AdministerFor',
      null,
      adminForDetails
    );
  }
}

