import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'datepicker-inline-calendar-example',
  template: ''
})
export class DatepickerInlineCalendarComponent {
  index = 0;
  disabled = false;
  @Input() selectedValue: Date | undefined;
  @Input() originalValue: Date | undefined;
  @Output() selectedValueChange = new EventEmitter<Date>();
  
  selectedChange(m: any, index: any, disabled) {
    if (disabled) {
      this.selectedValue = this.originalValue
      this.selectedValueChange.emit(this.selectedValue);
      return;
    }
    this.index = index
    this.selectedValue = new Date(m);
    this.selectedValueChange.emit(this.selectedValue);
  }
}