import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'app/material.module';
import { SimpleDecimalPipe } from 'app/shared/pipes/simpleDecimal';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AsyncIndicatorService } from './async-indicator.service';
import { CompanyTypeIconComponent } from './company-type-icon/company-type-icon.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { CountryIconComponent } from './country-icon/country-icon.component';
import { DataSourcePipe } from './data-source.pipe';
import { DateConvertInterceptor } from './date-convert.interceptor';
import { DecimalInputOnBlurDirective } from './decimal-input-on-blur.directive';
import { ChangeColorDirective } from './directives/change-color.directive';
import { IconCheckboxComponent } from './icon-checkbox/icon-checkbox.component';
import { InsteadOfCheckboxModelChangeDirective } from './instead-of-checkbox-model-change/instead-of-checkbox-model-change.directive';
import { CamelCaseToWordsPipe } from './pipes/camel-case-to-words.pipe';
import { EnumKeysPipe } from './pipes/enum-keys.pipe';
import { ObjectKeysPipe } from './pipes/object-keys.pipe';
import { ProgressInterceptor } from './progress.interceptor';
import { IconSidenavDirective } from './sidenav/icon-sidenav.directive';
import { MediaReplayService } from './sidenav/mediareplay/media-replay.service';
import { SidenavItemComponent } from './sidenav/sidenav-item/sidenav-item.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { SidenavService } from './sidenav/sidenav.service';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { UserCardComponent } from './user-card/user-card.component';
import { AsyncValidatorsDirective } from './validators/async-validators.directive';
import { EqualsValidatorDirective } from './validators/equals.validator';
import { ForbiddenValuesValidatorDirective } from './validators/forbidden-values.validator';
import { MaxValidatorDirective } from './validators/max.validator';
import { MinValidatorDirective } from './validators/min.validator';
import { ValidatorsDirective } from './validators/validators.directive';
import { SelectionDialogComponent } from './selection-dialog/selection-dialog.component';


const forExport = [
  UserCardComponent,
  InsteadOfCheckboxModelChangeDirective,
  ConfirmDialogComponent,
  SelectionDialogComponent,
  SidenavComponent,
  SidenavItemComponent,
  IconSidenavDirective,
  IconCheckboxComponent,
  EnumKeysPipe,
  ObjectKeysPipe,
  CamelCaseToWordsPipe,
  SimpleDecimalPipe,
  MinValidatorDirective,
  MaxValidatorDirective,
  ForbiddenValuesValidatorDirective,
  EqualsValidatorDirective,
  ValidatorsDirective,
  AsyncValidatorsDirective,
  DataSourcePipe,
  DecimalInputOnBlurDirective,
  UserAvatarComponent,
  ToolbarComponent,
  CountryIconComponent,
  CompanyTypeIconComponent,
  ChangeColorDirective
];

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    PerfectScrollbarModule,
    RouterModule,
    MaterialModule
  ],
  declarations: [
    ...forExport
  ],
  entryComponents: [
    ConfirmDialogComponent
  ],
  exports: forExport,
  providers: [
    SidenavService,
    MediaReplayService,
    AsyncIndicatorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DateConvertInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProgressInterceptor,
      multi: true
    }
  ]
})
export class SharedModule { }
