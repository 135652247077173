import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import { Country, QuickBooksErrorDocument, QuickBooksExportDetails, QuickBooksExportDocument } from '@equipmyschool/emsadminweb-models';
import { SelectionDialogComponent, SelectionOption } from 'app/shared/selection-dialog/selection-dialog.component';
import { CountryService, PurchaseInvoiceService, SupplierService } from 'app/server/server.module';
import { PurchaseInvoiceProductsComponent } from '../purchase-invoice-products/purchase-invoice-products.component';

@Component({
  selector: 'app-export-details',
  templateUrl: './export-details.component.html',
  styleUrls: ['./export-details.component.scss']
})
export class ExportDetailsComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription[] = [];

  popupColumns: string[]
  piColumns = ['DocumentNumber', 'Date', 'Goods', 'Freight', 'Tax', 'Total', 'Added', 'AddedBy', 'Actions'];
  siColumns = ['DocumentNumber', 'Date', 'Goods', 'Freight', 'Servicing', 'Handling', 'Tax', 'Total', 'Added', 'AddedBy'];
  payColumns = ['DocumentNumber', 'Date', 'Goods']
  qbObject: string;
  vatNumber: string;
  country: Country;
  popupSource = new MatTableDataSource<QuickBooksExportDocument>([]);
  countries: Country[];
  isClosing$: Observable<boolean>;

  constructor(
    private purchaseInvoiceServer: PurchaseInvoiceService,
    public countryServer: CountryService,
    private supplierServer: SupplierService,
    public dialogRef: MatDialogRef<ExportDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ExportDetailsData,
    public dialog: MatDialog
  ) {
    this.isClosing$ = this.dialogRef.beforeClosed().pipe(mapTo(true));
   }

  ngOnInit() {
    this.qbObject = this.data.data.Type;
    this.popupSource.data = this.data.data.Documents;
    this.vatNumber = this.data.data.Company.VATNumber;
    this.country = this.data.data.Company.Country;

    if (this.qbObject === "PurchaseInvoices") {
      this.popupColumns = this.piColumns;
    }
    else if (this.qbObject === "Payments") {
      this.popupColumns = this.payColumns;
    }
    else {
      this.popupColumns = this.siColumns;
    }
    
    this._subscriptions.push(
      this.countryServer.countries$
        .pipe(
          map((response) => {
            if (response) {
              this.countries = response;
            }
          })
        )
        .subscribe()
    );

    this.countryServer.getCountries()
  }
  
  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  hasErrors(row: QuickBooksExportDocument) {
    // See if the row's document has an entry in the errors field
    const errDoc = this.data.errors?.find(eD => eD.DocumentID === row.ID);
    // If so, check the number of errors
    return errDoc && errDoc.Errors.length > 0
  }

  getErrors(row: QuickBooksExportDocument) {
    // See if the row's document has an entry in the errors field
    const errDoc = this.data.errors?.find(eD => eD.DocumentID === row.ID);
    // If so, return the errors
    return errDoc.Errors
  }

  saveCountry() {
    this.data.data.Company.Country = this.country;
    this.supplierServer.save(this.data.data.Company);
  }

  saveVATNumber() {
    this.data.data.Company.Country = this.country;
    this.data.data.Company.VATNumber = this.vatNumber;
    this.supplierServer.save(this.data.data.Company);
  }

  notVATRegistered() {
    this.vatNumber = 'NOT VAT REGISTERED';
    this.saveVATNumber();
  }

  tryEdit(row: QuickBooksExportDocument) {
    if (this.qbObject === 'PurchaseInvoices') {
      this.dialog.open(PurchaseInvoiceProductsComponent, {data: row, width: "100%"});
    }
  }

  tryDelete(row: QuickBooksExportDocument) {
    if (this.qbObject === 'PurchaseInvoices') {
      var reasons = [
        { name: "Invoice is actually a ProForma" },
        { name: "Incorrect Document Posted" },
        { name: "Incorrect Document Number" },
        { name: "Missing Goods" },
        { name: "Incorrect Goods Value" },
        { name: "Incorrect Tax Value" },
        { name: "Missing Tax Value" },
        { name: "Incorrect Freight Value" },
        { name: "Incorrect Discount" },
        { name: "Incorrect Goods included" },
        { name: "Incorrect Invoice Date" },
        { name: "Incorrect Supplier" },
        { name: "Incorrect Currency" },
        { name: "Duplicated" },
        { name: "Re-raised/replaced by Supplier" }
      ];

      const dialogRef = this.dialog.open(SelectionDialogComponent, {data: { title: `Please advise why this PI needs to be deleted?`, options: reasons}});
      dialogRef.afterClosed().subscribe((result: SelectionOption) => {
        if (result) {
          this.purchaseInvoiceServer.delete(Number(row.ID), result.name).subscribe(() => {
            var index = this.popupSource.data.indexOf(row);
            if (index > -1) {
              this.popupSource.data.splice(index, 1);
              this.popupSource._updateChangeSubscription();
            }
          });
        }
      });
    }
  }
}

export interface ExportDetailsData {
  data: QuickBooksExportDetails
  errors: QuickBooksErrorDocument[]
}
