<mat-card class="container-card">
  <mat-card-title>
    Associate the user with a contact
  </mat-card-title>

  <app-user-card *ngIf="user" [user]="user"></app-user-card>

  <div class="search-header">
    <mat-form-field floatPlaceholder="never">
      <input matInput placeholder="Filter contacts" [(ngModel)]="filter" (ngModelChange)="updateFilter(false)" (keyup.enter)="updateFilter(true)">
    </mat-form-field>
  </div>

  <mat-card class="list-card">
    <button mat-fab type="button" color="primary" matTooltip="Create new contact for the user" (click)="createContact()" class="add">
      <mat-icon>add</mat-icon>
    </button>

    <mat-nav-list>
      <perfect-scrollbar>
        <a mat-list-item *ngFor="let contact of contacts" (click)="contactSelected(contact)">
          <div fxLayout fxLayoutAlign="space-between center" style="width: 100%;">
            <div fxFlex="30%">
              <h3>{{ contact.FirstName }} {{contact.LastName}}</h3>
            </div>
            <div fxFlex="45%">
              <div fxLayout fxLayoutAlign="left center" *ngIf="contact.Company">
                <ng-container [ngSwitch]="contact.Company.Type">
                  <mat-icon matListIcon *ngSwitchCase="CompanyType.Customer" matTooltip="Customer"> credit_card </mat-icon>
                  <mat-icon matListIcon *ngSwitchCase="CompanyType.Supplier" matTooltip="Supplier"> local_shipping </mat-icon>
                </ng-container>
                <app-country-icon [country]="contact.Company?.Country" style="margin-right: 5px"></app-country-icon>
                {{ contact.Company?.Name }}
                <ng-container *ngIf="contact.JobTitle && contact.Company"> - </ng-container>
                <em>{{ contact.JobTitle }}</em>
              </div>
            </div>
            <div fxFlex="20%" class="mat-caption">
              {{ contact.Email }}
            </div>
            <div fxFlex="5%" fxLayout>
              <mat-icon fxLayoutAlign="center center" *ngIf="contact.HasAspNetUserID" matTooltip="Already has an Asp.Net User associated">account_circle</mat-icon>
            </div>
          </div>
        </a>
      </perfect-scrollbar>
    </mat-nav-list>
  </mat-card>
</mat-card>
