import { HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';

export function saveFile(res: HttpResponse<Blob>) {
  saveAs(res.body, getDownloadedFileName(res.headers.get('content-disposition')));
}

export function getDownloadedFileName(contentDisposition: string) {
  return (contentDisposition.split(';')[1].trim().split('=')[1]).replace(/"/g, '');
}
