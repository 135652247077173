<label [attr.for]="inputId" class="mat-checkbox-layout" #label>
  <div class="mat-checkbox-inner-container"
       [class.mat-checkbox-inner-container-no-side-margin]="!checkboxLabel.textContent.trim()">
    <input #input
           class="mat-checkbox-input cdk-visually-hidden" type="checkbox"
           [id]="inputId"
           [required]="required"
           [checked]="checked"
           [value]="value"
           [disabled]="disabled"
           [name]="name"
           [tabIndex]="tabIndex"
           [indeterminate]="indeterminate"
           [attr.aria-label]="ariaLabel"
           [attr.aria-labelledby]="ariaLabelledby"
           (change)="_onInteractionEvent($event)"
           (click)="_onInputClick($event)">
    <div mat-ripple class="mat-checkbox-ripple"
         [matRippleTrigger]="label"
         [matRippleDisabled]="_isRippleDisabled()"
         [matRippleCentered]="true"></div>
    <div class="mat-checkbox-icon-container">
      <mat-icon class="icon-checkbox-icon">{{ icon }}</mat-icon>
      <!-- Element for rendering the indeterminate state checkbox. -->
      <div class="mat-checkbox-mixedmark"></div>
    </div>
  </div>
  <span class="mat-checkbox-label" #checkboxLabel (cdkObserveContent)="_onLabelTextChange()">
    <!-- Add an invisible span so JAWS can read the label -->
    <span style="display:none">&nbsp;</span>
    <ng-content></ng-content>
  </span>
</label>
