import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'simpleDecimal'
})
export class SimpleDecimalPipe extends DecimalPipe implements PipeTransform {
  transform(value: any, digitsInfo?: string, locale?: string): any {
    let result = super.transform(value, digitsInfo, locale);
    if (result && result.includes(',')) {
      result = result.replace(/,/g, '');
    }
    return result;
  }
}
