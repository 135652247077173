import { Pipe, PipeTransform } from '@angular/core';
import { Staff } from 'coadapi';
import { Observable } from 'rxjs';

@Pipe({ name: 'staffSearch' })
export class StaffFilterPipe implements PipeTransform {
  public transform(staff: Staff[], searchText: any): any {
    if ((searchText == null || searchText.length === 0) || staff == null) {
      return staff;
    }
    return staff.filter(hero => hero.firstName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);    
  }
}