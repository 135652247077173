export function UnsubscribeOnDestroy() {
  return function (target, propertyKey: string | symbol) {
    const original = target.ngOnDestroy;

    target.ngOnDestroy = function () {
      const propertyVal = this[propertyKey];
      if (Array.isArray(propertyVal)) {
        for (const item of propertyVal) {
          item.unsubscribe();
        }
      } else {
        propertyVal.unsubscribe();
      }

      if (original && typeof original === 'function') {
        original.apply(this, arguments);
      }
    };
  };
}
