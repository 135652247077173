import { Injectable } from '@angular/core';
import { ReplaySubject, Observable, Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { UnsubscribeOnDestroy } from 'app/shared/decorators/unsubscribe-on-destroy';

@Injectable()
export class MediaReplayService {
  private _isMobile$: ReplaySubject<boolean> = new ReplaySubject(1);

  @UnsubscribeOnDestroy()
  private subscriptions: Subscription[] = [];

  constructor(media: MediaObserver) {
    this.subscriptions.push(
      media.asObservable().subscribe(
        res => this._isMobile$.next(
          media.isActive(['xs','sm'])
        ), 
        err => this._isMobile$.error(err), 
        () => this._isMobile$.complete()
      )
    );
  }

  get isMobile$(): Observable<boolean> {
    return this._isMobile$.asObservable();
  }

}
