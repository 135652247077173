import { Component, OnInit, ViewChild } from '@angular/core';
import { ClientDetails, DdDispatchDetails, DdDispatchDetailsService,PtdClientService,PtdDispatchDetails,PtdDispatchService, DeadlineUpdate, SplitWarehouse, Warehouse } from 'coadapi';
import { Observable } from 'rxjs';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MatDialog } from '@angular/material/dialog';
import { NotesDialogComponent } from './pages/dialogs/notes-dialog/notes-dialog.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogCompleteService, DDActionService } from '../proforma-to-dispatch/abstracts/_datasharing';
import Utils from './abstracts/_utils';
import { startWith, switchMap } from 'rxjs/operators';
import { IwdDeadlineComponent } from '../dialogs/iwd-deadline/iwd-deadline.component';
import { CustomDateAdapter } from '../proforma-to-dispatch/abstracts/_date-adapter';

@Component({
  selector: 'app-dispatch-details',
  templateUrl: './dispatch-details.component.html',
  styleUrls: ['./dispatch-details.component.scss']
})
export class DispatchDetailsComponent implements OnInit {
  @ViewChild(CdkVirtualScrollViewport, { static: true })
  dispatchDetails: DdDispatchDetails[] = [];
  selectedDispatch: DdDispatchDetails; // selected dispatch
  ptdDispatchDetail: PtdDispatchDetails = {};
  randomPhrase = Utils.randomPhrase;
  lastSort = "";
  public dispatchDetails$: Observable<DdDispatchDetails[]>;
  orderVar: string;
  orderMod = false;
  orderDir: string;
  sortBy: string;
  
  constructor(
    private dispatchDetailsService: DdDispatchDetailsService,
    private clientService: PtdClientService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private dialogCompleteService: DialogCompleteService,
    private ptdDispatchService: PtdDispatchService,
    public ddActionService: DDActionService,
  ) {
    this.iconRegistry.addSvgIcon('edit-pen', this.sanitizer.bypassSecurityTrustResourceUrl('app/coad-web/proforma-to-dispatch/images/editsvg.svg'));
    this.iconRegistry.addSvgIcon('looking-glass', this.sanitizer.bypassSecurityTrustResourceUrl('app/coad-web/proforma-to-dispatch/images/contentssvg.svg'));
    this.iconRegistry.addSvgIcon('iwdr', this.sanitizer.bypassSecurityTrustResourceUrl('app/coad-web/proforma-to-dispatch/images/iwdrsvg.svg'));
  }
  

  sharingDispatchData(
    ddDispatch: DdDispatchDetails[],    
    index: number
  ) {
    this.selectedDispatch = ddDispatch[index];
    this.ptdDispatchService.getDispatch(this.selectedDispatch.dispatchId, ddDispatch[index].warehouseId).subscribe((ptdDispatch: PtdDispatchDetails) => {
      if (!ptdDispatch) {
        return;
      }      

      this.ptdDispatchDetail = ptdDispatch[0];

      if (this.ptdDispatchDetail.dispatchWarehouse.warehouseId !== this.ptdDispatchDetail.shippedToWarehouse.warehouseId) {
        this.openIWDDeadlineDialog(this.ptdDispatchDetail, this.ptdDispatchDetail.dispatchProformas);
      } else {
        let client: ClientDetails;
        this.clientService.getOneClient(this.ptdDispatchDetail.client).subscribe((res) => {
        client = res;
        this.router.navigate(['dispatch'], {
          relativeTo: this.route,
          skipLocationChange: false,
          state: {
            ptdDispatch: this.ptdDispatchDetail,
            clientDetails: client
          }
          });
        }); 
      }
    });
  }
  
  /**
   * Opens the content dialog.
   *
   * @param {number} destination - The destination value.
   * @param {number} dispatchId - The dispatch ID value.
   * @param {number} tabIndex - The tab index value.
   * @return {void} This function does not return a value.
   */
  openContentDialog(destination: number, dispatchId: number, tabIndex: number): void {
    this.router.navigate(['contents'], {
      relativeTo: this.route,
      skipLocationChange: true,
      queryParams: {
        destination: destination,
        dispatchId: dispatchId,
        tabIndex: tabIndex
      }
    });
  }

  openIwdContentDialog(dispatchId: number): void {
    this.router.navigate(['iwd-contents'], {
      relativeTo: this.route,
      skipLocationChange: true,
      queryParams: {
        dispatchId: dispatchId
      }
    });
  }

  ngOnInit(): void {
    this.ddActionService.ddAction$.pipe(
      startWith(''),
      switchMap(() => {
        return this.dispatchDetailsService.ddGetDispatchDetails()
      })
    ).subscribe((dispatchDetails) => {
      this.dispatchDetails = dispatchDetails;
    });
    // subscribe to the dialogcomplete subject to watch for the completed dialog close
    this.dialogCompleteService.dialogComplete$.subscribe({
      next: (result: boolean) => {
        if (result === true) {
          this.ddActionService.ddAction$.next();
        }
      }
    })
  }

  // ngAfterViewInit() {
  //   this.getData();
  // }

  onImgError(event) {
    event.target.src = this.orderDir + '.png';
  }

  sort(sort: string) {
    if (this.dispatchDetails) {
      this.sortBy = sort;
      if (this.sortBy === this.lastSort) {
        this.orderMod = !this.orderMod;
      } else {
        this.orderMod = false;
      }
      this.lastSort = sort;
      // this.orderMod = !this.orderMod;
      if (this.orderMod) {
        this.orderVar = this.sortBy;
        this.orderDir = 'up';
      } else {
        this.orderVar = '-' + this.sortBy;
        this.orderDir = 'down';
      }
      this.dispatchDetails = [...this.dispatchDetails.sort(
        (a, b) => a[this.orderVar] > b[this.orderVar] ? 1 : -1
      )];
    }
  }

  // getData() {
  //   this.dispatchDetailsService.ddGetDispatchDetails().subscribe((dispatchDetails) => {
  //     this.dispatchDetails = dispatchDetails;
  //   })
  // }

  openNotesDialog(id) {
    const notesRef = this.dialog.open(NotesDialogComponent, {
      id: "notes-dialog",
      width: "600px",
      disableClose: false,
      panelClass: "confirmation-pane",
      data: {
        tableName: "dispatch",
        id: id
      },
    });
  }

  openIWDDeadlineDialog(
    originDispatch: PtdDispatchDetails,
    iwds: Array<SplitWarehouse>
  ) {
    const distinctWarehouses = this.countIwdWarehouses(
      iwds,
      originDispatch.dispatchWarehouse.warehouseId
    );
    const iwdDeadlineRef = this.dialog.open(IwdDeadlineComponent, {
      id: "iwd-deadline-dialog",
      width: "600px",
      disableClose: false,
      panelClass: "confirmation-pane",
      data: {
        distinctWarehouses: distinctWarehouses,
        dispatch: originDispatch,
        warehouse: originDispatch.dispatchWarehouse.warehouseId,
      },
    });
    iwdDeadlineRef.afterClosed().subscribe((res) => {
      if (res) {
        res.forEach((element) => {
          if (element.index > 0){
            const utcEts = CustomDateAdapter.changeDateToFlatDate(element.selectedRangeValue.start)
            const utcEta = CustomDateAdapter.changeDateToFlatDate(element.selectedRangeValue.end)
            const iwdDeadline = <DeadlineUpdate>{
              dispatchId: originDispatch.dispatchId,
              warehouseId: element.index,
              ets: new Date(utcEts).toISOString(),
              eta: new Date(utcEta).toISOString(),
            };
            this.ptdDispatchService.setIWDDeadline(iwdDeadline).subscribe({
              next: (result: any) => {
                console.log(result);
                this.ddActionService.ddAction$.next();
              },
              complete: () => {
                console.log("update complete");
                return true;
              },
            });
          }
        });
      }
    });
  }

  countIwdWarehouses(
    iwds: Array<SplitWarehouse>,
    originWarehouse: number
  ): Array<Warehouse> {
    let distinctWarehouse: Array<Warehouse> = [];
    iwds.forEach((iwd) => {
      if (iwd.warehouse.warehouseId !== 5) {        
        if (
          !distinctWarehouse.find(
            (x) => x.warehouseId === iwd.warehouse.warehouseId
          )
        ) {
          if (iwd.warehouse.warehouseId === originWarehouse) {
            distinctWarehouse.splice(0, 0, iwd.warehouse);
          } else {
            distinctWarehouse.push(iwd.warehouse);
          }
        }
      }
    });
    return distinctWarehouse;
  }
}
