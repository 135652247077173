<mat-toolbar class="mat-elevation-z4">
  <span class="row-1">
    <h1>Dispatch Details</h1>
  </span>
  <!-- <div class="download-summary" (click)="getReport()"><div class="text">Download Summary</div></div> -->
  <span class="row-2">
    <h2>{{randomPhrase}}</h2>
  </span>
</mat-toolbar>

<div class="workspace"> 
  <div class="header row">
    <span class="cell actions">
      <div class="noteCount" (click)="sort('noteCount')">Actions
        <img *ngIf="orderDir && sortBy === 'noteCount'"
          src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg" (error)="onImgError($event)" width="12px"
          alt="sort">
      </div>
    </span>
    <div class="cell schoolAcc" (click)="sort('schoolAcc')">Dispatch
      <img *ngIf="orderDir && sortBy === 'schoolAcc'"
        src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg" (error)="onImgError($event)" width="12px"
        alt="sort">
    </div>
    <span class="cell warehouses">
      <div class="cell shippedTo" (click)="sort('shippedTo')">Shipped To
        <img *ngIf="orderDir && sortBy === 'shippedTo'"
          src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg" (error)="onImgError($event)" width="12px"
          alt="sort">
      </div>
      <div class="cell leavingFrom" (click)="sort('leavingFrom')">Leaving From
        <img *ngIf="orderDir && sortBy === 'leavingFrom'"
          src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg" (error)="onImgError($event)" width="12px"
          alt="sort">
      </div>
    </span>
    <div class="cell deliveryAddress" (click)="sort('deliveryAddress')">Delivery Address
      <img *ngIf="orderDir && sortBy === 'deliveryAddress'"
        src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg" (error)="onImgError($event)" width="12px"
        alt="sort">
    </div>
    <div class="cell dispMethod" (click)="sort('dispMethod')">Dispatch Method
      <img *ngIf="orderDir && sortBy === 'dispMethod'"
        src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg" (error)="onImgError($event)" width="12px"
        alt="sort">
    </div>
    <div class="cell incoterm" (click)="sort('incoterm')">Incoterm
      <img *ngIf="orderDir && sortBy === 'incoterm'" src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg"
        (error)="onImgError($event)" width="12px" alt="sort">
    </div>
    <div class="cell reqBy" matTooltip="Required By" (click)="sort('reqBy')">Req By
      <img *ngIf="orderDir && sortBy === 'reqBy'" src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg"
        (error)="onImgError($event)" width="12px" alt="sort">
    </div>
    <div class="cell closureDate" (click)="sort('closureDate')">Closure Date
      <img *ngIf="orderDir && sortBy === 'closureDate'"
        src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg" (error)="onImgError($event)" width="12px"
        alt="sort">
    </div>
    <div class="cell etd" matTooltip="Estimated Time of Dispatch" (click)="sort('etd')">ETD
      <img *ngIf="orderDir && sortBy === 'etd'" src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg"
        (error)="onImgError($event)" width="12px" alt="sort">
    </div>
    <div class="cell eta" matTooltip="Estimated Time of Arrival" (click)="sort('eta')">ETA
      <img *ngIf="orderDir && sortBy === 'eta'" src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg"
        (error)="onImgError($event)" width="12px" alt="sort">
    </div>
    <div class="cell isClosed" matTooltip="Is Shipment Closed?" (click)="sort('isClosed')">Closed?
      <img *ngIf="orderDir && sortBy === 'isClosed'" src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg"
        (error)="onImgError($event)" width="12px" alt="sort">
    </div>
    <div class="cell closedForIWDs" (click)="sort('closedForIWDs')">Closed for IWDS?
      <img *ngIf="orderDir && sortBy === 'closedForIWDs'"
        src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg" (error)="onImgError($event)" width="12px"
        alt="sort">
    </div>
    <div class="cell lineCount" (click)="sort('lineCount')">Line Count
      <img *ngIf="orderDir && sortBy === 'lineCount'"
        src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg" (error)="onImgError($event)" width="12px"
        alt="sort">
    </div>
    <div class="cell percRec" matTooltip="Percent Received" (click)="sort('percRec')">Perc Rec
      <img *ngIf="orderDir && sortBy === 'percRec'" src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg"
        (error)="onImgError($event)" width="12px" alt="sort">
    </div>
    <div class="cell atPFI" matTooltip="At Proforma Raising Stage" (click)="sort('atPFI')">At PFI
      <img *ngIf="orderDir && sortBy === 'atPFI'" src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg"
        (error)="onImgError($event)" width="12px" alt="sort">
    </div>
    <div class="cell poc" matTooltip="On Purchase Order Control" (click)="sort('poc')">POC
      <img *ngIf="orderDir && sortBy === 'poc'" src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg"
        (error)="onImgError($event)" width="12px" alt="sort">
    </div>
    <div class="cell ltc" matTooltip="Lines To Come" (click)="sort('ltc')">LTC
      <img *ngIf="orderDir && sortBy === 'ltc'" src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg"
        (error)="onImgError($event)" width="12px" alt="sort">
    </div>
    <div class="cell intake" matTooltip="Potentially on Intake?" (click)="sort('intake')">Intake?
      <img *ngIf="orderDir && sortBy === 'intake'" src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg"
        (error)="onImgError($event)" width="12px" alt="sort">
    </div>
    <div class="cell ownr" matTooltip="Ordered With No Response" (click)="sort('ownr')">OWNR
      <img *ngIf="orderDir && sortBy === 'oewnr'" src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg"
        (error)="onImgError($event)" width="12px" alt="sort">
    </div>
    <div class="cell pq" matTooltip="In Purchase Queries" (click)="sort('pq')">PQ
      <img *ngIf="orderDir && sortBy === 'pq'" src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg"
        (error)="onImgError($event)" width="12px" alt="sort">
    </div>
    <div class="cell cq" matTooltip="In Client Queries" (click)="sort('cq')">CQ
      <img *ngIf="orderDir && sortBy === 'cq'" src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg"
        (error)="onImgError($event)" width="12px" alt="sort">
    </div>
    <div class="cell unavailable" matTooltip="Not Available" (click)="sort('unavailable')">NAV
      <img *ngIf="orderDir && sortBy === 'unavailable'"
        src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg" (error)="onImgError($event)" width="12px"
        alt="sort">
    </div>
    <div class="cell rbq" matTooltip="Review Before Quote" (click)="sort('rbq')">RBQ
      <img *ngIf="orderDir && sortBy === 'rbq'" src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg"
        (error)="onImgError($event)" width="12px" alt="sort">
    </div>
  </div>

  <cdk-virtual-scroll-viewport autosize class="scrollviewport" itemSize="50">
    <div class="dispatch-details">
      <div class="row" *cdkVirtualFor="let dispatchDetail of dispatchDetails | orderBy: [orderVar]; let i = index">
        <span class="cell actions">
          <button class="note" mat-icon-button aria-label="notes" [disabled]="dispatchDetail.noteCount === 0" (click)="openNotesDialog(dispatchDetail.dispatchId)">
            <span  matTooltip="Notes"  *ngIf="dispatchDetail.noteCount > 0">N</span>
          </button>
          <button matTooltip="Contents" *ngIf="dispatchDetail.shippedTo === dispatchDetail.leavingFrom" class="contents" mat-icon-button aria-label="contents" (click)="openContentDialog(3, dispatchDetail.dispatchId,0)">
            <mat-icon svgIcon="looking-glass"></mat-icon>
          </button>
          <button matTooltip="Contents" *ngIf="dispatchDetail.shippedTo !== dispatchDetail.leavingFrom" class="contents" mat-icon-button aria-label="contents" (click)="openIwdContentDialog(dispatchDetail.dispatchId)">
            <mat-icon svgIcon="looking-glass"></mat-icon>
          </button>
          <button matTooltip="Edit Dispatch" class="edit" mat-icon-button aria-label="edit dispatch" (click)="sharingDispatchData(dispatchDetails, i)">
            <mat-icon svgIcon="edit-pen"></mat-icon>
          </button>
        </span>
        <div class="cell schoolAcc">{{dispatchDetail.schoolAcc}}</div>
        <span class="cell warehouses">
          <div class="cell shippedTo">{{dispatchDetail.shippedTo}}</div>
          <div class="cell leavingFrom">{{dispatchDetail.leavingFrom}}</div>
          <mat-icon svgIcon="iwdr" *ngIf="dispatchDetail.method !== 70012 && dispatchDetail.shippedTo !== dispatchDetail.leavingFrom" matTooltip="IWD Required"></mat-icon>
        </span>
        <div class="cell deliveryAddress" matTooltip="{{dispatchDetail.deliveryAddressDetail}}">{{dispatchDetail.deliveryAddress}}</div>
        <div class="cell dispMethod">{{dispatchDetail.dispMethod}}</div>
        <div class="cell incoterm">{{dispatchDetail.incotermAbbreviation}}</div>
        <div class="cell reqBy">{{dispatchDetail.reqBy | date: "d MMM yyyy"}}</div>
        <div class="cell closureDate">{{dispatchDetail.closureDate | date: "d MMM yyyy"}}</div>
        <div class="cell etd">{{dispatchDetail.etd | date: "d MMM yyyy"}}</div>
        <div class="cell eta">{{dispatchDetail.eta | date: "d MMM yyyy"}}</div>
        <div class="cell isClosed"><mat-checkbox class="example-margin" [(ngModel)]="dispatchDetail.isClosed" disabled="true"></mat-checkbox></div>
        <div class="cell closedForIWDs"><mat-checkbox class="example-margin" [(ngModel)]="dispatchDetail.closedForIWDs" disabled="true"></mat-checkbox></div>
        <div class="cell lineCount">{{dispatchDetail.lineCount}}</div>
        <div class="cell percRec">{{dispatchDetail.percRec}}</div>
        <div class="cell atPFI">{{dispatchDetail.atPFI}}</div>
        <div class="cell poc underline" (click)="openContentDialog(3, dispatchDetail.dispatchId, 4)">{{dispatchDetail.poc ?? 0}}</div>
        <div class="cell ltc">{{dispatchDetail.ltc ?? 0}}</div>
        <div class="cell intake underline" (click)="openContentDialog(3, dispatchDetail.dispatchId, 2)">{{dispatchDetail.intake ?? 0}}</div>
        <div class="cell ownr underline" (click)="openContentDialog(3, dispatchDetail.dispatchId, 3)">{{dispatchDetail.ownr ?? 0}}</div>
        <div class="cell pq underline" (click)="openContentDialog(3, dispatchDetail.dispatchId, 5)">{{dispatchDetail.pq ?? 0}}</div>
        <div class="cell cq underline" (click)="openContentDialog(3, dispatchDetail.dispatchId, 1)">{{dispatchDetail.cq ?? 0}}</div>
        <div class="cell unavailable">{{dispatchDetail.unavailable ?? 0}}</div>
        <div class="cell rbq">{{dispatchDetail.rbq ?? 0}}</div>
      </div>
    </div>
  </cdk-virtual-scroll-viewport>
  <router-outlet></router-outlet>
</div>