import {distinctUntilChanged} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AsyncIndicatorService {
  private activeRequests: string[] = [];
  private _pendingRequestsExist$ = new BehaviorSubject<boolean>(false);

  public pendingRequestsExist$ = this._pendingRequestsExist$.pipe(distinctUntilChanged());

  constructor() { }

  public requestStarted(id: string) {
    this.activeRequests.push(id);
    this._pendingRequestsExist$.next(this.getPendingRequestsExist());
  }

  public requestFinished(id: string) {
    this.activeRequests.splice(this.activeRequests.indexOf(id), 1);
    this._pendingRequestsExist$.next(this.getPendingRequestsExist());
  }

  private getPendingRequestsExist() {
    return this.activeRequests.length > 0;
  }
}
