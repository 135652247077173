<div fxLayout="column" fxLayout.gt-md="row">
  <!-- <mat-card fxFlex.gt-md="300px" style="margin-right: 16px;">
    <mat-card-title>Category Pages</mat-card-title>
    <mat-card-content fxLayout="column">
      <p>Try the checkboxes and see what happens.</p>
      <mat-checkbox [(ngModel)]="tableHover">
        .table-hover
      </mat-checkbox>
      <mat-checkbox [(ngModel)]="tableStriped">
        .table-striped
      </mat-checkbox>
      <mat-checkbox [(ngModel)]="tableCondensed">
        .table-condensed
      </mat-checkbox>
      <mat-checkbox [(ngModel)]="tableBordered">
        .table-bordered
      </mat-checkbox>
    </mat-card-content>
  </mat-card> -->

  <div fxFlex class="mat-elevation-z2">

    <app-crawler-status-table [supplierRows]="categoryPages" (downloadSpreadsheet)="downloadSpreadsheet($event)"></app-crawler-status-table>

  </div>

</div>
