import { catchError, map } from 'rxjs/operators';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpResponse,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import * as moment from 'moment';

/**
 * The interceptor that:
 * converts all ms-date strings in the responses to moment objects
 * -and-
 * converts all moment objects in requests to ISO-8601-formatted strings
 */
@Injectable()
export class DateConvertInterceptor implements HttpInterceptor {
  public static RegexMsDate = new RegExp('^/Date\\((-?\\d+)\\)/$');

  public intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

    // Clone the request body so that it does not get modified
    const newReq = req.clone();
    this.convertDatesToDateStrings(newReq.body);

    return next.handle(newReq).pipe(map(res => {
      if (res instanceof HttpResponse) {
        const newRes = res.clone();
        this.convertDateStringsToDates(newRes);
        return newRes;
      } else {
        return res;
      }
    }),

    // Handle any dates in error responses
    catchError(err => {
      this.convertDateStringsToDates(err);
      return throwError(err);
    }));
  }

  public convertDateStringsToDates(input) {
    // Ignore things that aren't objects.
    if (typeof input !== 'object') {
      return input;
    }

    for (const key in input) {
      if (!input.hasOwnProperty(key)) {
        continue;
      }

      const value = input[key];
      let match;
      // Check for string properties which look like dates.
      if (typeof value === 'string' && (match = value.match(DateConvertInterceptor.RegexMsDate))) {
        input[key] = moment(new Date(parseInt(value.substr(6), 10)));
      } else if (typeof value === 'object') {
        // Recurse into object
        this.convertDateStringsToDates(value);
      }
    }
  }
  public convertDatesToDateStrings(input) {
    // Ignore things that aren't objects.
    if (typeof input !== 'object') {
      return input;
    }

    for (const key in input) {
      if (!input.hasOwnProperty(key)) {
        continue;
      }

      const value = input[key];

      if (moment.isMoment(value)) {
        input[key] = value.format();
      } else if (typeof value === 'object') {
        // Recurse into object
        this.convertDatesToDateStrings(value);
      }
    }
  }
}
