import { AbstractControl, NG_ASYNC_VALIDATORS, ValidationErrors, Validators, AsyncValidatorFn, AsyncValidator } from '@angular/forms';
import { Provider, forwardRef, OnChanges, Directive, SimpleChanges, Input } from '@angular/core';

export const ASYNC_VALIDATORS_VALIDATOR: Provider = {
  provide: NG_ASYNC_VALIDATORS,
  useExisting: forwardRef(() => AsyncValidatorsDirective),
  multi: true
};

/**
 * A directive which installs the {@link AsyncValidatorsDirective} for any `formControlName`,
 * `formControl`, or control with `ngModel` that also has a `equals` attribute.
 */
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[formControlName][appAsyncValidators],[formControl][appAsyncValidators],[ngModel][appAsyncValidators]',
  providers: [ASYNC_VALIDATORS_VALIDATOR],
})
export class AsyncValidatorsDirective<T> implements AsyncValidator, OnChanges {
  private _aggregateValidator: AsyncValidatorFn;
  private _onChange: () => void;

  @Input() appAsyncValidators: AsyncValidatorFn[] | AsyncValidatorFn;

  ngOnChanges(changes: SimpleChanges): void {
    if ('appAsyncValidators' in changes) {
      this._createValidator();
      if (this._onChange) {
        this._onChange();
      }
    }
  }

  validate(c: AbstractControl) {
    return this._aggregateValidator(c);
  }

  registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }

  private _createValidator(): void {
    this._aggregateValidator = Validators.composeAsync(
      Array.isArray(this.appAsyncValidators) ?
        this.appAsyncValidators :
        [this.appAsyncValidators]
    );
  }
}
