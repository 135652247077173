import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CrawlerStatusPluginsComponent } from './plugins/crawler-status-plugins.component';
import { CategoryPagesComponent } from './category-pages/category-pages.component';
import { ProductPagesComponent } from './product-pages/product-pages.component';
import { ProductsComponent } from './products/products.component';
import { EventLogComponent } from './event-log/event-log.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RoutingModule } from 'app/app-routing.module';
import { FormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SharedModule } from 'app/shared/shared.module';
import { CrawlerStatusTableComponent } from './helpers/crawler-status-table/crawler-status-table.component';
import { MaterialModule } from 'app/material.module';
import { ProjectTreeComponent } from './plugins/project-tree/project-tree.component';
import { ProjectTreeDialogComponent } from './plugins/project-tree-dialog/project-tree-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    RoutingModule,
    FormsModule,
    PerfectScrollbarModule,

    MaterialModule,

    SharedModule
  ],
  declarations: [
    CrawlerStatusPluginsComponent,
    CategoryPagesComponent,
    ProductPagesComponent,
    ProductsComponent,
    EventLogComponent,
    CrawlerStatusTableComponent,
    ProjectTreeComponent,
    ProjectTreeDialogComponent,
  ],
  entryComponents: [
    ProjectTreeDialogComponent
  ],
  exports: [
    CrawlerStatusPluginsComponent,
    CategoryPagesComponent,
    ProductPagesComponent,
    ProductsComponent,
    EventLogComponent,
  ]
})
export class CrawlerStatusModule { }
