import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, forwardRef } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Provider Expression that allows mat-checkbox to register as a ControlValueAccessor.
 * This allows it to support [(ngModel)].
 * @docs-private
 */
export const MAT_CHECKBOX_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line:no-use-before-declare
  useExisting: forwardRef(() => IconCheckboxComponent),
  multi: true
};

@Component({
  selector: 'app-icon-checkbox',
  templateUrl: './icon-checkbox.component.html',
  styleUrls: ['./icon-checkbox.component.scss'],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    'class': 'mat-checkbox app-icon-checkbox',
    '[class.mat-checkbox-indeterminate]': 'indeterminate',
    '[class.mat-checkbox-checked]': 'checked',
    '[class.mat-checkbox-label-before]': 'labelPosition == "before"',
    '[class.mat-checkbox-disabled]': 'disabled',
  },
  providers: [MAT_CHECKBOX_CONTROL_VALUE_ACCESSOR],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconCheckboxComponent extends MatCheckbox {
  @Input() icon: string;
}
