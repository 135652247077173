import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Commodity, PurchaseInvoiceLine, PurchaseInvoiceSupplier, TaxType } from '@equipmyschool/emsadminweb-models';
import * as serverEnums from 'app/server/server-enums';
import { round } from 'app/shared/round';
import { Observable } from 'rxjs';
import { SetEtaComponent } from '../set-eta/set-eta.component';

@Component({
  selector: 'app-purchase-order-lines-table',
  templateUrl: './purchase-order-lines-table.component.html',
  styleUrls: ['./purchase-order-lines-table.component.scss']
})
export class PurchaseOrderLinesTableComponent implements OnInit {
  static readonly UnitCostThreshold = 0.6;

  @Input() lines: Observable<PurchaseInvoiceLine[]>;
  @Input() isRowSelected: (row: PurchaseInvoiceLine) => boolean;
  @Input() commodities: Commodity[];
  @Input() editable = false;
  @Input() supplier: PurchaseInvoiceSupplier;
  @Output() lineClicked = new EventEmitter<PurchaseInvoiceLine>();
  @Output() split = new EventEmitter<PurchaseInvoiceLine>();

  public displayedColumns = [
    'Select',
    'Code',
    'Description',
    'Quantity',
    'UnitSalePrice',
    'ExpectedUnitCost',
    'UnitCost',
    'Availability',
    'LineCost',
    'Commodity',
    'LineTaxCost',
    'Rec',
    'ETA',
    // 'Status',
   // 'Buttons'
  ];

  serverEnums = serverEnums;
  constructor(private dialog: MatDialog) { }

  ngOnInit() {
  }

  commodityCompareFn(a: Commodity, b: Commodity) {
    return a && b ? a.CommodityID === b.CommodityID : a === b;
  }

  updateLineVat(row: PurchaseInvoiceLine) {
    const VATRate = this.supplier.TaxType === TaxType.UKVAT ? row.Product.Commodity.VATRate : 0;
    row.TaxCost = round(row.Cost * VATRate);
  }

  checkIfUnitCostTooHigh(row: PurchaseInvoiceLine) {
    return row.Cost / row.Product.Quantity > row.Product.ExpectedUnitCost + PurchaseOrderLinesTableComponent.UnitCostThreshold;
  }

  public setETA(description, LineID, changeColorDirective) {
      this.dialog.open(SetEtaComponent, {
        data: {
          description: description,
          lineID: LineID,
          changeColorDirective: changeColorDirective
        }
      });
  }
}
