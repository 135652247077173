import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { User } from '@equipmyschool/emsadminweb-models';
import { AccountService } from 'app/server/server.module';

@Injectable({
  providedIn: 'root'
})
export class CommentActionService {
  commentAction$ = new Subject();
}

  
@Injectable({
  providedIn: 'root'
})
export class ChsActionService {
  chsAction$ = new Subject();
}

@Injectable({
  providedIn: 'root',
})
export class sharedUserData {
  user: User;

  constructor(private server: AccountService) {}

  getUser(): User {
    this.server.iAmBehaviourSubject.subscribe((next) => {
      this.user = next;
    });
    return this.user;
  }
}