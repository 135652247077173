import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClientCountriesService } from 'app/server/server.module';
import { ClientCountries, Country } from '@equipmyschool/emsadminweb-models';

@Component({
  selector: 'app-client-countries',
  templateUrl: './client-countries.component.html',
  styleUrls: ['./client-countries.component.scss']
})
export class ClientCountriesComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription[] = [];
  
  displayedColumns = ['AccountNo', 'Name', 'SalesRep', 'Country', 'WarehouseCountries'];
  dataSource = new MatTableDataSource<ClientCountries>([]);
  supplierCountries: Country[];
  filter = "";

  constructor(
    private server: ClientCountriesService,
  ) {
  }

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this._subscriptions.push(
      this.server.clientCountries$
        .pipe(
          map((response) => {
            if (response) {
              this.dataSource.data = response;
              this.dataSource.sort = this.sort;
            }
          })
        )
        .subscribe()
    );

    this._subscriptions.push(
      this.server.countries$
        .pipe(
          map((response) => {
          if (response) {
            this.supplierCountries = response;
          }
        })
        )
        .subscribe()
    );

    this.server.getCountries();
    this.server.getClients();

    this.dataSource.filterPredicate = (data: ClientCountries, filter: string) => {
      return data.AccountNo.toLowerCase().includes(filter) || data.Country.Name.toLowerCase().includes(filter) || data.Country.Code.toLowerCase().includes(filter) || data.Name.toLowerCase().includes(filter) || data.SalesRep.toLowerCase().includes(filter);
    };
  }
  
  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  updateFilter() {
    if (!this.dataSource) { return; }
    this.dataSource.filter = this.filter.trim().toLowerCase();
  }

  toggleCountry(row: ClientCountries, country:Country) {
    if (row.WarehouseCountries.includes(country.CountryID))
      row.WarehouseCountries.splice(row.WarehouseCountries.indexOf(country.CountryID), 1);
    else
      row.WarehouseCountries.push(country.CountryID);

    this.server.updateClientCountries(row.CompanyID, row.WarehouseCountries);
  }
}
