
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyType, ExtendedContact, NetUser } from '@equipmyschool/emsadminweb-models';
import { AspNetUserService } from 'app/server/server.module';
import { ConfirmDialogComponent } from 'app/shared/confirm-dialog/confirm-dialog.component';
import { UnsubscribeOnDestroy } from 'app/shared/decorators/unsubscribe-on-destroy';
import { FilteredDataSource } from 'app/shared/filtered-data-source/filtered-data-source';
import { of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-associate-to-contact',
  templateUrl: './associate-to-contact.component.html',
  styleUrls: ['./associate-to-contact.component.scss']
})
export class AssociateToContactComponent implements OnInit {
  public filter = "";
  public user: NetUser;
  public contacts: ExtendedContact[];
  public displayedColumns = ['FullName', 'Company', 'JobTitle', 'Email', 'HasAspNetUserID'];
  dataSource: FilteredDataSource<ExtendedContact> = new FilteredDataSource(
    filterValue => {
      if (filterValue === '') {
        return of([] as ExtendedContact[]);
      }

      return this.server.getContacts(filterValue);
    }
  );

  public CompanyType = CompanyType;

  @UnsubscribeOnDestroy()
  private subscriptions: Subscription[] = [];

  constructor(
    private server: AspNetUserService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router) { }

  ngOnInit() {
    this.subscriptions.push(this.route.paramMap
      .pipe(switchMap(params => this.server.getUser(params.get('aspNetUserId'))))
        .subscribe(res => {
          this.user = res;
        })
    );

    this.subscriptions.push(this.dataSource._resultingObservable
      .pipe(
        map((result) => {
          this.contacts = result;
        })
      )
      .subscribe()
    );
  }

  updateFilter(force:  boolean) {
    if (!this.dataSource) { return; }
    this.dataSource.filter = this.filter;
    if (force) { this.dataSource.forceUpdate(); }
  }

  contactSelected(contact: ExtendedContact) {
    this.showConfirmationDialog(contact);
  }

  createContact() {
    this.subscriptions.push(
      this.server.CreateContact(this.user.AspNetUserID).subscribe(res => {
        this.snackBar.open('Successfully created a contact ' + res);
        this.router.navigate(['/epp-users/associate-to-company', this.user.AspNetUserID]);
      }, res => {
        this.snackBar.open('Could not create a contact: ' + res);
      })
    );
  }

  private showConfirmationDialog(contact: ExtendedContact) {
    const user = this.user;

    // Show modal asking whether the user really wants to associate the user with the company.
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        text: `Are you sure you want to associate user ${user.Contact.FirstName} ${user.Contact.LastName}`
          + ` with contact ${contact.FirstName} ${contact.LastName}?`
      }
    });
    this.subscriptions.push(
      dialogRef.afterClosed().subscribe(confirmed => {
        if (confirmed) {
          this.subscriptions.push(
            this.server.associateUserToContact(user.AspNetUserID, contact.ContactID).subscribe(_ => {
              this.snackBar.open(`User ${user.Contact.FirstName} ${user.Contact.LastName} is now associated with contact ${contact.FirstName} ${contact.LastName}`);
              this.router.navigate(['/epp-users/associate-to-company', user.AspNetUserID]);
            }, err => {
              this.snackBar.open(`Could not associate user ${user.Contact.FirstName} ${user.Contact.LastName} with contact ${contact.FirstName} ${contact.LastName}: ` + err);
            })
          );
        }
      }));
  }
}
