/*
 * Example use
 *		Basic Array of single type: *ngFor="#todo of todoService.todos | orderBy : '-'"
 *		Multidimensional Array Sort on single column: *ngFor="#todo of todoService.todos | orderBy : ['-status']"
 *		Multidimensional Array Sort on multiple columns: *ngFor="#todo of todoService.todos | orderBy : ['status', '-title']"
 */

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "orderBy", pure: false })
export class OrderByPipe implements PipeTransform {
  static _orderByComparator(a: any, b: any): number {
    if (a === null || typeof a === "undefined") a = "";
    if (b === null || typeof b === "undefined") b = "";
    if (a) {
      // Remove percentage sign from a if it exists
      a = typeof a === "string" ? a.replace(/%$/, "") : a;
    }

    if (b) {
      // Remove percentage sign from b if it exists
      b = typeof b === "string" ? b.replace(/%$/, "") : b;
    }

    if (
      (isNaN(parseFloat(a)) ||
        !isFinite(a) ||
        isNaN(parseFloat(b)) ||
        !isFinite(b)) &&
      (typeof a !== "boolean" || typeof b !== "boolean")
    ) {
      //Isn't a number so lowercase the string to properly compare
      if (a.toLowerCase() < b.toLowerCase()) return -1;
      if (a.toLowerCase() > b.toLowerCase()) return 1;
    } else if (typeof a === "boolean" && typeof b === "boolean") {
      //Comparing booleans is more tricky
      if (a === false && b === true) return -1;
      if (a === true && b === false) return 1;
    } else {
      //Parse strings as numbers to compare properly
      if (parseFloat(a.toString()) < parseFloat(b.toString())) return -1;
      if (parseFloat(a.toString()) > parseFloat(b.toString())) return 1;
    }

    return 0; //equal each other
  }

  transform(input: any, [config = "+"]): any {
    if (!Array.isArray(input)) return input;

    if (
      !Array.isArray(config) ||
      (Array.isArray(config) && config.length == 1)
    ) {
      var propertyToCheck: string = !Array.isArray(config) ? config : config[0];
      var desc = propertyToCheck.substr(0, 1) == "-";

      //Basic array
      if (
        !propertyToCheck ||
        propertyToCheck == "-" ||
        propertyToCheck == "+"
      ) {
        return !desc ? input.sort() : input.sort().reverse();
      } else {
        var property: string =
          propertyToCheck.substr(0, 1) == "+" ||
          propertyToCheck.substr(0, 1) == "-"
            ? propertyToCheck.substr(1)
            : propertyToCheck;

        return input.sort(function (a: any, b: any) {
          return !desc
            ? OrderByPipe._orderByComparator(a[property], b[property])
            : -OrderByPipe._orderByComparator(a[property], b[property]);
        });
      }
    } else {
      //Loop over property of the array in order and sort
      return input.sort(function (a: any, b: any) {
        for (var i: number = 0; i < config.length; i++) {
          var desc = config[i].substr(0, 1) == "-";
          var property =
            config[i].substr(0, 1) == "+" || config[i].substr(0, 1) == "-"
              ? config[i].substr(1)
              : config[i];

          var comparison = !desc
            ? OrderByPipe._orderByComparator(a[property], b[property])
            : -OrderByPipe._orderByComparator(a[property], b[property]);

          //Don't return 0 yet in case of needing to sort by next property
          if (comparison != 0) return comparison;
        }

        return 0; //equal each other
      });
    }
  }
}
