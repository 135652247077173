export default class Utils {
      private static phrases = [
      "but on the internet !",
      "but with a digital twist !",
      "but surfing the data waves of the internet.",
      "but in the cloud, floating through cyberspace.",
      "but beamed across the fiber-optic highways.",
      "but sprinkled with a bit of online magic !",
      "but riding the digital surfboard.",
      "but zipping through the net's neural network.",
      "but teleported into cyberspace.",
      "but woven into the World Wide Web.",
      "but at the speed of e-commerce.",
      "but in the domain of digital nomads !",
      "but on the virtual reality expressway.",
      "but through the pixels and beyond !",
      "but on the other side of the firewall.",
      "but on the other side of the blackwall.",
      "but in the echo of binary codes... codes... codes...",
      "but where the avatars roam.",
      "but cached in the Internet of Things !",
      "but hashtagged and trending.",
      "but in a realm of infinite tabs.",
      "but in the chatrooms of old.",
      "but streaming live in your browser.",
      "but on the digital frontier !",
      "but swiped right into the cloud.",
      "but uploaded to the server farm.",
      "but in the ethernet æther.",
      "but blinking in an LED glow.",
      "but in the archives of the deep web.",
      "but liked and subscribed !",
      "but encrypted in a digital vault.",
      "but beamed through satellite wifi.",
    ];
  
    static get randomPhrase() {
      const randomIndex = Math.floor(Math.random() * this.phrases.length);
      return this.phrases[randomIndex];
    }
}
