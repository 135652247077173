import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurchaseInvoiceProvider } from './purchase-invoice.provider';
import { CrawlerStatusProvider } from 'app/server-data-providers/crawler-status.provider';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    PurchaseInvoiceProvider,
    CrawlerStatusProvider
  ]
})
export class ServerDataProvidersModule { }
