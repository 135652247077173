import { Injectable } from '@angular/core';
import { EndpointDataProvider } from 'app/endpoint-data-provider/endpoint-data-provider';
import { CrawlerStatusService } from 'app/server/server.module';

@Injectable()
export class CrawlerStatusProvider {
  constructor(private server: CrawlerStatusService) { }

  eventLog = new EndpointDataProvider(this.server.getEventLog);
}
