<app-user-avatar [url]="data.user.Contact.ProfilePicture"></app-user-avatar>

<div>{{data.user.Contact.FirstName}} {{data.user.Contact.LastName}}</div>
<div>{{data.user.Email}}</div>

<div>
  {{data.user.Contact.Company.Name}}
  <app-company-type-icon [companyType]="data.user.Contact.Company.Type"></app-company-type-icon>
</div>

<div>
  <div>
    Status:
    <app-icon-checkbox [ngModel]="data.user.IsApproved" icon="done" matTooltip="Approved" [disabled]="true"></app-icon-checkbox>
    <app-icon-checkbox [ngModel]="data.user.IsLockedOut" icon="block" matTooltip="Locked Out" [disabled]="true"></app-icon-checkbox>
    <app-icon-checkbox [ngModel]="data.user.IsOnline" icon="language" matTooltip="Online" [disabled]="true"></app-icon-checkbox>
  </div>
  <div>
    Role:
    <app-icon-checkbox matTooltip="{{serverEnums.UserType[data.user.Type]}}" [icon]="iconMappings.UserType[data.user.Type]" [disabled]="true"></app-icon-checkbox>
  </div>
</div>

<dl>
  <ng-container *ngFor="let field of ['Created', 'LastActive', 'LastLockedOut', 'LastLoggedIn', 'LastPasswordChange']">
    <dt>{{field}}:</dt>
    <dd>{{data.user[field] | date: 'dd/MM/yyyy HH:mm:ss'}}</dd>
  </ng-container>
</dl>
