import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ADMIN_API_SERVER_URL } from '../tokens/admin-api-server-url.token';
import { ServerBaseService } from '../server-base.service';
import { Project } from '@equipmyschool/emsadminweb-models';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ProjectService extends ServerBaseService {
  private _project$ = new BehaviorSubject<Project>(null);
  public project$ = this._project$.asObservable();
  private _error$ = new BehaviorSubject<any>(null);
  public error$ = this._error$.asObservable();

  constructor(http: HttpClient, @Inject(ADMIN_API_SERVER_URL) serverUrl: string) {
    super(http, serverUrl);
  }

  public getProject (projectId: number) {
    this.request<Project>('GET', 'Project/Get', {ProjectID: projectId})
      .subscribe((response) => {
         this._project$.next(response);
      }, err => {
        this._error$.next(err);
      });
  }

  public setClosureDate (projectId: number, closureDate: Date) {
    this.request('POST', 'Project/SetClosureDate', null, {ProjectID: projectId, ClosureDate: (closureDate as any as moment.Moment).utcOffset(0, true)})
      .subscribe(_ => {
        this.getProject(projectId);
      }, err => {
        this._error$.next(err);
      });
  }
}
