<h1 mat-dialog-title>Find order</h1>
<div mat-dialog-content>
  <mat-form-field>
    <input matInput type="search" #filter placeholder="Product Code">
  </mat-form-field>
  <mat-nav-list>
    <a mat-list-item *ngFor="let order of (orders$ | async)" (click)="select(order)">
      {{order.PurchaseOrder.Reference}} ({{order.Quantity}})
    </a>
  </mat-nav-list>
</div>
