import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ServerBaseService } from '../server-base.service';
import { ADMIN_API_SERVER_URL } from '../tokens/admin-api-server-url.token';
import { BehaviorSubject, Observable } from 'rxjs';
import { Status } from '@equipmyschool/emsadminweb-models';

@Injectable()
export class OrderLineService extends ServerBaseService {
  private Reasons: BehaviorSubject<Status[]> = new BehaviorSubject<Status[]>(null);

  public Reasons$: Observable<Status[]> = this.Reasons.asObservable();

  constructor(http: HttpClient, @Inject(ADMIN_API_SERVER_URL) serverUrl: string) {
    super(http, serverUrl);
  }

  public setETA(body) {
    return this.request<any>('POST', 'OrderLine/SetETA', null, body);
  }

  public getReasons () {
    return this.request<any>('GET', 'Status/ForETA').subscribe(reasons => {
      this.Reasons.next(reasons);
    });
  }
}
