import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-selection-dialog',
  templateUrl: './selection-dialog.component.html',
  styleUrls: ['./selection-dialog.component.scss']
})
export class SelectionDialogComponent implements OnInit {
  selectedOption: SelectionOption;

  constructor(
    public dialogRef: MatDialogRef<SelectionModel>,
    @Inject(MAT_DIALOG_DATA) public data: SelectionModel,
  ) { }

  ngOnInit(): void {
  }

}

export interface SelectionModel {
  title: string,
  options: SelectionOption[]
}

export interface SelectionOption {
  data: any,
  name: string,
  detail: string
}
