import { AbstractControl, ValidatorFn, NG_VALIDATORS, Validator, ValidationErrors } from '@angular/forms';
import { Provider, forwardRef, Directive, OnChanges, Input, SimpleChanges } from '@angular/core';

export function forbiddenValuesValidator<T>(forbiddenValues: T[] | T): ValidatorFn {
  const forbiddenValuesArray = Array.isArray(forbiddenValues) ? forbiddenValues : [forbiddenValues];

  return (control: AbstractControl): { [key: string]: any } => {
    const invalid = forbiddenValuesArray.indexOf(control.value) > -1;
    return invalid ? { 'forbiddenValues': { value: control.value } } : null;
  };
}

export const FORBIDDEN_VALUES_VALIDATOR: Provider = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ForbiddenValuesValidatorDirective),
  multi: true
};

/**
 * A directive which installs the {@link ForbiddenValuesValidatorDirective} for any `formControlName`,
 * `formControl`, or control with `ngModel` that also has a `forbiddenValues` attribute.
 *
 * @experimental
 */
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[formControlName][forbiddenValues],[formControl][forbiddenValues],[ngModel][forbiddenValues]',
  providers: [FORBIDDEN_VALUES_VALIDATOR],
})
export class ForbiddenValuesValidatorDirective<T> implements Validator, OnChanges {
  private _validator: ValidatorFn;
  private _onChange: () => void;

  @Input('forbiddenValues') forbiddenValues: T | T[];

  ngOnChanges(changes: SimpleChanges): void {
    if ('forbiddenValues' in changes) {
      this._createValidator();
      if (this._onChange) {
        this._onChange();
      }
    }
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this._validator(c);
  }

  registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }

  private _createValidator(): void {
    this._validator = forbiddenValuesValidator(this.forbiddenValues);
  }
}
