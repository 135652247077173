import { Component, HostBinding, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NetUser, UserType } from '@equipmyschool/emsadminweb-models';
import { UserDetailsComponent } from 'app/epp-users/user-details/user-details.component';
import * as serverEnums from 'app/server/server-enums';
import { AspNetUserService, EmsService } from 'app/server/server.module';
import { ConfirmDialogComponent } from 'app/shared/confirm-dialog/confirm-dialog.component';
import { FilteredDataSource } from 'app/shared/filtered-data-source/filtered-data-source';
import { iconMappings } from 'app/shared/icon-mappings';
import { environment } from 'environments/environment';
import { of, Subscription } from 'rxjs';
import { routeAnimation } from '../route.animation';


@Component({
  selector: 'app-epp-users',
  templateUrl: './epp-users.component.html',
  styleUrls: ['./epp-users.component.scss'],
  animations: [routeAnimation]
})
export class EppUsersComponent implements OnInit {
  @HostBinding('@routeAnimation') routeAnimation = true;

  innerWidth: number;

  private subscriptions: Subscription[] = [];

  public serverEnums = serverEnums;
  public filter = "";

  iconMappings = iconMappings;

  displayedColumns = ['Avatar', 'FullName', 'Company', 'Status', 'Roles', 'Actions'];
  dataSource: FilteredDataSource<NetUser> = new FilteredDataSource(
    filterValue => {
      if (filterValue === '') {
        return of([]);
      }

      return this.server.getUsers(filterValue);
    }
  );

  toggleHandlers = {
    status: {
      approved: (user: NetUser) => {
        this.subscriptions.push(this.server.toggleApproved(user.AspNetUserID).subscribe(_ => {
          user.IsApproved = !user.IsApproved;
        }, err => {
          this.snackBar.open(`Failed to ${user.IsApproved ? 'dis' : ''}approve user ${user.Contact.FirstName} ${user.Contact.LastName}`);
        }));
      },
      unlock: (user: NetUser) => {
        this.subscriptions.push(this.server.unlock(user.AspNetUserID).subscribe(_ => {
          user.IsLockedOut = false;
        }, err => {
          this.snackBar.open(`Failed to unlock user ${user.Contact.FirstName} ${user.Contact.LastName}`);
        }));
      },
      verify: (user: NetUser) => {
        this.subscriptions.push(this.server.SetVerified(user.AspNetUserID).subscribe(_ => {
          user.IsVerified = true; // TODO: Throws a ExpressionChangedAfterItHasBeenCheckedError. Fix!!!
        }, err => {
          this.snackBar.open('Could not verify user: ' + err.error.Message);
        }));
      }
    },
    userType: (userType: UserType, user: NetUser) => {
      this.subscriptions.push(this.server.setType(user.AspNetUserID, userType).subscribe(_ => {
        user.Type = userType;
      }, err => {
        this.snackBar.open('Could not set user type: ' + err.error.Message);
      }));
    }
  };

  tryDelete(user: NetUser) {
    // Show modal asking whether the user really wants to delete the user.
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        text: `Are you sure you want to delete the user ${user.Contact.FirstName} ${user.Contact.LastName}?`
      }
    });
    this.subscriptions.push(dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.subscriptions.push(this.server.delete(user.AspNetUserID).subscribe(_ => {
          this.snackBar.open(`User ${user.Contact.FirstName} ${user.Contact.LastName} deleted`);
        }, err => {
          this.snackBar.open(`An error has occurred while trying to delete user ${user.Contact.FirstName} ${user.Contact.LastName}.`);
        }));
      }
    }));
  }

  tryResetPassword(user: NetUser) {
    // Show modal asking whether the user really wants to reset the user's password.
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        text: `Are you sure you want to reset the password for the user ${user.Contact.FirstName} ${user.Contact.LastName}?`
      }
    });
    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.subscriptions.push(this.server.resetPassword(user.AspNetUserID).subscribe(newPwd => {
          console.log('User password reset');
          this.snackBar.open(`${user.Contact.FirstName} ${user.Contact.LastName}'s (${user.Email}) password has been reset to ${newPwd}`);
          user.PasswordReset = true;
        }, err => {
          this.snackBar.open(`An error has occurred while trying to reset ${user.Contact.FirstName} ${user.Contact.LastName}'s password.`);
        }));
      }
    });
  }

  runAs(user: NetUser) {
    this.subscriptions.push(this.server.generateAdminForDetails(user.Contact.ContactID).subscribe(adminForDetails => {
      this.subscriptions.push(this.emsServer.administerFor(adminForDetails).subscribe(_ => {
        window.open(environment.emsClientUrl, '_blank');
      }));
    }));
  }

  constructor(
    private server: AspNetUserService,
    private emsServer: EmsService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }

  updateFilter(force:  boolean) {
    if (!this.dataSource) { return; }
    this.dataSource.filter = this.filter;
    if (force) { this.dataSource.forceUpdate(); }
  }

  showDetails(row: NetUser) {
    const dialogRef = this.dialog.open(UserDetailsComponent, {
      data: {
        user: row
      }
    });
  }
}
