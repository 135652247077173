import { Pipe, PipeTransform } from '@angular/core';
import { AssignmentList, Supplier, DdDispatchDetails } from 'coadapi';

@Pipe({ name: 'filterAssignments' })
export class AssignmentsWarehouseFilterPipe implements PipeTransform {
  public transform(assignmentList: AssignmentList[], warehouse: any): any {
    if ((warehouse == null || warehouse.length === 0) || assignmentList == null || warehouse == 0) {
      return assignmentList;
    }
    for (let i = 0; i < assignmentList.length; i++) {
      const assignment = assignmentList[i];
      assignment.assigned = assignment.assigned.sort((a, b) => {
        if (a.supplierName && b.supplierName) {
          return a.supplierName.localeCompare(b.supplierName);
        } else if (a.supplierName) {
          return -1;
        } else if (b.supplierName) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    return assignmentList.filter(al => al.assignee?.purchasingUser === 1 * (warehouse == 5 ? 2 : warehouse));    
  }
}

@Pipe({ name: 'filterSuppliers' })
export class SuppliersWarehouseFilterPipe implements PipeTransform {
  public transform(supplierList: Supplier[], warehouse: any, assignments: AssignmentList[]): any {
    if ((warehouse == null || warehouse.length === 0) || supplierList == null) {
      return supplierList;
    }
    var alreadyAssigned: Supplier[] = [];
    
    for (let i = 0; i < assignments.length; i++) {
      const assignee = assignments[i];
      for (let s = 0; s < assignee.assigned.length; s++) {
        const supplier = assignee.assigned[s];
        alreadyAssigned.push(supplier)
      }
    }
    return supplierList.filter(sl => !alreadyAssigned.includes(sl)).filter(sl => sl.supplierShipTo === 1 * warehouse).sort((a, b) => {
      if (a.supplierName && b.supplierName) {
        return a.supplierName.localeCompare(b.supplierName);
      } else if (a.supplierName) {
        return -1;
      } else if (b.supplierName) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}

@Pipe({ name: 'filterDispatches' })
export class DispatchesWarehouseFilterPipe implements PipeTransform {
  public transform(dispatchList: DdDispatchDetails[], warehouse: any): any {
    if ((warehouse == null || warehouse.length === 0) || dispatchList == null) {
      return dispatchList;
    }
    return dispatchList.filter(dl => dl.warehouseId === 1 * warehouse);
  }
}