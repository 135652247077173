import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GenNotesService } from 'coadapi';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-notes-dialog',
  templateUrl: './notes-dialog.component.html',
  styleUrls: ['./notes-dialog.component.scss']
})
export class NotesDialogComponent implements OnInit {
  @ViewChild(CdkVirtualScrollViewport, { static: true })
  notesContents: any[] = [];
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  selectedValue = [];
  subscriptions: Subscription[] = [];

  constructor(
    private notesService: GenNotesService, 
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
    this.getNotes(this.data.tableName, this.data.id);
  }

  getNotes(tablename: string, id: number) {
    this.isLoading$.next(true);
    this.subscriptions.push(
      this.notesService.getNotes(tablename, id).subscribe((notes) => {
      this.notesContents = notes;
      this.notesContents.forEach((note, index) => {
        // note.message = note.message.replace(/(?:\r\n|\r|\n)/g, '<br />');
        let selectedArray = [];
        if (note.goodsIn) {
          selectedArray.push("goodsIn-"+index);
        }
        if (note.goodsOut) {
          selectedArray.push("goodsOut-"+index);
        }
        if (note.public) {
          selectedArray.push("public-"+index);
        }
        this.selectedValue.push(selectedArray);
      });
      this.isLoading$.next(false);
    })
  )    
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
