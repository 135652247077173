import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumKeys'
})
export class EnumKeysPipe implements PipeTransform {

  transform(value: Object, args?: string[]): any {
    const keys = [];
    for (const enumMember of Object.keys(value)) {
      const memberAsInt = parseInt(enumMember, 10);
      if (!isNaN(memberAsInt)) {
        keys.push(memberAsInt);
      }
    }
    return keys;
  }

}
