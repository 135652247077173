<mat-selection-list [multiple]="false" (selectionChange)="selectedStaff($event)" (keydown)="handleKeyDown($event)">
  <mat-list-option *ngFor="let staff of stafflist | staffSearch: data.search let i = index" [value]="staff.email" [selected]="i === selectedIndex">
    <div class="user">
      <div class="profilepicture"><img src="{{this.staff.imageUrl}}"></div>
      <div class="username">{{this.staff.firstName}} {{this.staff.lastName}}</div>
      <div class="email">{{this.staff.email}}</div>
    </div>  
  </mat-list-option>
</mat-selection-list>

