import { Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { DateRange, MatCalendar } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomDateAdapter } from 'app/coad-web/proforma-to-dispatch/abstracts/_date-adapter';
import { InlineRangeCalendarComponent } from '../../proforma-to-dispatch/abstracts/_inline-daterange-calendar';
import { DeadlineUpdate, PtdDispatchService } from 'coadapi';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-iwd-deadline',
  templateUrl: './iwd-deadline.component.html',
  styleUrls: ['./iwd-deadline.component.scss'],
  providers: [InlineRangeCalendarComponent, {provide: DateAdapter, useClass: CustomDateAdapter}]
})
export class IwdDeadlineComponent implements OnInit {
  @ViewChildren(MatCalendar) components:QueryList<MatCalendar<Date>>;
  selectedValues = new Map<number, any>();
  indexVal = 0;
  dateValidationMsg = ' ';
  validCount = 0;
  originWarehouse = 0;
  checkCount = 0;
  initialized = false;
  public inlineRangeCalendarArray: Array<InlineRangeCalendarComponent> = [new InlineRangeCalendarComponent, new InlineRangeCalendarComponent, new InlineRangeCalendarComponent];
  selectedEtd: Date;
  selectedEta: Date;
  isDateSelected: boolean;
  iwdDeadlines$: Observable<DeadlineUpdate[]>;
  iwdDeadlines: DeadlineUpdate[];
  currentUtcDate = CustomDateAdapter.createDate(
    new Date().getUTCFullYear(),
    new Date().getUTCMonth(),
    new Date().getUTCDate()
  );
  startDateArray: Date[] = [this.currentUtcDate, this.currentUtcDate, this.currentUtcDate]
  subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dispatchService: PtdDispatchService
  ) { 
    if (!this.inlineRangeCalendarArray || !this.data) {
      console.error('Missing required data.');
      return;
    }
  
    for (let index = 0; index < this.inlineRangeCalendarArray.length; index++) {
      const inlineRangeCalendar = this.inlineRangeCalendarArray[this.inlineRangeCalendarArray.length - 1 - index];
      this.subscriptions.push(
        inlineRangeCalendar.selectedRangeValueChange.subscribe((res) => {
          this.selectedEtd = res.start;
          if (this.data.distinctWarehouses > 1) {
            if (this.selectedEtd > this.inlineRangeCalendarArray[0].selectedRangeValue.start) {
              this.dateValidationMsg = 'Please select a date before main shipment dispatch. ';
            } else {
              this.dateValidationMsg = '';
            }
            this.selectedEta = res.end;
            if (this.selectedEta > this.inlineRangeCalendarArray[0].selectedRangeValue.start) {
              this.dateValidationMsg = 'Please select a date before main shipment dispatch';
            } else {
              this.dateValidationMsg += '';
            }
          } else {
            if (this.selectedEtd > new Date(this.data.dispatch.dispatchEts)) {
              this.dateValidationMsg = 'Please select a date before main shipment dispatch. ';
            } else {
              this.dateValidationMsg = '';
            }
            this.selectedEta = res.end;
            if (this.selectedEta > new Date(this.data.dispatch.dispatchEts)) {
              this.dateValidationMsg = 'Please select a date before main shipment dispatch';
            } else {
              this.dateValidationMsg += '';
            }
          }
        })
      );
    }
  };

  ngOnInit(): void {
    console.log(this.data, this.inlineRangeCalendarArray);
    this.originWarehouse = this.data.warehouse;
    this.iwdDeadlines$ = this.dispatchService.getIWDDeadline(this.data.dispatch.dispatchId)
    this.subscriptions.push(
      this.iwdDeadlines$.subscribe((res) => {
        this.iwdDeadlines = res;
      }, (error) => {
        console.error(error);
      })
    )
    for (let i = 0; i < this.data.distinctWarehouses.length; i++) {
      const warehouse = this.data.distinctWarehouses[i];
      this.subscriptions.push(
        this.iwdDeadlines$.subscribe((res) => {
          const deadline = res.find(x => x.warehouseId === warehouse.warehouseId);
          this.startDateArray[i] = new Date(deadline?.collectionDate) ?? new Date();
          this.chooseDate(deadline, i);
          this.goToDate();
          console.log(i, this.startDateArray[this.data.distinctWarehouses.length - 1 - i])
        }, (error) => {
          console.error(error);
        })
      )
    }
  }

  public goToDate() {
    for (let i = 0; i < this.components.toArray().length; i++) {
      const calendar = this.components.toArray()[this.components.toArray().length - 1 - i];      
      calendar._goToDateInView(this.startDateArray[i], 'month')
      calendar.updateTodaysDate();
      calendar.comparisonStart = this.startDateArray[i]
      calendar.comparisonEnd = this.startDateArray[i]
      console.log(calendar);
    }
 }

  chooseDate(deadlines: DeadlineUpdate, index: number) {
    this.inlineRangeCalendarArray[index].index = deadlines?.warehouseId
    this.inlineRangeCalendarArray[index].originalRangeValue = this.inlineRangeCalendarArray[index].selectedRangeValue
    this.selectedEtd = new Date(deadlines?.ets ?? new Date);
    this.selectedEta = new Date(deadlines?.eta ?? new Date);
    this.inlineRangeCalendarArray[index].selectedRangeValue = new DateRange<Date>(this.selectedEtd, this.selectedEta);
    console.log('etd date:', this.selectedEtd);
    console.log('eta date:', this.selectedEta);
    console.log(this.inlineRangeCalendarArray[index].selectedRangeValue)
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
