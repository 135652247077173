<div class="boundary">
  <h1 mat-dialog-title>Deadline for IWD</h1>
  <p class="divide--long"></p>
  <div mat-dialog-content>
      Please set the delivery deadline for IWD shipments per warehouse listed
    <div class="content calendars">
      <span class="calendar-group {{warehouse.warehouseId === this.originWarehouse}}" *ngFor="let warehouse of data.distinctWarehouses | reverse;index as indexVal">
        <span class="calendar-warehouse">
          <b>{{warehouse.region}}</b>
        </span>
        <mat-calendar class="warehouse-calendar" 
          [activeDate]="startDateArray[data.distinctWarehouses.length - 1 - indexVal]"
          [selected]="inlineRangeCalendarArray[data.distinctWarehouses.length - 1 - indexVal].selectedRangeValue" 
          (selectedChange)="inlineRangeCalendarArray[data.distinctWarehouses.length - 1 - indexVal].selectedChange($event, warehouse.warehouseId, warehouse.warehouseId === this.originWarehouse)">
        </mat-calendar>
      </span>
    </div>
  </div>
  <p class="verification">{{dateValidationMsg}}</p>
  <div mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button [disabled]="dateValidationMsg.length > 0" [mat-dialog-close]="inlineRangeCalendarArray">Accept</button>
  </div>
</div>