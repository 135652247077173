<div class="search-header">
  <mat-form-field floatPlaceholder="never">
    <input matInput placeholder="Filter clients" [(ngModel)]="filter" (ngModelChange)="updateFilter()" (keyup.enter)="updateFilter()">
  </mat-form-field>
</div>

<p *ngIf="(dataSource.connect() | async)?.length === 0">No clients found</p>

<ng-container *ngIf="supplierCountries && (dataSource.connect() | async)?.length > 0">
  <mat-table [dataSource]="dataSource" matSort>

    <ng-container cdkColumnDef="AccountNo">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>Account No</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{row.AccountNo}}
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="Name">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>Name</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{row.Name}}
      </mat-cell>
    </ng-container>
    
    <ng-container cdkColumnDef="SalesRep">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>Sales Rep</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{row.SalesRep}}
      </mat-cell>
    </ng-container>
    
    <ng-container cdkColumnDef="Country">
      <mat-header-cell *cdkHeaderCellDef>Country</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <app-country-icon [country]="row.Country"></app-country-icon>
        {{row.Country.Name}}
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="WarehouseCountries">
      <mat-header-cell *cdkHeaderCellDef>Warehouse Countries</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <ng-container *ngFor="let country of supplierCountries">
          <app-country-icon [country]="country" [class.disabled]="!row.WarehouseCountries.includes(country.CountryID)" (click)="toggleCountry(row, country)"></app-country-icon>&nbsp;
        </ng-container>
      </mat-cell>
    </ng-container>

    <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *cdkRowDef="let row; let i = index; columns: displayedColumns;"></mat-row>
  </mat-table>
</ng-container>
