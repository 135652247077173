import { Component, OnInit, HostBinding } from '@angular/core';
import { routeAnimation } from 'app/route.animation';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  animations: [routeAnimation]
})
export class WelcomeComponent implements OnInit {
  @HostBinding('@routeAnimation') routeAnimation = true;

  title = 'app';

  constructor() { }

  ngOnInit() {
  }

}
