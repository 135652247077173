import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ExportToQuickbooksComponent } from './export-to-quickbooks.component';
import { SharedModule } from 'app/shared/shared.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'app/material.module';
import { ExportDetailsComponent } from './export-details/export-details.component';
import { PurchaseInvoiceProductsComponent } from './purchase-invoice-products/purchase-invoice-products.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    FlexLayoutModule,
    MaterialModule,
    RouterModule,
    SharedModule
  ],
  declarations: [
    ExportToQuickbooksComponent,
    ExportDetailsComponent,
    PurchaseInvoiceProductsComponent
  ],
  entryComponents: [
    ExportDetailsComponent
  ],
})
export class ExportToQuickbooksModule { }
