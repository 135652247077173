import { Directive, ElementRef, HostListener, Self, Input, Output, EventEmitter, Optional } from '@angular/core';
import { MatCheckbox, TransitionCheckState } from '@angular/material/checkbox';
import { IconCheckboxComponent } from 'app/shared/icon-checkbox/icon-checkbox.component';
import { NgModel } from '@angular/forms';

@Directive({
  selector: 'mat-checkbox[appInsteadOfModelChange], app-icon-checkbox[appInsteadOfModelChange]'
})
export class InsteadOfCheckboxModelChangeDirective {
  @Output() appInsteadOfModelChange = new EventEmitter<boolean>();

  element: MatCheckbox;

  constructor( @Optional() element?: MatCheckbox, @Optional() element2?: IconCheckboxComponent) {
    this.element = element || element2;

    if (!this.element) {
      throw new Error('InsteadOfCheckboxModelChangeDirective constructor needs to be used either on MatCheckbox, ' +
        'on IconCheckboxComponent. Both are null');
    }

    this.element._onInputClick = _onInputClick;

    const onViewModelChange = this.appInsteadOfModelChange;

    /**
    * Event handler for checkbox input element.
    * Instead of changing the view model value and propagating it to the input element,
    * emit an event.
    * @param event
    */
    function _onInputClick(event: Event) {
      // We have to stop propagation for click events on the visual hidden input element.
      // By default, when a user clicks on a label element, a generated click event will be
      // dispatched on the associated input element. Since we are using a label element as our
      // root container, the click event on the `checkbox` will be executed twice.
      // The real click event will bubble up, and the generated click event also tries to bubble up.
      // This will lead to multiple click events.
      // Preventing bubbling for the second event will solve that issue.
      event.stopPropagation();

      if (!this.disabled) {
        onViewModelChange.emit(!this.checked);
      }
    }
  }
}
