import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { EppUsersComponent } from './epp-users/epp-users.component';
import { CrawlerStatusPluginsComponent } from './crawler-status/plugins/crawler-status-plugins.component';
import { CategoryPagesComponent } from 'app/crawler-status/category-pages/category-pages.component';
import { ProductPagesComponent } from 'app/crawler-status/product-pages/product-pages.component';
import { ProductsComponent } from 'app/crawler-status/products/products.component';
import { EventLogComponent } from 'app/crawler-status/event-log/event-log.component';
import { AssociateToCompanyComponent } from 'app/epp-users/associate-to-company/associate-to-company.component';
import { AssociateToContactComponent } from 'app/epp-users/associate-to-contact/associate-to-contact.component';
import { PurchaseInvoiceComponent } from 'app/purchase-invoice/purchase-invoice.component';
import { EmailActionComponent } from 'app/email-action/email-action.component';
import { ProformaToDispatchComponent } from 'app/coad-web/proforma-to-dispatch/proforma-to-dispatch.component';
import { ClientComponent } from 'app/coad-web/proforma-to-dispatch/pages/client/client.component';
import { IwdDeadlineComponent } from 'app/coad-web/dialogs/iwd-deadline/iwd-deadline.component'
import { ExportToQuickbooksComponent } from './export-to-quickbooks/export-to-quickbooks.component';
import { ClientCountriesComponent } from './client-countries/client-countries.component';
import { ProductImagesComponent } from './product-images/product-images.component';
import { ProjectClosureComponent } from './project-closure/project-closure.component';
import { DispatchDetailsComponent } from './coad-web/dispatch-details/dispatch-details.component';
import { ChasingBoardComponent } from './coad-web/chasing-board/chasing-board.component';
import { ChaseNotesComponent } from './coad-web/chasing-board/pages/dialogs/chase-notes/chase-notes.component'

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        component: WelcomeComponent
      },
      {
        path: 'email-action',
        component: EmailActionComponent
      },
      {
        path: 'epp-users',
        children: [
          {
            path: '',
            component: EppUsersComponent,
          },
          {
            path: 'associate-to-contact/:aspNetUserId',
            component: AssociateToContactComponent
          },
          {
            path: 'associate-to-company/:aspNetUserId',
            component: AssociateToCompanyComponent
          }
        ]
      },
      {
        path: 'crawler-status',
        children: [
          {
            path: 'plugins',
            component: CrawlerStatusPluginsComponent
          },
          {
            path: 'category-pages',
            component: CategoryPagesComponent
          },
          {
            path: 'product-pages',
            component: ProductPagesComponent
          },
          {
            path: 'products',
            component: ProductsComponent
          },
          {
            path: 'event-log',
            component: EventLogComponent
          }
        ]
      },
      {
        path: 'purchase-invoice/:documentType',
        component: PurchaseInvoiceComponent
      },
      {
        path: 'export-to-quickbooks',
        component: ExportToQuickbooksComponent
      },
      {
        path: 'client-countries',
        component: ClientCountriesComponent
      },
      {
        path: 'product-images',
        component: ProductImagesComponent
      },
      {
        path: 'coad-web',
        children: [
          {
            path: 'chasing-board',
            component: ChasingBoardComponent,
            children: [
              {
                path:'chase-notes',
                component: ChaseNotesComponent
              }
            ]
          },
          {
            path: 'dispatch-details',
            component: DispatchDetailsComponent,
            children: [
              {
                path: 'dispatch',
                loadChildren: () =>
                  import('./coad-web/dialogs/dispatch/dispatch-dialog.module').then(
                    m => m.DispatchDialogModule
                  ),
              },
              {
                path: 'contents',
                loadChildren: () =>
                  import('./coad-web/dialogs/contents/contents-dialog.module').then(
                    m => m.ContentsDialogModule
                  )
              },
              {
                path: 'iwd-contents',
                loadChildren: () =>
                  import('./coad-web/dialogs/iwd-contents/iwd-contents-dialog.module').then(
                    m => m.IwdContentsDialogModule
                  )
              },
            ]
          },
          {
            path: 'proformas-to-dispatch',
            component: ProformaToDispatchComponent,
          },
          {
            path: 'pages/client/:clientId',
            component: ClientComponent,
            children: [
              {
                path: 'dispatch',
                loadChildren: () =>
                  import('./coad-web/dialogs/dispatch/dispatch-dialog.module').then(
                    m => m.DispatchDialogModule
                  ),
              },
              {
                path: 'contents',
                loadChildren: () =>
                  import('./coad-web/dialogs/contents/contents-dialog.module').then(
                    m => m.ContentsDialogModule
                  )
              },
              {
                path: 'iwd-deadline',
                component: IwdDeadlineComponent,
              },
              {
                path: 'iwd-contents',
                loadChildren: () =>
                  import('./coad-web/dialogs/iwd-contents/iwd-contents-dialog.module').then(
                    m => m.IwdContentsDialogModule
                  )
              },
              {
                path: 'descriptive',
                loadChildren: () =>
                  import('./coad-web/dialogs/descriptive/descriptive.module').then(
                    m => m.DescriptiveModule
                  ),
              },
            ]
          }
        ],
      },
      {
        path: 'project-closure/:projectId',
        component: ProjectClosureComponent
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: []
})
export class RoutingModule { }
