import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AssociateToCompanyComponent } from './associate-to-company/associate-to-company.component';
import { ConfirmAssociateUserComponent } from './confirm-associate-user/confirm-associate-user.component';
import { AssociateToContactComponent } from './associate-to-contact/associate-to-contact.component';
import { EppUsersComponent } from './epp-users.component';
import { SharedModule } from 'app/shared/shared.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'app/material.module';
import { UserDetailsComponent } from './user-details/user-details.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PerfectScrollbarModule,
    FlexLayoutModule,

    MaterialModule,

    RouterModule,
    SharedModule
  ],
  declarations: [
    ConfirmAssociateUserComponent,
    AssociateToCompanyComponent,
    AssociateToContactComponent,
    EppUsersComponent,
    UserDetailsComponent
  ],
  entryComponents: [
    ConfirmAssociateUserComponent,
    UserDetailsComponent
  ],
})
export class EppUsersModule { }
