import { Component, OnInit } from '@angular/core';
import { CrawlerStatusPage, Supplier } from '@equipmyschool/emsadminweb-models';
import { CrawlerStatusService } from 'app/server/server.module';
import { UnsubscribeOnDestroy } from 'app/shared/decorators/unsubscribe-on-destroy';
import { saveFile } from 'app/shared/save-file';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-product-pages',
  templateUrl: './product-pages.component.html',
  styleUrls: ['./product-pages.component.scss']
})
export class ProductPagesComponent implements OnInit {

  public productPages: CrawlerStatusPage[];

  @UnsubscribeOnDestroy()
  private subscriptions: Subscription[] = [];

  constructor(private crawlerStatusService: CrawlerStatusService) { }

  ngOnInit() {
    this.subscriptions.push(this.crawlerStatusService.getProductPages().subscribe(res => {
      this.productPages = res;
    }));
  }

  downloadSpreadsheet(supplier: Supplier) {
    this.subscriptions.push(this.crawlerStatusService.getProductPageErrors(supplier.SupplierID).subscribe(res => {
      saveFile(res);
    }));
  }
}
