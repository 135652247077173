<!--<div fxLayout="row" fxLayoutAlign="center center" style="margin: 24px 0;">-->
<!--  <mat-card fxFlex="90%">-->
<div fxLayout="row" fxLayoutAlign="center center"
     [style.margin-top.px]="innerWidth <= 820 ? 0 : 24">
  <mat-card [fxFlex]="innerWidth <= 820 ? '100%' : '90%'">
    <h2>e-Procurement Portal Users</h2>

    <div class="search-header">
      <mat-form-field floatPlaceholder="never">
        <input matInput placeholder="Filter users" [(ngModel)]="filter" (ngModelChange)="updateFilter(false)" (keyup.enter)="updateFilter(true)">
      </mat-form-field>
    </div>

    <p *ngIf="(dataSource.connect() | async)?.length === 0">No users found</p>
    <p *ngIf="!(dataSource.connect() | async)">Please enter a search query to find users</p>

   <!-- <div class="table-container mat-elevation-z8" *ngIf="(dataSource.connect() | async)?.length > 0">-->
    <div [ngClass]="innerWidth <= 820 ? 'mat-elevation-z0': 'mat-elevation-z8'"
         class="table-container"
         *ngIf="(dataSource.connect() | async)?.length > 0">

      <mat-table #table [dataSource]="dataSource">

        <ng-container cdkColumnDef="Company">
          <mat-header-cell *cdkHeaderCellDef> Company </mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <app-company-type-icon [companyType]="row.Contact.Company?.Type"></app-company-type-icon>
            <app-country-icon [country]="row.Contact.Company?.Country"></app-country-icon>
            <div>{{row.Contact.Company?.Name}}</div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Avatar">
          <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <a class="detail-a" (click)="showDetails(row)">
              <app-user-avatar [url]="row.Contact.ProfilePicture"></app-user-avatar>
            </a>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="FullName">
          <mat-header-cell *cdkHeaderCellDef> Full Name </mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <a class="detail-a" (click)="showDetails(row)">
              {{row.Contact.FirstName}} {{row.Contact.LastName}}
            </a>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Status">
          <mat-header-cell *cdkHeaderCellDef> Status </mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <app-icon-checkbox [checked]="!!row.IsAccepted" icon="how_to_reg" matTooltip="Accepted" [disabled]="!row.IsAccepted"></app-icon-checkbox>
            <app-icon-checkbox [checked]="!!row.IsApproved" icon="done" matTooltip="Approved" (appInsteadOfModelChange)="toggleHandlers.status.approved(row)"></app-icon-checkbox>
            <app-icon-checkbox [checked]="!!row.IsVerified" icon="verified_user" matTooltip="Verified" [disabled]="row.IsVerified" (appInsteadOfModelChange)="toggleHandlers.status.verify(row)"></app-icon-checkbox>
            <app-icon-checkbox [checked]="!!row.IsLockedOut" icon="block" matTooltip="Locked Out" [disabled]="!row.IsLockedOut" (appInsteadOfModelChange)="toggleHandlers.status.unlock(row)"></app-icon-checkbox>
            <app-icon-checkbox [checked]="!!row.PasswordReset" icon="update" matTooltip="Password Reset" (appInsteadOfModelChange)="tryResetPassword(row)"></app-icon-checkbox>
            <app-icon-checkbox [checked]="!!row.IsOnline" icon="language" matTooltip="Online" [disabled]="true"></app-icon-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Roles">
          <mat-header-cell *cdkHeaderCellDef> Role </mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <app-icon-checkbox *ngFor="let userType of serverEnums.UserType | enumKeys" [ngModel]="row.Type === userType" [icon]="iconMappings.UserType[userType]"
              matTooltip="{{serverEnums.UserType[userType]}}" (appInsteadOfModelChange)="toggleHandlers.userType(userType, row)"></app-icon-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef> Actions </mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <button mat-icon-button (click)="tryDelete(row)">
              <mat-icon> delete </mat-icon>
            </button>
            <button mat-button *ngIf="!row.Contact.ContactID" [routerLink]="['/epp-users/associate-to-contact', row.AspNetUserID]">
              Associate to contact
            </button>
            <button mat-button *ngIf="row.Contact.ContactID && !row.Contact.Company.CompanyID" [routerLink]="['/epp-users/associate-to-company', row.AspNetUserID]">
              Associate to company
            </button>
            <button mat-button *ngIf="row.Contact.ContactID && row.Contact.Company.CompanyID" (click)="runAs(row)">
              Run As...
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
  </mat-card>
</div>
