import { Observable, merge } from 'rxjs';
import { map } from 'rxjs/operators';

export const reEmitWhen = <T, TWhen>(when$: Observable<TWhen>) => (source: Observable<T>) => {
  let lastSourceValue: T;

  // TODO: Dispose of subscription on unsubscribe()
  const subscription = source.subscribe(value => {
    lastSourceValue = value;
  });

  return merge(
    source,
    when$.pipe(map(() => lastSourceValue))
  );
};
