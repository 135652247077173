<mat-toolbar class="mat-elevation-z4">
  <span class="row-1">
    <h1>Chasing Board</h1>
  </span>
  <span class="row-2">
    <h2>{{randomPhrase}}</h2>
  </span>
  <span class="settings">
    <mat-button-toggle-group #group="matButtonToggleGroup" name="warehouse" (change)="setWarehouse(group.value)" aria-label="warehouse">
      <mat-button-toggle class="lincolnshire" [class.strong]="group.value == '1'" value="1">UK</mat-button-toggle>
      <mat-button-toggle class="summerville" [class.strong]="group.value == '2'" value="2">US</mat-button-toggle>
      <mat-button-toggle class="northcharleston" [class.strong]="group.value == '5'" value="5">US-NC</mat-button-toggle>
      <mat-button-toggle class="matraville" [class.strong]="group.value == '4'" value="4">Aus</mat-button-toggle>
      <mat-button-toggle class="direct/virtual" [class.strong]="group.value == '0'" value="0">Direct/Virtual</mat-button-toggle>
    </mat-button-toggle-group>
    <span class="buttons">
      <button mat-button class="autoAssign" (click)="confirm('auto assign',warehouse)">Auto Assign</button>
    </span>    
  </span>
</mat-toolbar>

<div class="workspace"
cdkDropList
(cdkDropListDropped)="dropDisassociate($event)"
[cdkDropListConnectedTo] = assignmentIDList
[cdkDropListData] = "sacrificialArray"
id="drop-zone">
  <cdk-virtual-scroll-viewport autosize class="scrollviewport" itemSize="25">
    <mat-card class="mat-elevation-z4 dispatch-details">
      <div class="header row">        
        <div class="cell schoolAcc" (click)="sort('schoolAcc')">Dispatch
          <img *ngIf="orderDir && sortBy === 'schoolAcc'"
            src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg" (error)="onImgError($event)" width="12px"
            alt="sort">
        </div>
        <div class="cell shippedTo" (click)="sort('shippedTo')">Shipped To
          <img *ngIf="orderDir && sortBy === 'shippedTo'"
            src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg" (error)="onImgError($event)" width="12px"
            alt="sort">
        </div>
        <div class="cell closureDate" (click)="sort('closureDate')">Closure Date
          <img *ngIf="orderDir && sortBy === 'closureDate'"
            src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg" (error)="onImgError($event)" width="12px"
            alt="sort">
        </div>
        <!-- <div class="cell etd" (click)="sort('etd')">ETD
          <img *ngIf="orderDir && sortBy === 'etd'" src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg"
            (error)="onImgError($event)" width="12px" alt="sort">
        </div>
        <div class="cell eta" (click)="sort('eta')">ETA
          <img *ngIf="orderDir && sortBy === 'eta'" src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg"
            (error)="onImgError($event)" width="12px" alt="sort">
        </div> -->
        <div class="cell poc" (click)="sort('poc')">POC
          <img *ngIf="orderDir && sortBy === 'poc'" src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg"
            (error)="onImgError($event)" width="12px" alt="sort">
        </div>
        <div class="cell ltc" (click)="sort('ltc')">LTC
          <img *ngIf="orderDir && sortBy === 'ltc'" src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg"
            (error)="onImgError($event)" width="12px" alt="sort">
        </div>
        <div class="cell ownr" (click)="sort('ownr')">OWNR
          <img *ngIf="orderDir && sortBy === 'ownr'" src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg"
            (error)="onImgError($event)" width="12px" alt="sort">
        </div>
        <div class="cell pq" (click)="sort('pq')">PQ
          <img *ngIf="orderDir && sortBy === 'pq'" src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg"
            (error)="onImgError($event)" width="12px" alt="sort">
        </div>
        <div class="cell cq" (click)="sort('cq')">CQ
          <img *ngIf="orderDir && sortBy === 'cq'" src="app/coad-web/proforma-to-dispatch/images/{{orderDir}}.svg"
            (error)="onImgError($event)" width="12px" alt="sort">
        </div>
      </div>

      <div class="row" *cdkVirtualFor="let dispatchDetail of dispatchDetails | filterDispatches: warehouse | orderBy: [orderVar]">
        <div class="cell schoolAcc">{{dispatchDetail.schoolAcc}}</div>
        <div class="cell shippedTo">{{dispatchDetail.shippedTo}}</div>
        <div class="cell closureDate">{{dispatchDetail.closureDate | date: "d MMM yyyy"}}</div>
        <!-- <div class="cell etd">{{dispatchDetail.etd | date: "d MMM yyyy"}}</div>
        <div class="cell eta">{{dispatchDetail.eta | date: "d MMM yyyy"}}</div> -->
        <div class="cell poc">{{dispatchDetail.poc ?? 0}}</div>
        <div class="cell ltc">{{dispatchDetail.ltc ?? 0}}</div>
        <div class="cell ownr">{{dispatchDetail.ownr ?? 0}}</div>
        <div class="cell pq">{{dispatchDetail.pq ?? 0}}</div>
        <div class="cell cq">{{dispatchDetail.cq ?? 0}}</div>
      </div>
    </mat-card>
  </cdk-virtual-scroll-viewport>

  <div class="suppliers" 
  id="supplierslist"
  cdkDropList
  [cdkDropListData]="getSuppliersForWarehouse(warehouse)"
  [cdkDropListConnectedTo] = assignmentIDList>
      <div class="mat-elevation-z4 assignment-box" *ngFor="let item of getSuppliersForWarehouse(warehouse)" 
      cdkDrag 
      [cdkDragDisabled]="!isPurchasingAdmin()"
      (cdkDragStarted)="onDragStarted($event, item)"
      (cdkDragReleased)="onDragReleased($event, item)"
      [ngClass]="{'dragging': isDragging(item)}">
        {{item.supplierName}} - Lines: {{item.lineCount}} - POs: {{item.poCount}}
      </div>
      <div class="mat-elevation-z4 suppliers-cleared" *ngIf="(getSuppliersForWarehouse(warehouse))?.length === 0">
        <mat-icon svgIcon="tick"></mat-icon>
        <div>All Clear Here</div>
      </div>
  </div>

  <div class="assignments">
    <mat-card class="mat-elevation-z4 assignment" id="assignment{{assignment.assignee.adminId}}"
    cdkDropList 
    cdkDropListSortingDisabled
    (cdkDropListDropped)="drop($event)" 
    [cdkDropListConnectedTo] = disassignmentIDList 
    [cdkDropListData]="assignment" 
    *ngFor="let assignment of assignmentList | filterAssignments: warehouse; let i = index" [attr.data-index]="i % 4">
      <mat-card-title>
          {{getPurchasingStaffFirstName(assignment.assignee.adminId)}} - Lines: {{countLines(assignment.assigned)}} - POs: {{countPoLines(assignment.assigned)}}
          <button matTooltip="Clear Assignments" class="delete" mat-icon-button aria-label="clear assignments" (click)="confirm('clear',assignment.assignee.adminId)">
            <mat-icon svgIcon="trash-can"></mat-icon>
          </button>
      </mat-card-title>
      <p class="divide--long"></p>
      <div>
        <div class="assignment-box" *ngFor="let item of assignment.assigned" 
        cdkDrag
        (cdkDragStarted)="onDragStarted($event, item)"
        [ngClass]="{'dragging': isDragging(item),'processing': item.processing}">
          <div class="name" [class.strikethrough]="item.complete">{{item.supplierName}}</div>
          <div class="counts" [class.strikethrough]="item.complete">LC: {{item.lineCount}} - PO: {{item.poCount}}</div>
          <button mat-button class="notes" (click)="openChaseNotes($event, assignment.assignee.adminId, item.supplierId)">N</button>
          <div class="checkbox"><mat-checkbox [(ngModel)]="item.complete" (change)="toggleAssignments(assignment.assignee.adminId, item)"></mat-checkbox></div>
        </div>
      </div>
    </mat-card>
  </div>
</div>