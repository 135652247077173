import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Commodity, PurchaseInvoiceProductDetailed, QuickBooksReconciliationObject } from '@equipmyschool/emsadminweb-models';
import { CommodityService, PurchaseInvoiceService } from 'app/server/server.module';
import { Observable, Subscription } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

@Component({
  selector: 'app-purchase-invoice-products',
  templateUrl: './purchase-invoice-products.component.html',
  styleUrls: ['./purchase-invoice-products.component.scss']
})
export class PurchaseInvoiceProductsComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription[] = [];
  productData = new MatTableDataSource<PurchaseInvoiceProductDetailed>([]);
  public commodities: Commodity[];
  popupColumns = ['Code', 'Description', 'Quantity', 'GoodsCost', 'FreightCost', 'TaxCost', 'Commodity']
  isClosing$: Observable<boolean>;

  constructor(
    private piServer: PurchaseInvoiceService,
    private commServer: CommodityService,
    public dialogRef: MatDialogRef<PurchaseInvoiceProductsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: QuickBooksReconciliationObject
  ) { 
    this.isClosing$ = this.dialogRef.beforeClosed().pipe(mapTo(true));
  }

  ngOnInit() {
    this.commServer.get().subscribe((response) => {
      this.commodities = response;
    });

    this._subscriptions.push(
      this.piServer.piProducts$
        .pipe(
          map((response) => {
            if (response) {
              this.productData.data = response;
            }
          })
        )
        .subscribe()
    );

    this.dialogRef.beforeClosed().subscribe((response) => {
      if (response) {
        this.piServer.updateProducts(this.productData.data).subscribe(() => {});
      }
    });

    this.piServer.getProducts(Number(this.data.ID));
  }
  
  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  commodityCompareFn(a: Commodity, b: Commodity) {
    return a && b ? a.CommodityID === b.CommodityID : a === b;
  }

}
