import { AbstractControl, ValidatorFn, NG_VALIDATORS, Validator, ValidationErrors, Validators } from '@angular/forms';
import { Provider, forwardRef, OnChanges, Directive, SimpleChanges, Input } from '@angular/core';

export const VALIDATORS_VALIDATOR: Provider = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ValidatorsDirective),
  multi: true
};

/**
 * A directive which installs the {@link ValidatorsDirective} for any `formControlName`,
 * `formControl`, or control with `ngModel` that also has a `equals` attribute.
 */
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[formControlName][appValidators],[formControl][appValidators],[ngModel][appValidators]',
  providers: [VALIDATORS_VALIDATOR],
})
export class ValidatorsDirective<T> implements Validator, OnChanges {
  private _aggregateValidator: ValidatorFn;
  private _onChange: () => void;

  @Input() appValidators: ValidatorFn[] | ValidatorFn;

  ngOnChanges(changes: SimpleChanges): void {
    if ('appValidators' in changes) {
      this._createValidator();
      if (this._onChange) {
        this._onChange();
      }
    }
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this._aggregateValidator(c);
  }

  registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }

  private _createValidator(): void {
    this._aggregateValidator = Validators.compose(Array.isArray(this.appValidators) ? this.appValidators : [this.appValidators]);
  }
}
