import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductImagesService } from 'app/server/server.module';
import { Company, Product } from '@equipmyschool/emsadminweb-models';

@Component({
  selector: 'app-product-images',
  templateUrl: './product-images.component.html',
  styleUrls: ['./product-images.component.scss']
})
export class ProductImagesComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription[] = [];

  clients: Company[];
  selectedClient: number = null;
  product: Product;
  stillSearching: boolean = null;
  remaining: number = null;

  form: FormGroup;

  @ViewChild('image') imageVariable: ElementRef;

  constructor(
    private server: ProductImagesService,
    private _fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.form =this._fb.group({
      url: ['', [this.urlValidator]],
      image: ['']
    })

    this._subscriptions.push(
      this.server.clients$
        .pipe(
          map((response) => {
            this.selectedClient = null;
            if (response) {
              this.clients = response;
            }
          })
        )
        .subscribe()
    );

    this._subscriptions.push(
      this.server.product$
        .pipe(
          map((response) => {
            this.resetValues();
            
            if (response) {
              this.product = response;
            }

            else {
              this.product = null;

              if (this.stillSearching) {
                this.findProduct();
              }
            }
          })
        )
        .subscribe()
    );

    this._subscriptions.push(
      this.server.remainingCount$
        .pipe(
          map((response) => {
            this.remaining = response;
          })
        )
        .subscribe()
    );

    this._subscriptions.push(
      this.server.stillSearching$
        .pipe(
          map((response) => {
            this.stillSearching = response;
          })
        )
        .subscribe()
    );

    this._subscriptions.push(
      this.server.modelState$
        .pipe(
          map((response) => {
            if (response) {
              Object.keys(response).forEach(prop => {
                const formControl = this.form.get(prop == 'Image' ? 'image' : prop == 'ImageUrl' ? 'url' : prop);
                if (formControl) {
                  formControl.setErrors({
                    serverError: response[prop]
                  })
                }
              });
            }
          })
        )
        .subscribe()
    )

    this.server.getClients();
  }
  
  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  clientChanged() {    
    this.stillSearching = null;
    this.product = null;
    this.remaining = null;
    this.resetValues();
  }

  findProduct() {
    this.server.get(this.selectedClient);
  }

  resetValues() {
    this.form.get('url').setValue("");
    this.form.get('url').setErrors({ serverError: null });
    this.form.get('image').setValue("");
    this.form.get('image').setErrors({ serverError: null });
  }

  addImage() {
    const url = this.form.get('url').value;
    const file = this.imageVariable.nativeElement.files[0];

    if (!file && this.form.get('url').invalid) { return; }

    this.server.post(this.product.SupplierID, this.product.ProductID, url, file);
  }

  urlValidator({value}: AbstractControl) : null | ValidationErrors {
    try {
      new URL(value);
      return null;
    } catch {
      return {pattern: true};
    }
  }
}
