<h2>Plugins</h2>

<div class="table-container mat-elevation-z8">

  <mat-table #table [dataSource]="dataSource" matSort>

    <ng-container cdkColumnDef="Name">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Supplier </mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <a [href]="emsClientUrl + 'shop?SupplierIDs=!' + row.SupplierID + '~'" target="_blank"> {{row.Name}} </a>
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="Id">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Id </mat-header-cell>
      <mat-cell *cdkCellDef="let row"> {{row.SupplierID}} </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="Logo">
      <mat-header-cell *cdkHeaderCellDef> Logo </mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <img *ngIf="row.HasLogo" src="https://img.equipmyschool.com/company/{{row.SupplierID}}/small" style="max-height: 100px; max-width: 100px;">
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="RootDepartment">
      <mat-header-cell *cdkHeaderCellDef> Root Department </mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <a href="javascript:void(0)" (click)="setRootDepartment(row)" [ngStyle]="{'color': !row.RootDepartment ? 'red' : ''}">{{row.RootDepartment?.Name || 'Select a department'}}</a>
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="Checkboxes">
      <mat-header-cell *cdkHeaderCellDef>Status</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <app-icon-checkbox icon="power" [ngModel]="row.HasPlugin" matTooltip="Has plugin" [disabled]="true"></app-icon-checkbox>
        <app-icon-checkbox icon="power_settings_new" [ngModel]="row.PluginEnabled" matTooltip="Is plugin enabled?" (appInsteadOfModelChange)="togglePluginEnabled(row)"></app-icon-checkbox>
        <app-icon-checkbox icon="language" [ngModel]="row.ShowProductsOnline" matTooltip="Should we show the products online?" (appInsteadOfModelChange)="toggleShowProducts(row)"></app-icon-checkbox>
        <app-icon-checkbox icon="sync" [ngModel]="row.UseCrawledPrices" matTooltip="Should the pricing for the given supplier will be based on crawled prices?"
          (appInsteadOfModelChange)="toggleUseCrawledPrices(row)"></app-icon-checkbox>
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="OutOfDateCategories">
      <mat-header-cell *cdkHeaderCellDef matTooltip="Out of date Categories" mat-sort-header>OOD Categories</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{ row.OutOfDateCategories }}
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="OutOfDateProducts">
      <mat-header-cell *cdkHeaderCellDef matTooltip="Out of date Products" mat-sort-header>OOD Products</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{ row.OutOfDateProducts }}
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="HomepageChecked">
      <mat-header-cell *cdkHeaderCellDef>Homepage Checked</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{ row.HomepageLastChecked }}
      </mat-cell>
    </ng-container>

    <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
