import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailActionResponse } from '@equipmyschool/emsadminweb-models';
import { GeneralService } from 'app/server/server.module';

@Component({
  selector: 'app-email-action',
  templateUrl: './email-action.component.html',
  styleUrls: ['./email-action.component.scss']
})
export class EmailActionComponent implements OnInit {
  serverResponse: EmailActionResponse;
  serverError;

  constructor(
    private server: GeneralService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.server.EmailAction(params).subscribe(res => {
        this.serverResponse = res;
        if (res.RedirectUrl) {
          this.router.navigateByUrl(res.RedirectUrl);
        }
      }, err => {
        this.serverError = err;
      });
    });
  }

}
