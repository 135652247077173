import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ADMIN_API_SERVER_URL } from '../tokens/admin-api-server-url.token';
import { ServerBaseService } from '../server-base.service';
import { ExportCompany } from '@equipmyschool/emsadminweb-models';

@Injectable({
  providedIn: 'root'
})
export class SupplierService extends ServerBaseService {

  constructor(http: HttpClient, @Inject(ADMIN_API_SERVER_URL) serverUrl: string) {
    super(http, serverUrl);
  }

  save(supplier: ExportCompany) {
    this.request('PUT', 'Supplier/Update', null, supplier).subscribe();
  }
}
