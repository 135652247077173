import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable, InjectionToken, Inject } from '@angular/core';

import { HttpParams } from '@angular/common/http';


export abstract class ServerBaseService {
  constructor(protected http: HttpClient, protected serverUrl: string) {
  }

  protected request<TResponseData>(
    method: string,
    path: string,
    params?: { [param: string]: any },
    body?: any,
    observe: string = 'body') {

    switch (observe) {
      case 'body':
        return this.http.request<TResponseData>(
          method,
          this.serverUrl + path,
          {
            body,
            params: params,
            withCredentials: true,
            observe,
          }
        );
      default:
        throw new Error('Not implemented - Other observe types are not supported yet');
    }
  }

  protected requestFile(
    method: string,
    path: string,
    params?: { [param: string]: any },
    body?: any,
    observe: string = 'response') {

    if (observe === 'response') {
      return this.http.request(
        method,
        this.serverUrl + path,
        {
          body,
          params: params,
          withCredentials: true,
          observe,
          responseType: 'blob'
        }
      );
    } else {
      throw new Error('Not implemented - Other observe types are not supported yet');
    }
  }
}
