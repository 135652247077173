<div class="sidenav-container" fxLayout="column">

  <mat-toolbar color="primary" class="sidenav-toolbar mat-elevation-z2">
    <div fxLayout="row" style="width: 100%;">
      <span style="width: 22px; text-align: center; margin-left: -3px;" fxFlexAlign="center"><img src="assets/img/logo.svg" style="width: 25px; vertical-align: text-top;"></span>
      <!-- <img style="height: 35px; margin-left: 24px; margin-bottom: -5px;" src="assets/img/icon.png" fxFlexAlign="center" class="fade-in-on-icon-sidenav"> -->
      <span fxFlex fxFlexAlign="center" style="text-align: center;">AdminWeb</span>
      <!-- <span fxFlex> fill space </span> -->
      <div fxHide="true" fxHide.gt-sm="false" fxFlexAlign="center">
        <button mat-icon-button (click)="toggleIconSidenav()">
          <mat-icon class="icon-sidenav-toggle fade-in-on-icon-sidenav" [class.rotate]="isIconSidenav()">chevron_left</mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar>

  <div class="sidenav-scrollbar-container" fxLayout="column">
      <mat-nav-list [perfectScrollbar] fxLayout="column" class="sidenav-list sidenav-toplevel">
        <app-sidenav-item *ngFor="let item of items" [item]="item"></app-sidenav-item>
      </mat-nav-list>
  </div>

</div>
