import { DataProvider } from './data-provider';
import { Observable } from 'rxjs';
import * as stringify from 'json-stable-stringify';

export class EndpointDataProvider<TParams, TResponse> {
  private createdStreams = new Map<string, DataProvider<TResponse>>();
  constructor(private observableFactory: (params: TParams) => Observable<TResponse>) {
  }

  forParams(params: TParams) {
    const paramsHash = stringify(params);

    if (this.createdStreams.has(paramsHash)) {
      return this.createdStreams.get(paramsHash);
    } else {
      const entry = new DataProvider<TResponse>(() => this.observableFactory(params));
      this.createdStreams.set(paramsHash, entry);
      return entry;
    }
  }
}
