import { Observable, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { Component, OnInit, Inject, Input, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ValidatorFn } from '@angular/forms';
import { PurchaseInvoiceProvider } from 'app/server-data-providers/purchase-invoice.provider';
import { PurchaseOrderDetail } from '@equipmyschool/emsadminweb-models';

@Component({
  selector: 'app-find-order',
  templateUrl: './find-order.component.html',
  styleUrls: ['./find-order.component.scss']
})
export class FindOrderComponent implements OnInit {
  public orders$: Observable<PurchaseOrderDetail[]>;
  @ViewChild('filter')
  filter: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<FindOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputData,
    private server: PurchaseInvoiceProvider) { }

  ngOnInit() {
    this.orders$ = fromEvent(this.filter.nativeElement, 'keyup').pipe(
      debounceTime(300),
      distinctUntilChanged(),
      map(() => this.filter.nativeElement.value),
      switchMap((filter) => {
        return this.server.purchaseOrdersByProductCode({ ProductCode: filter });
      })
    );
  }

  select(order: PurchaseOrderDetail) {
    this.dialogRef.close(order);
  }
}

export interface InputData {
  currencyCode: string;
  validator: ValidatorFn;
}
