<div fxLayout="row" fxLayoutAlign="center center" style="height: 100%;">
  <div class="login mat-elevation-z12">
    <div class="login-header" fxLayout="column" fxLayoutAlign="center center">
      <img src="assets/img/logo-large.png" width="80%">
    </div>
    <form #form="ngForm" (ngSubmit)="login()">
      <div class="login-content" fxLayout="column" fxLayoutAlign="start stretch">
          <mat-form-field>
            <input matInput placeholder="Email" type="email" name="Username" required [(ngModel)]="credential.Username">
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="Password" type="password" name="Password" required [(ngModel)]="credential.Password">
          </mat-form-field>
          <div class="extra-options" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-checkbox class="remember-me" name="RememberMe" [(ngModel)]="credential.RememberMe">Remember Me</mat-checkbox>
            <a class="forgot-password" [routerLink]="['/forgot-password']">Forgot Password?</a>
          </div>
          <button color="primary" mat-raised-button [disabled]="!form.valid">SIGN IN</button>
        <p class="note">Don't have an account?<br/> <a [routerLink]="['/register']">Click here to create one</a></p>
      </div>
    </form>
  </div>
</div>
