import { Injectable } from "@angular/core";
import { NativeDateAdapter } from "@angular/material/core";

@Injectable()
/** Adapts the native JS Date for use with cdk-based components that work with dates. */
export class CustomDateAdapter extends NativeDateAdapter {
  getFirstDayOfWeek(): number {
    return 1;
  }

  /**
   * Create a Date object with the specified year, month, and date.
   *
   * @param year - The year of the date.
   * @param month - The month of the date (0-11).
   * @param date - The day of the month (1-31).
   * @returns A Date object representing the specified date.
   */
  static createDate(year: number, month: number, date: number): Date {
    return new Date(Date.UTC(year, month, date));
  }

    /**
   * Create a Date object with the specified year, month, and date.
   *
   * @param year - The year of the date.
   * @param month - The month of the date (0-11).
   * @param date - The day of the month (1-31).
   * @param hours - The hours of the date (0-23).
   * @param minutes - The minutes of the date (0-59).
   * @returns A Date object representing the specified date.
   */
    static createDateTime(year: number, month: number, date: number, hours: number, minutes: number, seconds: number, ms?: number): Date {
      return new Date(Date.UTC(year, month, date, hours, minutes, seconds, ms));
    }

    /**
   * Changes a Date object to its flattened or wysiwyg version.
   *
   * @param year - The year of the date.
   * @param month - The month of the date (0-11).
   * @param date - The day of the month (1-31).
   * @returns A Date object representing the specified date.
   */
  static changeDateToFlatDate(date: Date): Date {
    const tzoffset = (date).getTimezoneOffset() * 60000; // offset in milliseconds
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())-(tzoffset < 0 ? -86400000 : 0))
  }

  /**
   * Converts a UTC date to a local date string for display.
   *
   * @param utcDate - The UTC date to convert.
   * @returns The local date string.
   */
  static toLocalDisplayString(utcDate: Date): string {
    return utcDate.toLocaleDateString();
  }
}
