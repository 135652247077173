<h1 mat-dialog-title>Foreign Currency</h1>
<div mat-dialog-content>
  <form novalidate #form="ngForm">
    <p>
      Please enter the total amount of this invoice in {{data.supplierCurrency.Code}}
    </p>
    <mat-form-field>
      <input matInput type="number" step="0.01" placeholder="Total invoice amount" true name="totalGbp" [forbiddenValues]="[0,0.00,'0.00']" (click)="$event.target"
      [ngModel]="totalGbp | simpleDecimal:'1.2-2'" [ngModelOptions]="{updateOn: 'blur'}" (ngModelChange)="totalGbp=$event">
      <span matSuffix>{{data.supplierCurrency.Code}}</span>
    </mat-form-field>
    <p>
      Please enter the total amount of this invoice in the NATIVE CURRENCY
    </p>
    <mat-form-field>
      <input matInput type="number" step="0.01" placeholder="Total invoice amount" true name="totalNative" [forbiddenValues]="[0,0.00,'0.00']" [equals]="totalGbp" [equalsComparer]="signComparer" (click)="$event.target"
      [ngModel]="totalNative | simpleDecimal:'1.2-2'" [ngModelOptions]="{updateOn: 'blur'}" (ngModelChange)="totalNative=$event">
      <span matSuffix>???</span>
    </mat-form-field>
    <p class="mat-caption">
      Exchange Rate: {{exchangeRate}}
    </p>
  </form>
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close tabindex="-1">Cancel</button>
  <button mat-button [mat-dialog-close]="exchangeRate" tabindex="1" [disabled]="form.invalid">OK</button>
  <!-- TODO: Should add custom validators:
    * totalGbp != 0 (same for totalNative);
    * totalNative has to be positive or negative depending on whether totalGbp is positive/negative  -->
</mat-dialog-actions>
