import { AbstractControl, ValidatorFn, NG_VALIDATORS, Validator, ValidationErrors } from '@angular/forms';
import { Provider, forwardRef, OnChanges, Directive, SimpleChanges, Input } from '@angular/core';

export function equalsValidator<T>(to: T, comparer: (a: T, b: T) => boolean): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const valid = comparer(control.value, to);
    return !valid ? { 'equals': { value: control.value } } : null;
  };
}

export const EQUALS_VALIDATOR: Provider = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => EqualsValidatorDirective),
  multi: true
};

/**
 * A directive which installs the {@link EqualsValidatorDirective} for any `formControlName`,
 * `formControl`, or control with `ngModel` that also has a `equals` attribute.
 */
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[formControlName][equals],[formControl][equals],[ngModel][equals]',
  providers: [EQUALS_VALIDATOR],
})
export class EqualsValidatorDirective<T> implements Validator, OnChanges {
  private _validator: ValidatorFn;
  private _onChange: () => void;

  @Input('equals') to: T;
  @Input() equalsComparer: (a: T, b: T) => boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if ('to' in changes || 'equalsComparer' in changes) {
      this._createValidator();
      if (this._onChange) {
        this._onChange();
      }
    }
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this._validator(c);
  }

  registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }

  private _createValidator(): void {
    this._validator = equalsValidator(this.to, this.equalsComparer);
  }
}
