<ng-container *ngIf="!(isClosing$ | async)">
<h1 mat-dialog-title>Products for {{data.DocumentNumber}}</h1>
<div mat-dialog-content>
  <mat-table [dataSource]="productData">
    <ng-container cdkColumnDef="Code">
      <mat-header-cell *cdkHeaderCellDef>Code</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{row.Code}}
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="Description">
      <mat-header-cell *cdkHeaderCellDef>Description</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{row.Description}}
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="Quantity">
      <mat-header-cell *cdkHeaderCellDef>Quantity</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{row.Quantity}}
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="GoodsCost">
      <mat-header-cell *cdkHeaderCellDef>Goods</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{row.GoodsCost | currency: data.Currency.Code}}
      </mat-cell>
    </ng-container>
      
    <ng-container cdkColumnDef="FreightCost">
      <mat-header-cell *cdkHeaderCellDef>Freight</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{row.FreightCost | currency: data.Currency.Code}}
      </mat-cell>
    </ng-container>
    
    <ng-container cdkColumnDef="TaxCost">
      <mat-header-cell *cdkHeaderCellDef>Tax</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{row.TaxCost | currency: data.Currency.Code }}
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="Commodity">
      <mat-header-cell *cdkHeaderCellDef>Commodity</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <mat-select [(ngModel)]="row.Commodity" [compareWith]="commodityCompareFn">
          <mat-option *ngFor="let comm of commodities" [value]="comm">
            {{comm.Name}}  
          </mat-option>
        </mat-select>
      </mat-cell>
    </ng-container>

    <mat-header-row *cdkHeaderRowDef="popupColumns"></mat-header-row>
    <mat-row *cdkRowDef="let row; let i = index; columns: popupColumns;"></mat-row>
  </mat-table>
  
  <mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true" tabindex="1">OK</button>
    <button mat-button [mat-dialog-close]="false" tabindex="-1">Cancel</button>
  </mat-dialog-actions>
</div>
</ng-container>