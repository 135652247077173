import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Currency } from '@equipmyschool/emsadminweb-models';

@Component({
  selector: 'app-set-exchange-rate-dialog',
  templateUrl: './set-exchange-rate-dialog.component.html',
  styleUrls: ['./set-exchange-rate-dialog.component.scss']
})
export class SetExchangeRateDialogComponent implements OnInit {
  totalGbp = 0;
  totalNative = 0;
  get exchangeRate() {
    if (this.totalGbp) {
      return this.totalNative / this.totalGbp;
    }
    return 0;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InputData
  ) {
  }

  ngOnInit(): void {
  }

  signComparer = (a: number, b: number) => {
    return Math.sign(a) === Math.sign(b);
  }
}

export interface InputData {
  supplierCurrency: Currency;
}
