<mat-card style="margin: 10px;">
  <div *ngIf="serverResponse">
    <h1>{{serverResponse.Title}}</h1>
    <p>{{serverResponse.Message}}</p>
  </div>
  <div *ngIf="serverError">
    <h1>Error</h1>
    <p>{{serverError.error.Message}}</p>
    <div *ngIf="serverError && serverError.error.ModelState">
      <p *ngFor="let key of (serverError.error.ModelState | objectKeys)">
        {{ key }}: {{ serverError.error.ModelState[key] }}
      </p>
    </div>
  </div>
</mat-card>
